<div class="friends">
  <div class="f_name"><a [routerLink]="['/user', user.id]">{{user.firstName}} {{user.lastName}}</a></div>
  <a [routerLink]="['/user', user.id]">
    <div class="f_pic" [ngStyle]="{'background-image': 'url(' + user?.getLowImgUrl() + ')'}">
      <div class="f_pic_b">
        <div class="f_gps">
          <fa-icon [icon]="icons.marker"></fa-icon>
          <span class="f_location">{{user?.getLocationString()}}</span>
        </div>
      </div>
    </div>
  </a>
  <div class="f_info">
    <div class="f_chat">
      <button mat-icon-button [routerLink]="['/messages', user.id]">
        <fa-icon [icon]="icons.message"></fa-icon>
      </button>
    </div>

    <div class="f_age tr">
      <p class="f_a_txt"></p>
    </div>

    <div class="f_delete">
      <button mat-icon-button (click)="removeFriend(user)">
        <fa-icon [icon]="icons.removeFriend"></fa-icon>
      </button>
    </div>
  </div>
</div>
