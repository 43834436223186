import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RangeSliderComponent} from './range-slider/range-slider.component';
import {GoogleMapsComponent} from './google-maps/google-maps.component';
import {GoogleMapsAutocompleteComponent} from './google-maps-autocomplete/google-maps-autocomplete.component';
import {ReactiveFormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';

@NgModule({
  declarations: [
    RangeSliderComponent,
    GoogleMapsComponent,
    GoogleMapsAutocompleteComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule
  ],
  exports: [
    RangeSliderComponent,
    GoogleMapsComponent
  ]
})
export class UiElementsModule {
}
