import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {User} from '../../../shared/models/user';

@Component({
  selector: 'app-blocked-user-item',
  templateUrl: './blocked-user-item.component.html',
  styleUrls: ['./blocked-user-item.component.css']
})
export class BlockedUserItemComponent {

  @Input() user: User;
  @Output() removeBlocking: EventEmitter<User> = new EventEmitter<User>();

  constructor() {
  }

  handleRemoveBlocking(user: User) {
    if (!user) {
      return;
    }
    this.removeBlocking.emit(user);
  }

}
