<div [formGroup]="formGroup" class="flex col s10">
  <div class="title-box">
    <h3>Was & Wann</h3>
    <hr>
  </div>

  <div class="row">
    <mat-form-field class="col s10">
      <input (click)="openSportsDialog()" data-lpignore="true"
             formControlName="sportName"
             matInput
             name="sportName"
             placeholder="Sportart auswählen"
             readonly
             required
             type="text">
      <button (click)="removeSports()"
              *ngIf="formGroup.get('sportName').value"
              aria-label="Clear"
              mat-button
              mat-icon-button
              matSuffix
              type="button">
        <fa-icon [icon]="icons.clear"></fa-icon>
      </button>
      <button (click)="openSportsDialog()"
              aria-label="Wähle Sport"
              mat-button
              mat-icon-button
              matSuffix
              type="button">
        <fa-icon [icon]="icons.sports"></fa-icon>
      </button>
      <mat-error *ngIf="formGroup.get('sportName').invalid">{{errors.sport}}</mat-error>
    </mat-form-field>
    <input data-lpignore="true"
           formControlName="sportId"
           hidden
           name="sportId"
           readonly
           required>
  </div>

  <div class="row">
    <div class="col s-row">
      <mat-form-field class="date col s10 m6">
        <input (click)="day.open()"
               (dateInput)="onDaySet($event, time)"
               [matDatepicker]="day"
               [min]="minDate"
               data-lpignore="true"
               formControlName="day"
               matInput
               name="day"
               placeholder="Tag"
               readonly
               required>
        <button (click)="day.open()"
                mat-button
                mat-icon-button
                matSuffix
                type="button">
          <fa-icon [icon]="icons.calendar"></fa-icon>
        </button>
        <mat-datepicker #day [panelClass]="''"></mat-datepicker>
        <mat-error *ngIf="formGroup.get('day').invalid">{{errors.day}}</mat-error>
      </mat-form-field>

      <mat-form-field class="time col s10 m4">
        <input [format]="timeFormat" [ngxTimepicker]="time"
               data-lpignore="true"
               formControlName="time"
               matInput
               name="time"
               placeholder="Zeit"
               readonly
               required>
        <button (click)="time.open()"
                mat-button
                mat-icon-button
                matSuffix
                type="button">
          <fa-icon [icon]="icons.time"></fa-icon>
        </button>
        <ngx-material-timepicker #time
                                 (timeSet)="onTimeSet($event)"
                                 [enableKeyboardInput]="true"
                                 [minutesGap]="5">
        </ngx-material-timepicker>
        <mat-error *ngIf="formGroup.get('time').invalid">{{errors.time}}</mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col s10 input-field">
      <span>Level wählen:</span>
      <mat-slider
        (change)="onSkillChange($event)"
        [invert]="sliderConfig.invert"
        [max]="sliderConfig.max"
        [min]="sliderConfig.min"
        [step]="sliderConfig.step"
        [thumbLabel]="sliderConfig.thumbLabel"
        [tickInterval]="sliderConfig.tickInterval"
        [vertical]="sliderConfig.vertical"
        color="primary"
        formControlName="skill">
      </mat-slider>
      <span>{{skillLevel}}</span>
    </div>
  </div>

  <div class="row">
    <mat-form-field class="time col s10">
      <input (keyup)="onMaxAttendeesChange($event)" formControlName="maxAttendees"
             matInput
             name="maxAttendees"
             placeholder="max. Teilnehmer (optional)"
             type="number">
      <button mat-button
              mat-icon-button
              matSuffix
              type="button">
        <fa-icon [icon]="icons.maxAttendees"></fa-icon>
      </button>
      <mat-error
        *ngIf="formGroup.get('maxAttendees').invalid">{{errors.maxAttendees}}</mat-error>
    </mat-form-field>
  </div>
</div>
