import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {Alert} from '../../shared/models/alert';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  private lastAlertId = 1;

  private alertCache: Alert[] = [];

  alerts$: Subject<Alert> = new Subject();

  constructor() {
  }

  getAlerts(): Observable<Alert[]> {
    return this.alerts$
      .pipe(
        map((alert: Alert) => {
          return this.alertCache;
        })
      );
  }

  appendAlert(alert: Alert) {
    if (!alert) {
      return;
    }

    alert.id = this.lastAlertId++;
    this.alertCache.push(alert);
    this.alerts$.next(alert);
  }

  remove(alert: Alert) {
    for (let i = 0; i < this.alertCache.length; i++) {
      if (this.alertCache[i].id === alert.id) {
        this.alertCache.splice(i, 1);
        break;
      }
    }
  }
}
