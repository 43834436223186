import {Component, OnInit} from '@angular/core';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent {

  images = {
    background: environment.imageHost + 'landing/bg_register.jpg',
    logo: environment.imageHost + 'logos/logo_mt_384x384.png'
  };

  constructor() {
  }
}
