import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {UpdatePasswordComponent} from './update-password/update-password.component';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {NonAuthGuard} from '../../core/guards/non-auth.guard';

const forgotPasswordRoutes: Routes = [
  {
    path: '',
    canActivateChild: [NonAuthGuard],
    children: [
      {
        path: ':email/:token',
        component: UpdatePasswordComponent
      },
      {
        path: '',
        component: ForgotPasswordComponent
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(forgotPasswordRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class ForgotPasswordRoutingModule {
}
