<div class="flex-col motifun-box" style="width: 230px; height: 290px; margin: 0.8rem; padding: 0.8rem;">
  <div style="display: flex; justify-content: center;">
    <div class="img small circle link" [ngStyle]="{'background-image': 'url(' + user?.getMedImgUrl() + ')'}"
         [routerLink]="['/user', user.id]"></div>
  </div>
  <div style="flex: auto;">
    <div class="text-overflow" style="font-size: 1.05em; text-align: center;">
      {{user.getFullName()}}
    </div>
    <div class="motifun text" style="text-align: center; font-size: 0.875em;">
      {{subText}}
    </div>
  </div>
  <div style="font-size: 0.75em;margin: 10px 0;padding-bottom: 30px;border-bottom: 2px solid #cecece;">
    <div class="inner-row flex-row" *ngIf="user.age">
      <span class="col-5 margin" style="text-align: right; padding-right: 5px;">Alter</span>
      <span class="col-5 margin" style="padding-left: 5px; text-align: left;">{{user.age}} Jahre</span>
    </div>
    <div class="inner-row flex-row" *ngIf="user.city">
      <span class="col-5 margin" style="text-align: right; padding-right: 5px;">Ort</span>
      <span class="col-5 margin text-overflow" style="padding-left: 5px; text-align: left;">{{user.getLocationString()}}</span>
    </div>
    <div class="inner-row flex-row">
      <span class="col-5 margin" style="text-align: right; padding-right: 5px;">Letzter Login</span>
      <span class="col-5 margin" style="padding-left: 5px; text-align: left;">{{user.getLastSignIn()}}</span>
    </div>
  </div>
  <div class="inner-row flex-row flex-center" style="font-size: 1.5em; min-height: 36px;">
    <a class="link" style="padding: 0 0.8rem;" *ngIf="user.id !== ownUserId" [routerLink]="['/messages', user.id]">
      <fa-icon [icon]="icons.chat"></fa-icon>
    </a>
    <a class="link" style="padding: 0 0.8rem;" *ngIf="user.id !== ownUserId" (click)="onAddFriend()">
      <fa-icon [icon]="getFriendsIcon()"></fa-icon>
    </a>
    <a class="link" *ngIf="user.userRelation === requestSent" (click)="onRemoveFriend()">
      <fa-icon [icon]="icons.cancelRequest"></fa-icon>
    </a>
  </div>
</div>
