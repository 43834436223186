export const environment = {
  production: false,
  apiUrl: 'https://api.motifun.borgstedt.net/v1/',
  socketUrl: 'https://api.motifun.borgstedt.net/v1/',
  sportImgUrl: 'https://static.motifun.borgstedt.net/images/events/',
  userImgUrl: 'https://static.motifun.borgstedt.net/images/users/',
  imageHost: 'https://static.motifun.borgstedt.net/images/',
  googleAPIKey: 'AIzaSyD3DakW-XMziEtvzWh-8dzmE4ow981QtUU',
  tileHostingAPIKey: 'prksSTPRofg5nC5zgq8W',
  locationIqAPIKey: 'ce78be46107656',
  ipInfo: 'fb24202bf69996',
  ipStack: 'bd2637cca988a3de874a0f6c8b9054ca',
  storageKeys: {
    settings: 'motifun-settings',
    jwt: 'motifun-jwt-manager'
  }
};
