import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UpdatePasswordComponent} from './update-password/update-password.component';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {ForgotPasswordRoutingModule} from './forgot-password-routing.module';
import {SharedModule} from '../../shared/shared.module';


@NgModule({
  declarations: [
    ForgotPasswordComponent,
    UpdatePasswordComponent
  ],
  imports: [
    CommonModule,
    ForgotPasswordRoutingModule,
    SharedModule
  ]
})
export class ForgotPasswordModule {
}
