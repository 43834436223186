<div id="wrapper" class="flex-row motifun-box">
  <input id="show-chats" type="checkbox" [checked]="currentChat !== null">
  <div id="chats" class="col-3">
    <div id="chat-list-header" class="flex-row text-center" [ngStyle]="{'display': searching ? 'none' : 'flex'}">
      <fa-icon [icon]="icons.message"></fa-icon>
      <span>Nachrichten</span>
      <fa-icon [icon]="icons.search" class="grow" (click)="onSearchClick()"></fa-icon>
    </div>
    <div id="search-header" class="flex-row text-center" [ngStyle]="{'display': searching ? 'block' : 'none'}">
      <mat-form-field class="col-10">
        <fa-icon matPrefix [icon]="icons.search"></fa-icon>
        <input #search
               matInput
               placeholder="Finde einen Chat"
               type="text"
               (keyup)="onSearch($event)"
               (blur)="searching = search.value.trim().length > 0"
               [(ngModel)]="searchQuery">
        <button mat-icon-button matSuffix *ngIf="searchQuery.trim().length > 0"
                (click)="searchQuery = ''; this.searching = false">
          <fa-icon [icon]="icons.close"></fa-icon>
        </button>
      </mat-form-field>
    </div>
    <cdk-virtual-scroll-viewport
      *ngIf="!searching || searchQuery.trim().length <= 0"
      #chatList
      [itemSize]="chatItemHeight"
      [minBufferPx]="50"
      [maxBufferPx]="70"
      (scrolledIndexChange)="handleGetNextChats($event)">
      <ul>
        <li *cdkVirtualFor="let chat of chats$ | async"
            [ngClass]="{'selected': chat.selected}"
            (click)="handleChatClick(chat)">
          <app-chat-user [chat]="chat" (chatDeleted)="deleteChat(chat)"
                         (chatReported)="reportChat(chat)"></app-chat-user>
        </li>
        <li *ngIf="loading.chats" class="loading">
          <mat-progress-spinner color="primary" [mode]="'indeterminate'"></mat-progress-spinner>
        </li>
        <li *ngIf="empty.chats" class="flex-center chats-end">
          <span>Bisher hast du noch keine Nachrichten.</span>
        </li>
        <li *ngIf="end.chats && !empty.chats" class="flex-center chats-end">
          <span>Kein weiterer Chat vorhanden.</span>
        </li>
      </ul>
    </cdk-virtual-scroll-viewport>

    <cdk-virtual-scroll-viewport
      *ngIf="searching && searchQuery.trim().length > 0"
      #chatSearchList
      itemSize="100"
      [minBufferPx]="50"
      [maxBufferPx]="70"
      (scrolledIndexChange)="handleGetNextSearchedChats($event)">
      <ul>
        <li
          *cdkVirtualFor="let chat of chatsSearching$ | async"
          (click)="handleChatClick(chat)"
        >
          <app-chat-user [chat]="chat"></app-chat-user>
        </li>
        <li *ngIf="loading.search" class="loading">
          <mat-progress-spinner color="primary" [mode]="'indeterminate'"></mat-progress-spinner>
        </li>
        <li *ngIf="empty.search" class="flex center">
          <span>Es wurden keine Chats gefunden.</span>
        </li>
        <li *ngIf="end.search && chatSearchList.getDataLength() > 0" class="flex center">
          <span>Keinen weiteren Chat gefunden.</span>
        </li>
      </ul>
    </cdk-virtual-scroll-viewport>
  </div>
  <div id="messages" class="col-7">
    <div id="message-header" class="flex-row" *ngIf="currentChat$ | async as currentChat">
      <div class="flex-row">
        <div id="back-container" class="flex-row flex-center">
          <button mat-icon-button (click)="unselectChat(true)">
            <fa-icon [icon]="icons.back"></fa-icon>
          </button>
        </div>
        <div id="user" class="flex-row flex-center">
          <div class="chat-info-img"
               [ngStyle]="{'background-image': 'url(' + currentChat.user.getLowImgUrl() + ')'}">
            <a *ngIf="currentChat" [routerLink]="['/user', currentChat.user.id]"></a>
          </div>
          <a [routerLink]="['/user', currentChat.user.id]">
            <span>{{currentChat.user.getFullName()}}</span>
            <fa-icon [icon]="icons.link"></fa-icon>
          </a>
        </div>
      </div>
    </div>
    <ul #messageList id="message-list">
      <li *ngIf="end.messages" class="info motifun text center" id="message-end">
        <span>Keine weiteren Nachrichten vorhanden.</span>
      </li>
      <li *ngIf="loading.messages" class="flex-center loading">
        <mat-progress-spinner color="primary" [mode]="'indeterminate'"></mat-progress-spinner>
      </li>
      <ng-container
        *ngFor="let message of messages$ | async; let i = index">
        <li class="date"
            *ngIf="i === 0 || (i > 0 && currentChat?.messages[i-1] && isNewDay(message.time, currentChat?.messages[i - 1].time))">
            <span>
              {{message.getChatTime()}}
            </span>
        </li>
        <li class="message {{message.type}}">
          <app-chat-message [message]="message"></app-chat-message>
        </li>
      </ng-container>
    </ul>
    <form id="message-form" class="flex-row" [formGroup]="messageForm">
      <mat-form-field class="col-10">
        <textarea matInput
                  cdkTextareaAutosize
                  cdkAutosizeMinRows="2"
                  cdkAutosizeMaxRows="5"
                  placeholder="Deine Nachricht..."
                  formControlName="message"
                  name="message"
                  (keydown)="onEnterSend($event)"></textarea>
        <button mat-button matSuffix mat-icon-button color="primary" type="submit"
                (click)="sendMessage()">
          <fa-icon [icon]="icons.send"></fa-icon>
        </button>
      </mat-form-field>
    </form>
  </div>
</div>
