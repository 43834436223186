<ng-template>Was & Wann?</ng-template>
<form [formGroup]="info" class="flex col">
  <div class="row">
    <mat-form-field class="date col s10">
      <input matInput
             name="day"
             [matDatepicker]="day"
             [min]="minDate"
             formControlName="day"
             (click)="day.open()"
             placeholder="Tag"
             (dateInput)="onDaySet($event, time)"
             readonly
             required
             data-lpignore="true">
      <button mat-button
              matSuffix
              mat-icon-button
              (click)="day.open()">
        <mat-icon matSuffix>date_range</mat-icon>
      </button>
      <mat-datepicker touchUi #day></mat-datepicker>
      <mat-error *ngIf="info.get('day').invalid">{{errors.day}}</mat-error>
    </mat-form-field>
  </div>
  <div class="row">
    <mat-form-field class="time col s10">
      <input matInput
             placeholder="Zeit"
             name="time"
             [ngxTimepicker]="time"
             [format]="timeFormat"
             data-lpignore="true"
             formControlName="time"
             readonly
             required>
      <button mat-button
              matSuffix
              mat-icon-button
              (click)="time.open()">
        <mat-icon matSuffix>access_time</mat-icon>
      </button>
      <ngx-material-timepicker #time
                               [enableKeyboardInput]="false"
                               (timeSet)="onTimeSet($event)"
                               [minutesGap]="5">
      </ngx-material-timepicker>
      <mat-error *ngIf="info.get('time').invalid">{{errors.time}}</mat-error>
    </mat-form-field>

  </div>

  <div class="row skill-wrapper">
    <p class="col s10">Level wählen: {{skillLevel}}</p>
    <div class="col s10">
      <mat-slider
        color="primary"
        [invert]="sliderConfig.invert"
        [max]="sliderConfig.max"
        [min]="sliderConfig.min"
        [step]="sliderConfig.step"
        [thumbLabel]="sliderConfig.thumbLabel"
        [tickInterval]="sliderConfig.tickInterval"
        formControlName="skill"
        (change)="onSkillChange($event)"
        [vertical]="sliderConfig.vertical">
      </mat-slider>
    </div>
  </div>

  <div class="row">
    <mat-form-field class="time col s10">
      <input matInput placeholder="max. Teilnehmer (optional)"
             name="maxAttendees"
             type="number"
             formControlName="maxAttendees"
             (keyup)="onMaxAttendeesChange($event)">
      <button mat-button
              matSuffix
              mat-icon-button>
        <fa-icon [icon]="icons.maxAttendees"></fa-icon>
      </button>
      <mat-error
        *ngIf="info.get('maxAttendees').invalid">{{errors.maxAttendees}}</mat-error>
    </mat-form-field>
  </div>
  <div class="row">
    <button (click)="submit()" class="col s10" color="primary" mat-raised-button>Event ändern</button>
  </div>

</form>
