import {Sport} from './sport';
import {Deserializable} from './deserializable.model';

export class SportCategory implements Deserializable {

  id: number;
  name: string;
  sports: Sport[];

  constructor() {
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  clone() {
    return Object.assign( Object.create( Object.getPrototypeOf(this)), this);
  }

}
