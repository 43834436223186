import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DeleteAccountComponent} from './delete-account/delete-account.component';
import {DeleteComponent} from './delete/delete.component';

const deleteRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: ':token',
        component: DeleteAccountComponent
      },
      {
        path: '',
        component: DeleteComponent
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(deleteRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class DeleteRoutingModule {
}
