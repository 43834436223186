import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {DataStoreService} from '../../../core/services/data-store.service';
import {ApiService} from '../../../core/services/api.service';
import {User} from '../../../shared/models/user';
import Cropper from 'cropperjs';
import {
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faRedo,
  faSearchMinus,
  faSearchPlus,
  faUpload
} from '@fortawesome/free-solid-svg-icons';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UserService} from '../../../core/services/user.service';

@Component({
  selector: 'app-user-img-dialog',
  templateUrl: './user-img-dialog.component.html',
  styleUrls: ['./user-img-dialog.component.css']
})
export class UserImgDialogComponent implements OnInit {

  icons = {
    faRedo: faRedo,
    zoomMinus: faSearchMinus,
    zoomPlus: faSearchPlus,
    angleUp: faAngleUp,
    angleDown: faAngleDown,
    angleLeft: faAngleLeft,
    angleRight: faAngleRight,
    upload: faUpload
  };

  user: User;

  cropper: Cropper;
  @ViewChild('cropperImg', {static: true}) cropperImg: ElementRef;

  constructor(private data: DataStoreService,
              private api: ApiService,
              private userService: UserService,
              private dialogRef: MatDialogRef<UserImgDialogComponent>,
              @Inject(MAT_DIALOG_DATA) private passedData: any) {
    this.user = passedData;
  }

  ngOnInit() {
    this.initCropper();
  }

  choseImage(image: HTMLInputElement) {
    const files = image.files;

    if (FileReader && files && files.length === 1) {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        console.log('onload');
        this.initCropper(fileReader.result);
      };
      fileReader.readAsDataURL(image.files[0]);
    } else {
      console.log('invalid image chose');
    }
  }

  initCropper(image?: any) {
    if (!this.cropperImg) {
      console.log('profile img is not available');
      return;
    }

    if (this.cropper) {
      this.cropper.destroy();
    }

    const minCroppedWidth = 150;
    const minCroppedHeight = 150;
    const maxCroppedWidth = 500;
    const maxCroppedHeight = 500;

    if (image) {
      this.cropperImg.nativeElement.src = image;
    } else {
      this.cropperImg.nativeElement.src = this.user.getHighImgUrl();
    }

    this.cropper = new Cropper(this.cropperImg.nativeElement, {
      aspectRatio: 1, // 1.9736842105 event img ratio
      viewMode: 2,
      autoCrop: true,
      autoCropArea: 0.5,
      preview: '.preview',
      minCropBoxWidth: 250,
      minCropBoxHeight: 250,
      data: {
        width: (minCroppedWidth + maxCroppedWidth) / 2,
        height: (minCroppedHeight + maxCroppedHeight) / 2
      }
    });
  }

  closeCropper() {
    this.dialogRef.close(this.user);
  }

  uploadImage() {
    const imgLow = this.cropper.getCroppedCanvas({
      width: 130,
      fillColor: '#fff',
      imageSmoothingEnabled: true,
      imageSmoothingQuality: 'high',
    }).toDataURL('image/jpeg');
    const imgMed = this.cropper.getCroppedCanvas({
      width: 256,
      fillColor: '#fff',
      imageSmoothingEnabled: true,
      imageSmoothingQuality: 'high',
    }).toDataURL('image/jpeg');
    const imgHigh = this.cropper.getCroppedCanvas({
      width: 450,
      fillColor: '#fff',
      imageSmoothingEnabled: true,
      imageSmoothingQuality: 'high',
    }).toDataURL('image/jpeg');

    const imgLowFile = new File([imgLow], 'low.jpg', {type: 'image/jpeg'});
    const imgMedFile = new File([imgMed], 'med.jpg', {type: 'image/jpeg'});
    const imgHighFile = new File([imgHigh], 'high.jpg', {type: 'image/jpeg'});

    const imgs = [imgLowFile, imgMedFile, imgHighFile];

    this.api.uploadImage<any>('user/image', imgs)
      .subscribe(data => {
        this.user.updateImage(data.img);
        this.userService.setUser(this.user);

        this.closeCropper();
      });
  }

  deleteImage() {
    this.api.delete('user/image')
      .subscribe((data: any) => {
        if (data.removed) {
          this.user.updateImage(null);
          this.userService.setUser(this.user);

          this.dialogRef.close();
        }
      });
  }
}
