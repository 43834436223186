import {Injectable} from '@angular/core';
import {io, ManagerOptions, Socket, SocketOptions} from 'socket.io-client';
import {AuthService} from './auth.service';
import {environment} from '../../../environments/environment';
import {fromEvent, Observable, Subject} from 'rxjs';
import {SocketIOEvent} from '../../shared/enums/socket-io-event';
import {map, share, switchMap} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class SocketIOService {

  socket: Socket;
  isAuthenticated = false;
  reconnectCounter = 0;
  authenticated$: Subject<boolean> = new Subject<boolean>();
  connected$: Subject<boolean> = new Subject<boolean>();

  constructor(private auth: AuthService) {
    this.auth.isAuthenticated()
      .subscribe((isAuthenticated: boolean) => {
        if (isAuthenticated && !this.isAuthenticated) {
          this.connect();
        }
      });

    this.auth.onSignedOut()
      .subscribe(() => {
        this.disconnect();
      });
  }

  disconnect() {
    if (this.socket && this.socket.connected) {
      this.socket.disconnect();
    }
  }

  setupListener() {
    this.onConnect()
      .subscribe(() => {
        console.log('connected');
        this.connected$.next(true);
        this.reconnectCounter = 0;
        this.authenticate();
      });

    this.onEvent(SocketIOEvent.DISCONNECT)
      .subscribe(data => {
        console.log('disconnected');
        this.handleDisconnect();
        console.log(this.socket);
      });

    this.onEvent(SocketIOEvent.ERROR)
      .subscribe((data) => {
        console.log('error');
        console.log(data);
      });

    this.onAuthenticated()
      .subscribe((isAuthenticated: boolean) => {
        this.isAuthenticated = isAuthenticated;
      });
  }

  isConnected() {
    return this.socket ? this.socket.connected : false;
  }

  onAuthenticated() {
    return this.connected$
      .pipe(
        switchMap(() => fromEvent(this.socket as Socket, SocketIOEvent.AUTHENTICATE)),
        map((data: any) => {
          if (data.error) {
            console.log('socket io auth failed');
            console.log(data.error);
            return false;
          } else {
            console.log('successfully authenticated');
            this.authenticated$.next(true);
            return true;
          }
        }),
        share()
      );
  }

  onMessage(): Observable<any> {
    return this.connected$
      .pipe(
        switchMap(() => fromEvent(this.socket as Socket, SocketIOEvent.MESSAGE)),
        share()
      );
  }

  onNotification(): Observable<any> {
    return this.connected$
      .pipe(
        switchMap(() => fromEvent(this.socket as Socket, SocketIOEvent.NOTIFICATION)),
        share()
      );
  }

  onOther(type: string) {
    return this.connected$
      .pipe(
        switchMap(() => fromEvent(this.socket as Socket, type)),
        share()
      );
  }

  onConnect() {
    return fromEvent(this.socket as Socket, SocketIOEvent.CONNECT);
  }

  onEvent(event: SocketIOEvent): Observable<any> {
    return this.connected$
      .pipe(
        switchMap((data: boolean) => fromEvent(this.socket as Socket, event)),
        share()
      );
  }

  handleDisconnect() {
    this.isAuthenticated = false;
    this.reconnectCounter = 0;
    if (this.socket && this.socket.connected) {
      this.socket.disconnect();
    }
  }

  send(name: SocketIOEvent, data: any) {
    if (!this.socket) {
      return;
    }
    console.log(name);
    console.log(data);
    this.socket.emit(name, data);
  }

  private connect() {
    if (this.socket && this.socket.connected) {
      console.log('socket already connected');
      return;
    }
    const options: Partial<ManagerOptions & SocketOptions> = {transports: ['websocket', 'polling'], upgrade: false};
    this.socket = io(environment.socketUrl, options);
    this.setupListener();
  }

  private authenticate() {
    if (!this.isAuthenticated) {
      const data = {
        token: this.auth.getToken()
      };
      this.send(SocketIOEvent.AUTHENTICATE, data);
    }
  }
}
