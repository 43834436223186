<div [formGroup]="formGroup" class="flex col s10">
  <div class="title-box">
    <h3>Wie</h3>
    <hr>
  </div>

  <div class="row">
    <mat-form-field class="col s10">
      <input (keyup)="onTitleChange($event)" data-lpignore="true"
             formControlName="title"
             matInput
             maxlength="50"
             name="title"
             placeholder="Titel"
             required>
      <mat-hint align="end">{{formGroup.get('title').value.length || 0}}/50
      </mat-hint>
      <mat-error *ngIf="formGroup.get('title').invalid">{{errors.title}}</mat-error>
    </mat-form-field>
  </div>
  <div class="row">
    <mat-form-field class="col s10">
                <textarea (keyup)="onDescriptionChange($event)" cdkAutosizeMaxRows="8"
                          cdkAutosizeMinRows="5"
                          cdkTextareaAutosize
                          data-lpignore="true"
                          formControlName="description"
                          matInput
                          maxlength="400"
                          name="description"
                          placeholder="Beschreibung"
                          required></textarea>
      <mat-hint align="end">{{formGroup.get('description').value.length || 0}}
        /400
      </mat-hint>
      <mat-error
        *ngIf="formGroup.get('description').invalid">{{errors.description}}</mat-error>
    </mat-form-field>
  </div>
</div>
