<div class="motifun-box flex col" id="filter">
  <div id="arrow"></div>
  <div class="row">
    <mat-form-field class="col s10">
      <mat-chip-list #sportsList>
        <mat-chip
          *ngFor="let sport of searchOptions.sports"
          [selectable]="false"
          [removable]="true"
          (removed)="removeSport(sport)">
          {{sport.name}}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
        <input
          placeholder="Sportart auswählen"
          #sportsInput
          [formControl]="formControls.sports"
          [matAutocomplete]="sportsAuto"
          [matChipInputFor]="sportsList"
          [matChipInputAddOnBlur]="true">
        <button mat-icon-button matSuffix>
          <fa-icon [icon]="icons.sport"></fa-icon>
        </button>
      </mat-chip-list>
      <mat-autocomplete #sportsAuto="matAutocomplete" [displayWith]="displayNull()"
                        (optionSelected)="onSelectSports($event)">
        <mat-option *ngFor="let sport of sports$ | async" [value]="sport">
          {{sport.name}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>

  <div id="skill" class="row">
    <div class="col s10">
      <p>Wie gut dürfen deine Mitsportler sein?
        <fa-icon [icon]="icons.sport"></fa-icon>
      </p>
      <app-range-slider [config]="rangeSliderConfig" (valueChanged)="onSkillChanged($event)"></app-range-slider>
    </div>
  </div>

  <div class="row">
    <mat-form-field class="col s10">
      <input matInput
             placeholder="von"
             [matDatepicker]="minStartDate"
             [formControl]="formControls.minStartDate"
             [min]="today"
             (dateChange)="onMinStartDateChanged($event)"
             (click)="minStartDate.open()">
      <button mat-button
              matSuffix
              mat-icon-button
              type="button"
              (click)="minStartDate.open()">
        <fa-icon [icon]="icons.day"></fa-icon>
      </button>
      <mat-datepicker #minStartDate></mat-datepicker>
    </mat-form-field>
  </div>

  <div class="row">
    <mat-form-field class="col s10">
      <input matInput
             placeholder="bis"
             [matDatepicker]="maxStartDate"
             [formControl]="formControls.maxStartDate"
             [min]="minDate"
             (dateChange)="onMaxStartDateChanged($event)"
             (click)="maxStartDate.open()">
      <button *ngIf="formControls.maxStartDate.value"
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="onMaxStartDateChanged(null)">
        <fa-icon [icon]="icons.close"></fa-icon>
      </button>
      <button matSuffix
              mat-icon-button
              type="button"
              (click)="maxStartDate.open()">
        <fa-icon [icon]="icons.day"></fa-icon>
      </button>
      <mat-datepicker #maxStartDate></mat-datepicker>
    </mat-form-field>
  </div>

  <div class="row">
    <mat-form-field class="col s10">
      <input matInput
             type="text"
             placeholder="Ort wählen"
             [formControl]="formControls.location"
             [matAutocomplete]="placesAuto"
             required>
      <button mat-icon-button matSuffix (click)="handleLocateUser()">
        <fa-icon [icon]="icons.locate"></fa-icon>
      </button>
      <mat-autocomplete #placesAuto="matAutocomplete" [displayWith]="displayNull()"
                        (optionSelected)="onSelectPlace($event)">
        <mat-option *ngFor="let place of places$ | async" [value]="place">
          {{place.getAutocompleteString()}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>

  <div id="location" class="flex row">
    <div class="col s10 input-field">
      <span>Entfernung</span>
      <div class="col s10">
        <mat-slider
          thumbLabel
          min="5"
          max="200"
          step="1"
          value="15"
          color="primary"
          (change)="onMaxDistanceChanged($event)"
          [(ngModel)]="searchOptions.maxDistance"></mat-slider>
      </div>
      <div>
        <span>5 km</span>
        <span>200 km</span>
      </div>
    </div>
  </div>
</div>
