<div class="row flex-row" style="height: 120px; margin: 10px 0;">
  <div class="col-2">
    <div class="img xsmall circle" [ngStyle]="{'background-image': 'url(' + comment.user.getMedImgUrl() + ')'}"></div>
  </div>
  <div class="col-8 flex-col">
    <div class="inner-row">
      <div class="col-10" style="font-size: 1.05em;">
        {{comment.user.getFullName()}}
      </div>
    </div>
    <div class="inner-row flex-row">
      <div class="col-5 motifun text" style="font-size: 0.875em;">
        {{comment.user.id === eventCreatorId ? 'Initiator' : ''}}
      </div>
      <div class="col-5" style="font-size: 0.875em;">
        {{comment.getTime()}}
      </div>
    </div>
    <div class="inner-row overflow-y">
      <div class="col-10" style="font-size: 0.9375em;">
        {{comment.text}}
      </div>
    </div>
  </div>
</div>


<!--<div class="container-event-comments-menu">-->
  <!--<i class="fa fa-ellipsis-h" aria-hidden="true"></i>-->
<!--</div>-->
<!--<a [routerLink]="['/user/' + comment?.user?.id]"><div class="container-event-comments-img" [ngStyle]="{'background-image': 'url(' + comment?.user?.getMedImgUrl() + ')'}"></div></a>-->
<!--<div class="container-event-comments-name">-->
  <!--<a class="text-link" [routerLink]="['/user/' + comment?.user?.id]">{{comment?.user?.firstName}} {{comment?.user?.lastName}}</a>-->
<!--</div>-->

<!--<div class="container-event-comments-timestamp">-->
  <!--{{comment?.getTime()}}-->
<!--</div>-->

<!--<div class="container-event-comments-content">-->
  <!--{{comment?.text}}-->
<!--</div>-->

<!--<div class="clearer"></div>-->
