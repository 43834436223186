<section id="top" class="fullscreen img flex-center"
         [ngStyle]="{'background-image': 'url(' + images.background + ')'}">
  <div class="animated fadeInUp">
    <div class="logo-container grow" [routerLink]="['/']">
      <div class="logo" [ngStyle]="{'background-image': 'url(' + images.logo + ')'}"></div>
    </div>
    <div class="motifun-box">
      <h5 class="section-headline">Accountlöschung</h5>
      <div class="flex-col content-text">
        <div *ngIf="loading">
          <p>Dein Account wird gerade gelöscht. Bitte hab' einen Moment Geduld.</p>
          <mat-spinner></mat-spinner>
        </div>
        <div *ngIf="deleted">
          <p>Schade, dass du uns verlassen hast. Dein Account wurde soeben erfolgreich aus unserem System
            entfernt. Wir würden uns freuen, wenn du uns mitteilst, warum du unseren Service nicht mehr nutzen willst.
            <a [routerLink]="['/']" fragment="contact">Hier</a> kannst du uns eine Nachricht hinterlassen.</p>
        </div>
        <div *ngIf="error">
          <p>Wir konnten zu deinem Löschtoken keinen Account finden. Entweder ist der Token falsch oder dein Account
            wurde bereits gelöscht.<br>Falls du doch wieder mit anderen Leuten Sport machen willst, dann kannst du
            <a [routerLink]="['/']">hier</a> einen neuen Account erstellen.</p>
        </div>
      </div>
    </div>
  </div>
</section>
