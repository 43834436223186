import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {User} from '../../../shared/models/user';
import {BehaviorSubject, merge, Observable, of, Subject, Subscription} from 'rxjs';
import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {Event} from '../../../shared/models/event';
import {ResizeService} from '../../../core/services/resize.service';
import {ApiService} from '../../../core/services/api.service';
import {EventService} from '../../../core/services/event.service';
import {GeoService} from '../../../core/services/geo.service';
import {EventType} from '../event-type';
import {AlertService} from '../../../core/services/alert.service';
import {UserService} from '../../../core/services/user.service';
import {catchError, map, share, switchMap} from 'rxjs/operators';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {HttpErrorResponse} from '@angular/common/http';
import {Alert} from '../../../shared/models/alert';
import {AlertType} from '../../../shared/enums/alert-type';
import {Router} from '@angular/router';
import {IEvent} from '../../../shared/interface/IEvent';
import {ICreateEvent} from '../../../shared/interface/ICreateEvent';
import {faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {DateTime} from 'luxon';

@Component({
  selector: 'app-event-form',
  templateUrl: './event-form.component.html',
  styleUrls: ['./event-form.component.css'],
  providers: []
})
export class EventFormComponent implements OnInit, OnDestroy {

  eventGroup: UntypedFormGroup;

  icons = {
    arrowLeft: faChevronLeft,
    arrowRight: faChevronRight
  };

  progress = 0;
  progressMax = 7;

  eventType: EventType = EventType.CREATED;

  recentEventLoading = false;
  recentEventEnd = false;
  noRecentEvents = false;

  private initialLoad$: BehaviorSubject<number> = new BehaviorSubject(-1);
  private pageScroll$: Subject<number> = new Subject();
  private pageResize$: Observable<any>;
  recentEvents$: Observable<Event[]>;

  private eventGroupSubscription: Subscription;

  user$: Observable<User>;

  @ViewChild('recentEventsViewport') recentEventsViewport: CdkVirtualScrollViewport;

  constructor(private alert: AlertService,
              private builder: UntypedFormBuilder,
              private userService: UserService,
              private resize: ResizeService,
              private api: ApiService,
              private geo: GeoService,
              private eventService: EventService,
              private router: Router) {
    this.user$ = this.userService.getUser()
      .pipe(
        map((user: User) => {
          return user;
        }),
        share()
      );
    this.eventGroup = this.builder.group({
      eventInfoGroup: this.builder.group({
        sportName: ['', Validators.required],
        sportId: ['', Validators.required],
        day: ['', Validators.required],
        time: ['', Validators.required],
        startTime: ['', Validators.required],
        skill: [1, [Validators.required, Validators.min(1), Validators.max(5)]],
        maxAttendees: ['', [Validators.min(5), Validators.max(15)]]
      }),
      eventPlaceGroup: this.builder.group({
        address: ['', Validators.required],
        street: [''],
        houseNumber: [''],
        postalCode: ['', Validators.required],
        city: ['', Validators.required],
        state: ['', Validators.required],
        country: ['', Validators.required],
        lat: ['', Validators.required],
        lng: ['', Validators.required]
      }),
      eventTextGroup: this.builder.group({
        title: ['', Validators.required],
        description: ['', Validators.required]
      })
    });

    this.eventGroupSubscription = this.eventGroup.valueChanges
      .subscribe((formData: IEvent) => {
        this.calcProgress(formData);
      });
  }

  ngOnInit() {
    this.pageResize$ = this.resize.onScreenResizeX(document.body, 150, 10);

    this.recentEvents$ = merge(this.initialLoad$, this.pageResize$, this.pageScroll$)
      .pipe(
        switchMap((page: number) => {
          return this.eventService.getEvents(null, EventType.CREATED);
        }),
        map((events: Event[]) => {
          if (!events || events.length === 0) {
            this.noRecentEvents = true;
          }

          return events;
        })
      );
  }

  ngOnDestroy(): void {
    if (this.eventGroupSubscription) {
      this.eventGroupSubscription.unsubscribe();
    }
  }

  getNextRecentEvents($event: number) {
    if (this.recentEventEnd || this.recentEventLoading) {
      return;
    }

    const end = this.recentEventsViewport.getRenderedRange().end;
    const total = this.recentEventsViewport.getDataLength();
    console.log(`${end}, '>=', ${total}`);
    if (end === total) {
      this.pageScroll$.next(total);
    }
  }

  calcProgress(event: IEvent) {
    this.progress = 0;
    if (event.eventInfoGroup.sportId > 0) {
      this.progress++;
    } else {
      console.log('invalid sport');
    }

    if (event.eventInfoGroup.day && event.eventInfoGroup.day.isValid && event.eventInfoGroup.day.diff(DateTime.local(), 'day').days <= 0) {
      this.progress++;
    } else {
      console.log('invalid day');
    }

    const minStartTime: DateTime = DateTime.local().plus({hour: 3});
    if (event.eventInfoGroup.startTime && event.eventInfoGroup.startTime.isValid
      && event.eventInfoGroup.startTime.diff(minStartTime).hours > 0) {
      this.progress++;
    } else {
      console.log('invalid startTime');
    }

    if (event.eventInfoGroup.skill > 0 && event.eventInfoGroup.skill < 6) {
      this.progress++;
    } else {
      console.log('invalid skill');
    }

    if (!Number.isNaN(event.eventPlaceGroup.lat) && !Number.isNaN(event.eventPlaceGroup.lng)) {
      this.progress++;
    } else {
      console.log('invalid location');
    }

    if (event.eventTextGroup.title.length > 0) {
      this.progress++;
    } else {
      console.log('invalid title');
    }

    if (event.eventTextGroup.description.length > 0) {
      this.progress++;
    } else {
      console.log('invalid description');
    }
  }

  onCopyEvent(e: Event) {
    const dayINeed: number = DateTime.fromJSDate(e.startDate.toJSDate()).weekday;
    const today: number = DateTime.local().weekday;
    // if we haven't yet passed the day of the week that I need:
    let date: DateTime;
    if (today <= dayINeed) {
      // then just give me this week's instance of that day
      date = DateTime.local();
    } else {
      // otherwise, give me *next week's* instance of that same day
      date = DateTime.local().plus({week: 1});
    }
    const event: Event = e.clone();
    event.startDate = date;

    const values = event.getIEvent();
    values.eventInfoGroup.startTime = this.calculateStartTime(date, event.startTime.toISOTime());

    this.eventGroup.patchValue(values);
    console.log(values);
  }

  submitEvent() {
    this.onSubmitEvent(Event.formGroupToPayload(this.eventGroup.getRawValue()));
  }

  onSubmitEvent(payload: ICreateEvent) {
    // TODO: use separate model class for CreateEvent and call validate functions. to show errors
    this.eventService.createEvent(payload)
      .pipe(
        catchError((err: HttpErrorResponse, caught) => {
          if (err.status === 422) {
            this.alert.appendAlert(
              new Alert(
                'Falsche Eingabe',
                err.message,
                AlertType.ERROR
              )
            );
          }

          return of(null);
        })
      )
      .subscribe((eventResult: Event) => {
        if (eventResult && eventResult.id > 0) {
          this.router.navigate(['event', eventResult.id]).catch(e => console.log(e));
        }
      });
  }

  private calculateStartTime(day: DateTime, time: string) {
    const t = time.split(':');
    const hour = Number(t[0]);
    const minute = Number(t[1]);

    const newStartTime = DateTime.fromISO(day.toISO());
    newStartTime.set({minute: minute, hour: hour});

    return newStartTime;
  }
}
