import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {EventsRoutingModule} from './events-routing.module';
import {EventComponent} from './event/event.component';
import {EventListComponent} from './event-list/event-list.component';
import {EventItemComponent} from './event-item/event-item.component';
import {EventAttendeesComponent} from './event-attendees/event-attendees.component';
import {EventCommentComponent} from './event-comment/event-comment.component';
import {EventListFilterComponent} from './event-list-filter/event-list-filter.component';
import {EventFormMapDialogComponent} from './event-form-map-dialog/event-form-map-dialog.component';
import {EventFormDialogComponent} from './event-form-dialog/event-form-dialog.component';
import {EventFormSportsDialogComponent} from './event-form-sports-dialog/event-form-sports-dialog.component';
import {EventFormComponent} from './event-form/event-form.component';
import {EventStartComponent} from './event-start/event-start.component';
import {EventFormDesktopComponent} from './event-form-desktop/event-form-desktop.component';
import {EventFormTabletComponent} from './event-form-tablet/event-form-tablet.component';
import {EventFormMobileComponent} from './event-form-mobile/event-form-mobile.component';
import {EventFormWhatComponent} from './event-form-what/event-form-what.component';
import {EventFormWhereComponent} from './event-form-where/event-form-where.component';
import {EventFormHowComponent} from './event-form-how/event-form-how.component';
import {SharedModule} from '../../shared/shared.module';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';

@NgModule({
  declarations: [
    EventComponent,
    EventListComponent,
    EventItemComponent,
    EventAttendeesComponent,
    EventCommentComponent,
    EventListFilterComponent,
    EventFormComponent,
    EventFormDialogComponent,
    EventFormMapDialogComponent,
    EventFormSportsDialogComponent,
    EventStartComponent,
    EventFormDesktopComponent,
    EventFormTabletComponent,
    EventFormMobileComponent,
    EventFormWhatComponent,
    EventFormWhereComponent,
    EventFormHowComponent
  ],
  imports: [
    CommonModule,
    EventsRoutingModule,
    SharedModule,
    NgxMaterialTimepickerModule
  ],
  exports: [
    EventListComponent,
    EventStartComponent,
    EventAttendeesComponent,
    EventCommentComponent
  ]
})
export class EventsModule {
}
