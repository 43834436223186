import {Component, OnDestroy} from '@angular/core';
import {ApiService} from '../../../core/services/api.service';
import {Notification} from '../notification';
import {DataStoreService} from '../../../core/services/data-store.service';
import {User} from '../../../shared/models/user';
import {filter, map, share, switchMap, tap} from 'rxjs/operators';
import {BehaviorSubject, merge, Observable, of, Subject, Subscription} from 'rxjs';
import {ScrollService} from '../../../core/services/scroll.service';
import {ResizeService} from '../../../core/services/resize.service';
import {UserService} from '../../../core/services/user.service';
import {NotificationService} from '../../../core/services/notification.service';
import {Router} from '@angular/router';
import {faChevronDown} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.css']
})
export class NotificationListComponent implements OnDestroy {

  user: User;

  loading = {
    notification: false,
    profile: false
  };

  icons = {
    arrowDown: faChevronDown
  };

  notificationEnd = false;

  user$: Observable<User>;

  notifications$: Observable<Notification[]>;
  notificationsLoading$: Observable<Notification[]>;
  notificationsChanging$: Observable<Notification[]>;
  notificationsReceiving$: Subscription;
  notificationsRemoving$: Observable<Notification[]>;
  notificationsScroll$: Observable<number>;
  notificationsResize$: Observable<number>;
  notificationsInitial$: BehaviorSubject<string> = new BehaviorSubject('-1');

  private notificationsCount = -1;

  notificationProfile$: Observable<Notification>;
  notificationProfileInitial$: Subject<Notification> = new Subject();

  private itemHeight = 150;
  private numberOfItems = 10;
  private currentNotification: Notification;

  constructor(private api: ApiService,
              private data: DataStoreService,
              private notificationService: NotificationService,
              private resize: ResizeService,
              private router: Router,
              private scroll: ScrollService,
              private userService: UserService) {

    const elem: Element = document.getElementsByTagName('main')[0];

    this.user$ = this.userService.getUser()
      .pipe(
        map((user: User) => {
          this.user = user;
          return user;
        }),
        share()
      );

    this.notificationsScroll$ = scroll.onWindowScrollY(elem, 10, 200, this.itemHeight, this.numberOfItems);
    this.notificationsResize$ = resize.onScreenResizeY(elem, this.itemHeight, this.numberOfItems);
    this.notificationsLoading$ = merge(this.notificationsInitial$, this.notificationsScroll$, this.notificationsResize$)
      .pipe(
        filter(() => !this.loading.notification && !this.notificationEnd),
        switchMap(() => {
          this.loading.notification = true;

          return this.notificationService.getNotifications(this.user);
        })
      );

    this.notificationsRemoving$ = this.data.onNotificationRemoved()
      .pipe(
        map((notification: Notification) => {
          console.log('removed notification:', notification);
          const notifications = this.data.getCachedNotifications();

          if (this.currentNotification.id === notification.id) {
            this.currentNotification = null;
            if (notifications.length > 0) {
              this.notificationProfileInitial$.next(notifications[0]);
            }
          }

          return notifications;
        })
      );

    this.notificationsReceiving$ = this.user$
      .pipe(
        switchMap((user: User) => {
          return this.notificationService.onNewNotification(user);
        })
      ).subscribe((notification: Notification) => {
        this.notificationService.prependNotification(notification);
        this.notificationService.resetCounter();
      });

    this.notificationsChanging$ = merge(this.notificationService.onNotificationChanged(), this.notificationsRemoving$)
      .pipe(
        map((notifications: Notification[]) => {
          return notifications.concat([]);
        })
      );

    this.notifications$ = this.user$
      .pipe(
        switchMap(() => {
          return merge(this.notificationsLoading$, this.notificationsChanging$);
        }),
        tap((notifications: Notification[]) => {
          if (notifications.length === this.notificationsCount) {
            this.notificationEnd = true;
          }
          this.notificationsCount = notifications.length;

          if (!this.currentNotification) {
            this.notificationProfileInitial$.next(notifications[0]);
          }

          this.loading.notification = false;

          if ((this.itemHeight * notifications.length) < window.innerHeight && !this.notificationEnd
            && notifications.length > 0 && !this.loading.profile) {
            this.notificationsInitial$.next(notifications[notifications.length - 1].id);
          }
        }),
        share()
      );

    this.notificationProfile$ = this.notificationProfileInitial$
      .pipe(
        tap(() => {
          this.loading.profile = true;
        }),
        switchMap((notification: Notification) => {
          if (!notification) {
            return of(null);
          }
          notification.selected = true; // do it here, because otherwise the user has to wait until following request is done..
          return this.notificationService.getNotificationProfile(notification);
        }),
        map((notification: Notification) => {
          this.loading.profile = false;

          this.currentNotification = notification;

          return notification;
        })
      );
  }

  ngOnDestroy() {
    if (this.currentNotification) {
      this.currentNotification.selected = false;
    }
    this.currentNotification = null;
    this.notificationsReceiving$.unsubscribe();
  }

  handleRemoveNotification(n: Notification) {
    console.log('handle remove notification');
    this.currentNotification = null;
    this.notificationService.removeNotification(n);
  }

  onNotificationSelected(notification: Notification) {
    if (this.currentNotification) {
      this.currentNotification.selected = false;
    }
    if (window.innerWidth < 1000) {
      this.router.navigate(notification.url).catch((error: Error) => console.log(error));
    }
    this.notificationProfileInitial$.next(notification);
  }
}
