import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Session} from '../../../shared/models/session';
import {faLinux} from '@fortawesome/free-brands-svg-icons/faLinux';
import {faApple} from '@fortawesome/free-brands-svg-icons/faApple';
import {faMicrosoft} from '@fortawesome/free-brands-svg-icons/faMicrosoft';
import {faChrome} from '@fortawesome/free-brands-svg-icons/faChrome';
import {faFirefox} from '@fortawesome/free-brands-svg-icons/faFirefox';
import {faEdge} from '@fortawesome/free-brands-svg-icons/faEdge';
import {faInternetExplorer} from '@fortawesome/free-brands-svg-icons/faInternetExplorer';
import {faOpera} from '@fortawesome/free-brands-svg-icons/faOpera';
import {faSafari} from '@fortawesome/free-brands-svg-icons/faSafari';
import {faDesktop, faGlobe, faLaptop, faMobile, faTablet} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIconObj} from '../../../shared/interface/font-awesome-icon-obj';

@Component({
  selector: 'app-session-item',
  templateUrl: './session-item.component.html',
  styleUrls: ['./session-item.component.css']
})
export class SessionItemComponent {

  icons: FontAwesomeIconObj = {
    os: {
      linux: faLinux,
      apple: faApple,
      windows: faMicrosoft
    },
    env: {
      mobile: faMobile,
      tablet: faTablet,
      laptop: faLaptop,
      desktop: faDesktop
    },
    browser: {
      chrome: faChrome,
      firefox: faFirefox,
      edge: faEdge,
      internetExplorer: faInternetExplorer,
      safari: faSafari,
      opera: faOpera,
      other: faGlobe
    }
  };

  @Input() session: Session;

  @Output() removeSession: EventEmitter<Session> = new EventEmitter<Session>();

  constructor() {
  }

  handleRemoveSession(session: Session) {
    if (!session) {
      return;
    }

    this.removeSession.emit(session);
  }

}
