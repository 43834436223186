import {SportCategory} from './sport-category';
import {Deserializable} from './deserializable.model';
import {Sport} from './sport';
import {Location} from './location';
import {DateTime} from 'luxon';

export class SearchOptions implements Deserializable {

  sports: Sport[] = [];
  sportCategories: SportCategory[] = [];
  location: Location = new Location();
  maxDistance = 15;
  minStartDate: number;
  maxStartDate: number;
  skill: number[] = [1, 5];
  minSkill = 1;
  maxSkill = 5;

  constructor() {
    this.minStartDate = Number(DateTime.local().toMillis());
  }

  deserialize(input: any): this {
    Object.assign(this as any, input);
    return this;
  }

  getPayload(eventIds: number[]) {
    const sports = this.sports.length > 0 ? this.sports.map((sport) => sport.id).join(',') : '';

    const categories = this.sportCategories.length > 0
      ? this.sportCategories.map((category) => category.id).join(',') : '';

    const data = {
      sid: sports,
      scid: categories,
      r: this.maxDistance,
      lat: this.location ? this.location.lat : '',
      lng: this.location ? this.location.lng : '',
      tf: this.minStartDate,
      tt: this.maxStartDate,
      smin: this.skill[0],
      smax: this.skill[1],
      leid: eventIds.length > 0 ? eventIds[eventIds.length - 1] : -1,
      events: eventIds.join(',')
    };

    return data;
  }

  clone() {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this as any);
  }
}
