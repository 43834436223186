import {Component, Input, OnInit} from '@angular/core';
import {User} from '../../../shared/models/user';
import {UserImgDialogComponent} from '../user-img-dialog/user-img-dialog.component';
import {UserFavoriteSportsDialogComponent} from '../user-favorite-sports-dialog/user-favorite-sports-dialog.component';
import {DateAdapter} from '@angular/material/core';
import {MatDialog} from '@angular/material/dialog';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {AlertService} from '../../../core/services/alert.service';
import {Alert} from '../../../shared/models/alert';
import {UserImagePreviewDialogComponent} from '../user-image-preview-dialog/user-image-preview-dialog.component';
import {AlertType} from '../../../shared/enums/alert-type';
import {Location} from '../../../shared/models/location';
import {GeoService} from '../../../core/services/geo.service';
import {Observable, of} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, share, startWith, switchMap} from 'rxjs/operators';
import {GeolocationType} from '../../../shared/enums/geolocation-type';
import {
  faAward,
  faBan,
  faBaseballBall,
  faBasketballBall,
  faBicycle,
  faBowlingBall,
  faChess,
  faCrown,
  faDumbbell,
  faEdit,
  faEllipsisH,
  faEnvelope,
  faFish,
  faFlag,
  faFlagCheckered,
  faFootballBall,
  faFutbol,
  faGolfBall,
  faHiking,
  faHockeyPuck,
  faHorse,
  faMedal,
  faRunning,
  faSkating,
  faSkiing,
  faSkiingNordic,
  faSnowboarding,
  faSwimmer,
  faTableTennis,
  faTrophy,
  faUserCheck,
  faUserMinus,
  faUserPlus,
  faUserTimes,
  faVolleyballBall,
  faWalking
} from '@fortawesome/free-solid-svg-icons';
import {UserService} from '../../../core/services/user.service';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';
import {FontAwesomeIconObj} from '../../../shared/interface/font-awesome-icon-obj';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {DateTime} from 'luxon';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.css'],
  providers: []
})
export class UserInfoComponent implements OnInit {

  icons: FontAwesomeIconObj = {
    friend: {
      add: faUserPlus,
      remove: faUserMinus,
      accept: faUserCheck,
      deny: faUserTimes
    },
    control: {
      block: faBan,
      flag: faFlag,
      message: faEnvelope,
      more: faEllipsisH,
      edit: faEdit,
    },
    sports: {
      football: faFutbol,
      americanFootball: faFootballBall,
//      tennis: fa,
      basketball: faBasketballBall,
      baseball: faBaseballBall,
      golf: faGolfBall,
      iceHockey: faHockeyPuck,
      fieldHockey: faHockeyPuck,
      tableTennis: faTableTennis,
      skiing: faSkiing,
      skiJump: faSkiing,
      skiNordic: faSkiingNordic,
      snowboard: faSnowboarding,
      running: faRunning,
//      boxing: faBoxingGlove,
      bowling: faBowlingBall,
      skating: faSkating,
//      racquet: faRacquet,
//      shuttleCock: faShuttlecock,
//      cricket: faCricket,
//      sledding: faSledding,
      fitness: faDumbbell,
      volleyball: faVolleyballBall,
//      curling: faCurling,
      bicycle: faBicycle,
      walking: faWalking,
      swimming: faSwimmer,
      hiking: faHiking,
      fishing: faFish,
      chess: faChess,
      racing: faFlagCheckered,
//      fencing: faSwords,
      riding: faHorse
    },
    awards: {
      trophy: faTrophy,
      medal: faMedal,
      award: faAward,
      cert: faCrown
    }
  };

  edit = {
    about: false,
    info: false,
    target: false
  };

  editAbout: UntypedFormGroup;
  editInfo: UntypedFormGroup;
  editTarget: UntypedFormGroup;

  editUser: User;

  startDate: Date;
  minDate: DateTime;
  maxDate: DateTime;

  places$: Observable<Location[]>;

  @Input() user: User;
  @Input() ownProfile: boolean;
  sports$: { icon: IconDefinition, name: string }[] = [];

  constructor(private adapter: DateAdapter<Date>,
              private alertService: AlertService,
              private geo: GeoService,
              private dialog: MatDialog,
              private userService: UserService) {
    const keys = Object.keys(this.icons.sports);
    keys.forEach((key: string) => {
      this.sports$.push({icon: this.icons.sports[key], name: key});
    });

    this.startDate = DateTime.fromFormat('01.01.1995', 'dd.MM.yyyy').toJSDate();
    this.minDate = DateTime.local().minus({years: 90});
    this.maxDate = DateTime.local().plus({years: 14});
  }

  ngOnInit() {
    this.editAbout = new UntypedFormGroup({
      about: new UntypedFormControl(this.user.aboutMe || '', [Validators.maxLength(400)])
    });

    this.editInfo = new UntypedFormGroup({
      birthday: new UntypedFormControl(this.user.dayOfBirth, []),
      location: new UntypedFormControl(this.user.getLocationString(), [])
    });

    this.editTarget = new UntypedFormGroup({
      target: new UntypedFormControl('', [Validators.maxLength(100)])
    });

    this.userService.getUser()
      .subscribe((user: User) => {
        this.editUser = user.cloneEditable();
        this.adapter.setLocale(user.settings.language);
      });

    this.places$ = this.editInfo.get('location').valueChanges
      .pipe(
        startWith(''),
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((val: any) => {
          if (val instanceof Location) {
            return of([]);
          } else {
            if (!val || val.trim().length <= 0) {
              return of([]);
            }
            return this.geo.getSearchPlace(val, GeolocationType.KOMOOT, true, this.user.settings.language, 6);
          }
        }),
        share()
      );
  }

  onShowUserImg() {
    const dialogRef = this.dialog.open(UserImagePreviewDialogComponent, {
      data: this.user,
      panelClass: 'img-preview'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  onEditUserImg() {
    let width;
    if (window.innerWidth < 900) {
      width = '90%';
    } else {
      width = '70%';
    }
    const dialogRef = this.dialog.open(UserImgDialogComponent, {
      maxWidth: width,
      width,
      data: this.user
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  onEditFavoriteSports() {
    const dialogRef = this.dialog.open(UserFavoriteSportsDialogComponent, {
      width: '70%',
      height: '500px'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  saveFavoriteSports() {
  }

  updateUser(): Observable<User> {
    return this.userService.updateUser(this.editUser);
  }

  onEditTarget() {
    if (this.editTarget.invalid) {
      this.alertService.appendAlert(
        new Alert(
          'Ungültiges Ziel',
          'Deine Eingabe ist nicht korrekt.',
          AlertType.ERROR
        )
      );
      return;
    }
    this.editUser.target = this.editTarget.getRawValue().target;
    this.edit.target = false;

    this.updateUser()
      .subscribe((user: User) => {
        this.editUser = user.cloneEditable();
      });
  }

  onEditInfo() {
    if (this.editInfo.invalid) {
      this.alertService.appendAlert(
        new Alert(
          'Ungültige Infos',
          'Deine Eingaben sind nicht korrekt.',
          AlertType.ERROR
        )
      );
      return;
    }
    this.edit.info = false;

    this.editUser.birth = this.editInfo.getRawValue().birthday;

    this.updateUser()
      .subscribe((user) => {
        this.user = user;
      });
  }

  onEditAbout() {
    if (this.editAbout.invalid) {
      this.alertService.appendAlert(
        new Alert(
          'Ungültiger Text',
          'Deine Beschreibung ist ungültig. Es sind nur 400 Zeichen erlaubt',
          AlertType.ERROR
        )
      );
      return;
    }

    this.edit.about = false;
    this.editUser.aboutMe = this.editAbout.getRawValue().about;

    this.updateUser()
      .pipe(
        catchError((err, caught) => {
          return of(err);
        })
      )
      .subscribe((user) => {
        if (!user) {

        }
        this.user = user;
      });
  }

  displayNull(): null {
    return null;
  }

  onSelectPlace($event: MatAutocompleteSelectedEvent) {
    const place = $event.option.value;
    this.editUser.city = place.city;
    this.editUser.country = place.country;
    this.editUser.lat = place.lat;
    this.editUser.lng = place.lng;
    this.editInfo.patchValue({location: place.getAutocompleteString()});
  }

  onHandleLocateUser() {
    this.geo.locateUser()
      .subscribe((data: Location) => {
        this.editInfo.patchValue({location: data.city + ', ' + data.country});
      });
  }

  denyFriendRequest(user: User) {
    this.userService.denyUser(user)
      .subscribe((u: any) => {
        console.log(u);
      });
  }

  sendFriendRequest(user: User) {
    this.userService.addUser(user)
      .subscribe((u: User) => {
        console.log(u);
      });
  }

  blockUser(user: User) {
    this.userService.blockUser(user)
      .subscribe((data: any) => {
        console.log(data);
      });
  }

  reportUser(user: User) {
    // TODO: open modal and let user select a category and type a message + optional block
  }
}
