import {Component, Input, OnInit} from '@angular/core';
import {User} from '../../../shared/models/user';
import {UserService} from '../../../core/services/user.service';
import {faBan, faEnvelope, faFlag, faLink} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-notification-user-info',
  templateUrl: './notification-user-info.component.html',
  styleUrls: ['./notification-user-info.component.css']
})
export class NotificationUserInfoComponent {

  icons = {
    message: faEnvelope,
    flag: faFlag,
    block: faBan,
    link: faLink
  };

  @Input() user: User;

  constructor(private userService: UserService) {
  }

}
