import {AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {Location} from '../../../shared/models/location';
import {GeolocationType} from '../../../shared/enums/geolocation-type';
import {GeoService} from '../../../core/services/geo.service';
import {UntypedFormGroup} from '@angular/forms';
import {User} from '../../../shared/models/user';
import {GoogleMapsService} from '../services/google-maps.service';

declare const google: any;

@Component({
  selector: 'app-google-maps',
  templateUrl: './google-maps.component.html',
  styleUrls: ['./google-maps.component.scss']
})
export class GoogleMapsComponent implements AfterViewInit {

  @Input() formGroup: UntypedFormGroup;
  @Input() inputError: string;
  @Input() useAutocomplete: boolean;
  @Input() user: User;
  @Input() defaultLocation: Location;
  @Output() location: EventEmitter<Location> = new EventEmitter<Location>();
  @ViewChild('mapRef', {static: true}) mapElement: ElementRef<HTMLInputElement>;
  private map: any;
  private marker: any;
  private latlng: any;
  private posMarker: any;
  private markersArray: any[] = [];

  constructor(private geo: GeoService,
              private mapService: GoogleMapsService) {
  }


  ngAfterViewInit(): void {
    this.initMap(this.mapElement);
  }

  onPlaceChanged(location: Location) {
    this.setMarker(location, true);
  }

  setMarker(location: Location, centerMap: boolean) {
    this.deleteOverlays();
    const latLng = new google.maps.LatLng(location.lat, location.lng);

    this.marker = new google.maps.Marker({
      position: latLng,
      map: this.map
    });

    this.markersArray.push(this.marker);

    if (centerMap) {
      this.map.setCenter(latLng);
      this.map.setZoom(15);
    }
  }

  // Deletes all markers in the array by removing references to them
  deleteOverlays() {
    if (this.markersArray) {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.markersArray.length; i++) {
        this.marker.setMap(null);
      }
      this.markersArray = [];
    }
  }

  handleLocationError(browserHasGeolocation: boolean, pos: any) {
    this.map.setCenter(pos);
  }

  setCurrentLocation() {
    this.geo.locateUser(this.user.settings.language)
      .subscribe((location: Location) => {
        this.map.setCenter(new google.maps.LatLng(location.lat, location.lng));
        this.map.setZoom(15);
        this.location.emit(location);
      });
  }

  private initMap(mapElement: ElementRef<HTMLInputElement>) {
    let latlng;
    if (this.defaultLocation) {
      latlng = new google.maps.LatLng(this.defaultLocation.lat, this.defaultLocation.lng);
    } else {
      latlng = new google.maps.LatLng(51.5079705, 7.4002233);
      console.log('got no location..');
    }

    const myOptions: any = {
      zoom: 12,
      center: latlng,
      draggableCursor: 'default',
      draggingCursor: 'move'
    };

    this.map = new google.maps.Map(mapElement.nativeElement, myOptions);
    this.marker = new google.maps.Marker({
      map: this.map,
      anchorPoint: new google.maps.Point(0, -29)
    });

    this.mapService.setMap({map: this.map, marker: this.marker});

    if (this.markersArray && this.markersArray.length > 0) {
      const location: Location = new Location();
      location.lat = this.markersArray[0].getPosition().lat();
      location.lng = this.markersArray[0].getPosition().lng();
      this.setMarker(location, true);
    }

    this.map.addListener('click', (event: any) => {
      this.marker.setVisible(false);
      this.handleMapClick(event.latLng.lat(), event.latLng.lng());
    });
  }

  private handleMapClick(lat: number, lng: number) {
    const location = new Location().parseLatLng(lat, lng);
    this.setMarker(location, false);
    this.geo.getAddressOfCoordinates(location, GeolocationType.NOMINATIM, this.user.settings.language)
      .subscribe((l: Location) => {
        this.location.emit(l);
      });
  }
}
