<app-notification-user-info [user]="notification.sender"></app-notification-user-info>

<div class="row flex-col motifun-box">
  <p class="section-headline">Freundschaftsanfrage</p>
  <div class="flex-row flex-center">
    <button mat-flat-button color="primary" (click)="handleAddFriend()">
      <fa-icon [icon]="icons.add"></fa-icon>
      <span>Annehmen</span></button>
    <button mat-flat-button color="accent" (click)="handleDenyFriend()">
      <fa-icon [icon]="icons.deny"></fa-icon>
      <span>Ablehnen</span></button>
  </div>
</div>
