import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BlockedUserItemComponent} from './blocked-user-item/blocked-user-item.component';
import {SessionItemComponent} from './session-item/session-item.component';
import {SettingsComponent} from './settings/settings.component';
import {SharedModule} from '../../shared/shared.module';
import {SettingsRoutingModule} from './settings-routing.module';

@NgModule({
  declarations: [
    BlockedUserItemComponent,
    SessionItemComponent,
    SettingsComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    SettingsRoutingModule
  ]
})
export class SettingsModule {
}
