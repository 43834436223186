<section id="top" class="fullscreen img flex-center"
         [ngStyle]="{'background-image': 'url(' + images.background + ')'}">
  <div class="animated fadeInUp">
    <div class="logo-container grow" [routerLink]="['/']">
      <div class="logo" [ngStyle]="{'background-image': 'url(' + images.logo + ')'}"></div>
    </div>
    <div class="motifun-box">
      <h5 class="section-headline">Accountaktivierung</h5>
      <div class="flex-col content-text">
        <div *ngIf="loading">
          <p>Dein Account wird gerade aktiviert. Bitte hab' einen Moment Geduld.</p>
          <mat-spinner></mat-spinner>
        </div>
        <div *ngIf="activated">
          <p>Vielen Dank - Die Aktivierung war erfolgreich! Du kannst unseren Service nun nutzen.</p>
        </div>
        <div *ngIf="error">
          <p>Bei der Aktivierung ist ein Fehler aufgetreten. Ist dein Account vielleicht schon aktiviert worden? Falls
            nicht versuche den Aktivierungslink erneut aufzurufen oder melde dich <a [routerLink]="['/login?ref=settings']">hier</a> an und lass dir erneut
            eine Aktivierungsmail senden.</p>
        </div>
      </div>
    </div>
  </div>
</section>
