<mat-form-field class="col s10">
  <input #input
         formControlName="address"
         data-lpignore="true"
         matInput
         name="place"
         placeholder="Ort wählen"
         required
         type="text">
  <button (click)="setCurrentLocation()"
          mat-button
          mat-icon-button
          matSuffix
          type="button">
    <mat-icon matSuffix>location_on</mat-icon>
  </button>
  <mat-error *ngIf="formGroup && formGroup.invalid">{{inputError}}</mat-error>
</mat-form-field>
