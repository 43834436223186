import {Component, OnInit, ViewChild} from '@angular/core';
import {ApiService} from '../../../core/services/api.service';
import {DataStoreService} from '../../../core/services/data-store.service';
import {environment} from '../../../../environments/environment';
import {User} from '../../../shared/models/user';
import {Session} from '../../../shared/models/session';
import {AuthService} from '../../../core/services/auth.service';
import {Language} from '../../../shared/models/language';
import {SettingsService} from '../../../core/services/settings.service';
import {finalize, map, mergeMap, scan, startWith, switchMap, throttleTime} from 'rxjs/operators';
import {BehaviorSubject, merge, Observable, of} from 'rxjs';
import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {Settings} from '../../../shared/models/settings';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {AlertService} from '../../../core/services/alert.service';
import {Alert} from '../../../shared/models/alert';
import {AlertType} from '../../../shared/enums/alert-type';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {UserService} from '../../../core/services/user.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  images: string[] = [];
  imgHost = environment.imageHost + 'background/';
  colors = ['none', 'pomegranate', 'king-yna', 'ibiza-sunset', 'flickr', 'purple-bliss', 'man-of-steel', 'purple-love'];

  // TODO: get languages from node
  languages: Language[] = [];

  blockedUsers: User[] = [];

  activeSessions: Session[] = [];

  subscriptions: { [key: string]: Observable<any> } = {
    sessions: null,
    blockedUsers: null
  };

  end = {
    sessions: false,
    blockedUsers: false
  };

  loading = {
    sessions: false,
    blockedUsers: false
  };

  loadingData = {
    session: new BehaviorSubject(null)
  };

  settings$: Observable<Settings>;
  settings: Settings;

  user: User;

  selectedImage: HTMLElement;
  selectedColor: HTMLElement;

  languageForm: UntypedFormGroup;
  emailForm: UntypedFormGroup;
  passwordForm: UntypedFormGroup;

  private language: string;

  @ViewChild('sessionViewport') sessionsViewport: CdkVirtualScrollViewport;
  @ViewChild('blockedUsersViewport') blockedUsersViewport: CdkVirtualScrollViewport;

  constructor(private api: ApiService,
              private alertService: AlertService,
              private auth: AuthService,
              private data: DataStoreService,
              private settingsService: SettingsService,
              private userService: UserService) {
    for (let i = 0; i < 27; i++) {
      if (i < 10) {
        this.images.push('0' + i + '.jpeg');
      } else {
        this.images.push(i + '.jpeg');
      }
    }

    this.settings$ = this.userService.getUser()
      .pipe(
        map((user: User) => {
          this.user = user;

          return user.settings;
        }),
        switchMap((settings: Settings) => {
          return merge(of(settings), settingsService.observeSettings());
        }),
        map((settings: Settings) => {
          this.settings = settings;

          this.language = settings.language;

          return settings;
        })
      );

    this.passwordForm = new UntypedFormGroup({
      oldPassword: new UntypedFormControl('', [Validators.required]),
      newPassword: new UntypedFormControl('', [Validators.required, Validators.maxLength(32), Validators.minLength(8)]),
      newPasswordConfirm: new UntypedFormControl('', [Validators.required, Validators.maxLength(32), Validators.minLength(8)])
    });

    this.emailForm = new UntypedFormGroup({
      email: new UntypedFormControl('', [Validators.required, Validators.email])
    });

    this.languageForm = new UntypedFormGroup({
      language: new UntypedFormControl(this.language, [Validators.required])
    });
  }

  ngOnInit() {
    this.getBlockedUsers();
    this.getLanguages();

    this.subscriptions.sessions = this.loadingData.session
      .pipe(
        startWith([]),
        throttleTime(500),
        mergeMap((n: any) => {
          console.log(n);
          if (n && n.type === 'delete') {
            return of(n);
          }

          return this.getSessions(n);
        }),
        scan((acc: Session[], batch: any) => {
          if (batch && batch.type === 'delete') {
            const index = acc.findIndex((elt) => elt.id === batch.id);
            acc.splice(index, 1);
            return acc;
          }
          console.log(batch);
          return acc.concat(batch);
        }),
        map((data) => {
          console.log(data);
          return Object.values(data);
        })
      );
  }

  handleRemoveBlocking(user: User) {
    this.api.get<any>('user/' + user.id + '/block')
      .subscribe((data: any) => {
        console.log(data);
        if (data.msg === 'unblocked') {
          for (let i = 0; i < this.blockedUsers.length; i++) {
            if (this.blockedUsers[i].id === user.id) {
              this.blockedUsers.splice(i, 1);
              break;
            }
          }
        }
      });
  }

  handleRemoveSession(session: Session) {
    this.api.delete<any>('auth/' + session.id)
      .subscribe(data => {
        const tokenId = Number(data.id);
        if (tokenId > 0) {
          this.loadingData.session.next({type: 'delete', id: tokenId});
        }
      }, (error) => {
        if (error.status === 404) {
          this.loadingData.session.next({type: 'delete', id: session.id});
        }
      });
  }

  getBlockedUsers() {
    this.loading.blockedUsers = true;
    return this.api.get<User[]>('user/blocked')
      .pipe(
        map((data: any) => {
          for (const block of data) {
            this.blockedUsers.push(new User().deserialize(block));
          }
        }),
        finalize(() => {
          this.loading.blockedUsers = false;
        })
      );
  }

  getLanguages() {
    this.data.getLanguages()
      .subscribe((languages: Language[]) => {
        this.languages = languages;
      });
  }

  getSessions(dataLength?: number) {
    this.loading.sessions = true;
    let param = '';
    if (dataLength > 0) {
      param = `?s=${dataLength}`;
    }
    return this.api.get<any>('auth' + param)
      .pipe(
        map((data: any) => {
          if (data.length === 0) {
            this.end.sessions = true;
            return this.activeSessions;
          }
          const sessions = [];
          for (const s of data) {
            sessions.push(new Session().deserialize(s));
          }

          return sessions;
        }),
        finalize(() => {
          this.loading.sessions = false;
        })
      );
  }

  getNextSessions(e: number) {
    if (this.end.sessions || this.loading.sessions) {
      return;
    }

    const end = this.sessionsViewport.getRenderedRange().end;
    const total = this.sessionsViewport.getDataLength();
    console.log(`${end}, '>=', ${total}`);
    if (end === total) {
      this.loadingData.session.next(total);
    }
  }

  selectImage(event: MouseEvent, image: string) {
    if (this.settings.backgroundImage === image) {
      return;
    }
    if (this.selectedImage) {
      this.selectedImage.classList.remove('motifun-border');
    }

    this.selectedImage = event.target as HTMLElement;
    this.selectedImage.classList.add('motifun-border');

    this.settingsService.updateBackgroundImage(image)
      .subscribe((settings: Settings) => {
        this.alertService.appendAlert(
          new Alert(
            'Gespeichert',
            'Hintergrund erfolgreich geändert.',
            AlertType.SUCCESS
          )
        );
      });
  }

  setTheme(event: MouseEvent, color: string) {
    if (this.settings.backgroundColor === color) {
      return;
    }
    if (this.selectedColor) {
      this.selectedColor.classList.remove('motifun-border');
    }

    this.selectedColor = event.target as HTMLElement;
    this.selectedColor.classList.add('motifun-border');

    this.settingsService.updateBackgroundColor(color)
      .subscribe((settings: Settings) => {
        this.alertService.appendAlert(
          new Alert(
            'Gespeichert',
            'Hintergrund erfolgreich geändert.',
            AlertType.SUCCESS
          )
        );
      });
  }

  updateEmail() {
    if (this.emailForm.invalid) {
      this.alertService.appendAlert(new Alert(
        'Ungültige E-Mail',
        'Die eingegebene E-Mail ist ungültig.',
        AlertType.ERROR
      ));
    }
    const value = this.emailForm.getRawValue();
    this.api.put<any>('settings/email', value)
      .subscribe(data => {
        // TODO: handle DB result
        console.log(data);
      });
  }

  updatePassword() {
    if (this.passwordForm.invalid) {
      this.alertService.appendAlert(new Alert(
        'Ungültige Passwörter',
        'Die eingegebenen Passworter stimmen nicht überein.',
        AlertType.ERROR
      ));
    }
    const values = this.passwordForm.getRawValue();
    this.api.put<any>('settings/password', values)
      .subscribe(data => {
        // TODO: handle DB result
        console.log(data);
      });
  }

  updateLanguage() {
    if (this.languageForm.invalid) {
      this.alertService.appendAlert(new Alert(
        'Ungültige Sprache',
        'Die ausgewählte Sprache ist ungültig.',
        AlertType.ERROR
      ));
    }
    const languages = this.languageForm.getRawValue().language;
    let value;
    if (languages && languages.length > 0) {
      value = {language: languages[0]};
    }

    this.api.put<any>('settings/language', value)
      .subscribe(data => {
        // TODO: handle DB result
        if (data.language) {
          this.user.settings.language = data.language;
        }
      });
  }

  onDarkMode(event: MatSlideToggleChange) {
    this.settingsService.updateBackgroundTheme(event.checked)
      .subscribe((settings: Settings) => {
        this.alertService.appendAlert(
          new Alert(
            'Gespeichert',
            `Nachtmodus erfolgreich ${event.checked ? 'aktiviert' : 'deaktiviert'}.`,
            AlertType.SUCCESS
          )
        );
      });
  }
}
