import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {ApiService} from './api.service';
import {merge, Observable, of, Subject} from 'rxjs';
import {share, shareReplay} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private redirectUrl: string;

  private signedOut$: Subject<boolean> = new Subject<boolean>();
  private authenticated$: Subject<boolean> = new Subject<boolean>();

  constructor(private router: Router,
              private api: ApiService) {
  }

  setToken(token: string) {
    if (token) {
      localStorage.setItem(environment.storageKeys.jwt, token);
    }
    this.authenticated$.next(true);
  }

  getToken() {
    return localStorage.getItem(environment.storageKeys.jwt);
  }

  getAuthToken(): string {
    const token = localStorage.getItem(environment.storageKeys.jwt);
    if (token) {
      return 'Bearer ' + token;
    }
    return null;
  }

  refreshToken() {
    return this.api.get('auth/refresh');
  }

  isLoggedIn() {
    return this.getToken() !== null;
  }

  onAuthenticated() {
    return this.authenticated$.pipe(shareReplay(1));
  }

  isAuthenticated(): Observable<boolean> {
    return merge(
      of(this.isLoggedIn()),
      this.onAuthenticated()
    )
      .pipe(share());
  }

  onIsSignedIn() {
    return of(this.isLoggedIn());
  }

  onSignedOut() {
    return this.signedOut$;
  }

  logout() {
    this.clear();
    this.authenticated$.next(false);
    return this.router.navigate(['/login']);
  }

  setRedirectUrl(url: string) {
    this.redirectUrl = url;
  }

  getRedirectUrl() {
    return this.redirectUrl;
  }

  clear() {
    localStorage.clear();
  }
}
