import {Component, Input, OnInit} from '@angular/core';
import {Notification} from '../notification';

@Component({
  selector: 'app-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrls: ['./notification-item.component.css']
})
export class NotificationItemComponent {

  @Input() notification: Notification;

  constructor() {
  }
}
