import {Component, Input, OnInit} from '@angular/core';
import {Notification} from '../notification';

@Component({
  selector: 'app-notification-event-updated',
  templateUrl: './notification-event-updated.component.html',
  styleUrls: ['./notification-event-updated.component.css']
})
export class NotificationEventUpdatedComponent {

  @Input() notification: Notification;

  constructor() {
  }
}
