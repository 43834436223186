import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NonAuthGuard} from '../../core/guards/non-auth.guard';
import {RegisterComponent} from './register/register.component';

const registerRoutes: Routes = [
  {
    path: '',
    canActivateChild: [NonAuthGuard],
    component: RegisterComponent
  }
];


@NgModule({
  imports: [
    RouterModule.forChild(registerRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class RegisterRoutingModule {
}
