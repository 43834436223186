import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {MaterialModule} from '../material.module';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {RoundPipe} from './pipes/round.pipe';
import {UiElementsModule} from '../modules/ui-elements/ui-elements.module';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {BaseListWrapperComponent} from './helper/base-list-wrapper.component';


@NgModule({
  declarations: [
    RoundPipe,
    BaseListWrapperComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MaterialModule,
    ScrollingModule,
    UiElementsModule
  ],
  exports: [
    FormsModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    RouterModule,
    MaterialModule,
    UiElementsModule,
    ScrollingModule,
    RoundPipe,
    BaseListWrapperComponent
  ]
})
export class SharedModule {
}
