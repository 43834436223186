export enum NotificationType {
  eventComment = 1,
  eventAttender = 2,
  eventObserver = 3,
  eventStarts = 4,
  eventEnded = 5,
  eventEdited = 6,
  friendRequest = 7,
  newFriendship = 8
}
