import {Component} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {User} from '../../../shared/models/user';
import {ApiService} from '../../../core/services/api.service';
import {map, share, shareReplay, switchMap} from 'rxjs/operators';
import {Observable, zip} from 'rxjs';
import {UserService} from '../../../core/services/user.service';
import {ResizeService} from '../../../core/services/resize.service';
import {faChartLine, faUserCircle} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent {

  loading = {
    profile: false,
    awards: false,
    activities: false
  };

  icons = {
    profile: faUserCircle,
    activity: faChartLine
  };

  profileId$: Observable<string>;

  signedInUser$: Observable<User>;
  user$: Observable<User>;

  user: User;
  ownProfile = false;

  constructor(private activatedRoute: ActivatedRoute,
              private api: ApiService,
              private router: Router,
              private resize: ResizeService,
              private userService: UserService) {
    this.profileId$ = this.activatedRoute.paramMap
      .pipe(
        map((params: ParamMap) => params.get('id')),
        shareReplay(1)
      );

    this.signedInUser$ = zip(
      this.userService.getUser(),
      this.profileId$
    ).pipe(
      map((data: any) => {
        this.ownProfile = data[0].id === data[1] || data[1] === -1;

        return data[0];
      }),
      share()
    );

    this.user$ = this.signedInUser$
      .pipe(
        switchMap(() => this.profileId$),
        switchMap((id: string) => {
          if (!this.ownProfile) {
            return this.userService.getProfile(id);
          }
          return this.userService.getUser();
        }),
        map((user: User) => {
          this.user = user;

          return user;
        }),
        shareReplay(1)
      );
  }
}
