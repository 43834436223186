import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {NotificationsRoutingModule} from './notifications-routing.module';
import {NotificationEventUpdatedComponent} from './notification-event-updated/notification-event-updated.component';
import {NotificationEventAttenderComponent} from './notification-event-attender/notification-event-attender.component';
import {NotificationEventEndedComponent} from './notification-event-ended/notification-event-ended.component';
import {NotificationFriendRequestComponent} from './notification-friend-request/notification-friend-request.component';
import {NotificationFriendshipComponent} from './notification-friendship/notification-friendship.component';
import {NotificationItemComponent} from './notification-item/notification-item.component';
import {NotificationListComponent} from './notification-list/notification-list.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {MaterialModule} from '../../material.module';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {NotificationUserInfoComponent} from './notification-user-info/notification-user-info.component';
import {NotificationEventInfoComponent} from './notification-event-info/notification-event-info.component';
import {EventsModule} from '../events/events.module';

@NgModule({
  declarations: [
    NotificationEventAttenderComponent,
    NotificationEventEndedComponent,
    NotificationEventUpdatedComponent,
    NotificationUserInfoComponent,
    NotificationFriendRequestComponent,
    NotificationFriendshipComponent,
    NotificationItemComponent,
    NotificationListComponent,
    NotificationEventInfoComponent
  ],
  imports: [
    CommonModule,
    NotificationsRoutingModule,
    FontAwesomeModule,
    MaterialModule,
    ScrollingModule,
    EventsModule
  ],
  exports: []
})
export class NotificationsModule {
}
