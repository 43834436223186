<div class="scrollable-content">
  <ng-container *ngIf="me$ | async as me">
    <div class="motifun-box flex center">
      <span class="title">Freundschaftsanfragen</span>
    </div>
    <div class="flex row">
      <div class="nav-arrows">
        <fa-icon [icon]="icons.left"></fa-icon>
      </div>
      <ul>
        <cdk-virtual-scroll-viewport
          #requestsList
          [itemSize]="requestsWidth"
          orientation="horizontal"
          (scrolledIndexChange)="handleGetNextRequests($event)"
          class="attendantsList horizontal"
          style="height: 190px;">
          <li *cdkVirtualFor="let user of requests$ | async; trackBy: trackByRequests"
              style="float: left;">
            <app-friend-request-item [user]="user"></app-friend-request-item>
          </li>
          <li *ngIf="loading.requests" style="width: 100%;">
            <mat-progress-spinner
              color="primary"
              mode="indeterminate">
            </mat-progress-spinner>
          </li>
        </cdk-virtual-scroll-viewport>
      </ul>
      <div class="nav-arrows">
        <fa-icon [icon]="icons.right"></fa-icon>
      </div>
    </div>

    <div class="motifun-box flex col center">
      <span class="title">Freunde</span>

      <mat-form-field id="searchFriends" class="col s10">
        <mat-icon matPrefix>search</mat-icon>
        <input matInput name="search" type="text" placeholder="Durchsuche Deine Freunde..."
               autocomplete="off" (keyup)="onSearchFriends()"
               [formControl]="friendSearch">
      </mat-form-field>
    </div>
    <ul *ngIf="friendSearch.invalid">
      <li
        *ngFor="let user of friends$ | async"
      >
        <app-user-item [user]="user" [ownUserId]="me.id"></app-user-item>
      </li>
      <li *ngIf="loading.friends">
        <mat-progress-spinner
          color="primary"
          mode="indeterminate">
        </mat-progress-spinner>
      </li>
    </ul>
    <ul *ngIf="friendSearch.valid">
      <li
        *ngFor="let user of searched$ | async; trackBy: trackByFriends"
      >
        <app-user-item [user]="user" [ownUserId]="me.id"></app-user-item>
      </li>
      <li *ngIf="loading.searched">
        <mat-progress-spinner
          color="primary"
          mode="indeterminate">
        </mat-progress-spinner>
      </li>
    </ul>
  </ng-container>
</div>
