<section id="top" class="fullscreen img flex-center"
         [ngStyle]="{'background-image': 'url(' + images.backgrounds.top + ')'}">
  <div class="overlay"></div>
  <div class="scroll-btn-sec">
    <a [ngxScrollToDuration]="300"
       [ngxScrollTo]="'what-is-it'"></a>
  </div>

  <div id="top-container" class="row">
    <div id="logo-top-container">
      <div id="logo-top"
           [ngStyle]="{'background-image': 'url(' + images.logos.top + ')'}"></div>
    </div>

    <div id="form-container" *ngIf="!isProduction">
      <mat-tab-group mat-stretch-tabs class="mat-elevation-z4 motifun-box">
        <mat-tab label="Anmelden">
          <app-login-form></app-login-form>
        </mat-tab>
        <mat-tab label="Registrieren">
          <app-register-form></app-register-form>
        </mat-tab>
      </mat-tab-group>
    </div>

    <div id="top-infotext" *ngIf="isProduction">
      <h3>Die Sport-community in Deiner Nähe!</h3>
      <p>MotiFun ist der kostenlose, zentrale Anlaufpunkt für Sportler im Web, der es erlaubt, Trainings- und
        Sportpartner in über 130 Sportarten zu finden.</p>
      <p>Wenn du deine E-Mail Adresse hier einträgst, informieren wir dich, sobald unser Portal freigeschaltet wird.</p>
      <div class="row">
        <div class="col s9">
          <input type="email" placeholder="Deine E-Mail Adresse">
        </div>
        <div class="col s3">
          <button mat-flat-button color="primary" (click)="handlePreLaunchClick()">Anmelden</button>
        </div>
      </div>
    </div>
  </div>
  <p>
    Auf MotiFun kannst du andere Sportler finden, dich mit ihnen austauschen und zum gemeinsamen Training verabreden.
  </p>

  <div id="social-icons">
    <ul>
      <li>
        <a href="https://www.instagram.com/motifun_official/" target="_blank" class="instagram">
          <fa-icon [icon]="icons.instagram"></fa-icon>
        </a>
      </li>
      <li>
        <a href="https://twitter.com/motifun" target="_blank" class="twitter">
          <fa-icon [icon]="icons.twitter"></fa-icon>
        </a>
      </li>
      <li>
        <a href="https://www.facebook.com/motifun" target="_blank" class="facebook">
          <fa-icon [icon]="icons.facebook"></fa-icon>
        </a>
      </li>
    </ul>
  </div>
</section>

<section class="flex col white center" id="what-is-it">
  <div class="title-box">
    <p>Was ist MotiFun</p>
    <hr>
  </div>
  <div class="flex m-rcol row center">
    <div class="flex col s5 m10 preview-img" [ngStyle]="{'background-image': 'url(' + images?.what?.beach + ')'}"></div>
    <div class="flex col s5 m10 text start">
      <p>MotiFun ist eine Plattform, auf der du andere Sportler finden und dich mit ihnen vernetzen kannst. Die Idee
        ist, dass ihr euch, durch gemeinsames Sport treiben, gegenseitig motiviert, um so noch mehr Spaß am Sport
        habt.
      </p>
      <p>Dies funktioniert, indem du entweder Suchanfragen, so genannte Events, erstellst oder bestehenden Events
        beitrittst. Events erstellst du in einem einfachen Prozess: Zuerst wählst du die Sportart aus, dann Ort und
        Zeit und schließlich vergibst du einen unverkennbaren Titel und eine Beschreibung. Klingt einfach? Ist es
        auch! Momentan haben wir in unserer Datenbank über 130 verschiedene Sportarten gespeichert. Falls wir trotzdem
        deine Lieblingssportart vergessen haben sollten, dann zögere nicht uns zu informieren. Wir werden
        versuchen sie dann so schnell wie möglich aufzunehmen.
      </p>
      <p *ngIf="false">Innerhalb von MotiFun hat jeder Nutzer sein eigenes Profil. Dort können z.B. Lieblingssportarten,
        bisherige
        Erfolge oder Bestzeiten anderen Nutzern präsentiert werden.
      </p>
    </div>
  </div>
</section>

<section class="flex col grey center" id="how-to">
  <div class="title-box">
    <p>So einfach funktioniert's!</p>
    <hr>
  </div>
  <div class="flex row center steps">
    <div class="flex col center">
      <div class="preview-img" [ngStyle]="{'background-image': 'url(' + images?.howTo?.register + ')'}"></div>
      <div class="step">
        <span>1.</span>
        <p>Registriere dich auf unserer Plattform</p>
      </div>
    </div>
    <div class="flex col center">
      <div class="preview-img" [ngStyle]="{'background-image': 'url(' + images?.howTo?.search + ')'}"></div>
      <div class="step">
        <span>2.</span>
        <p>Erstelle oder suche Events und finde Sportler in deiner Nähe</p>
      </div>
    </div>
    <div class="flex col center">
      <div class="preview-img" [ngStyle]="{'background-image': 'url(' + images?.howTo?.sport + ')'}"></div>
      <div class="step">
        <span>3.</span>
        <p>Nun heißt es: Computer aus und raus zum Sport! :)</p>
      </div>
    </div>
  </div>
</section>

<div class="dyn-triangle"><p class="left-up grey"></p></div>

<section class="flex center" id="advantages">
  <div class="title-box">
    <p>Deine Vorteile</p>
    <hr>
  </div>
  <ul class="text start">
    <li><p>Mehr Spaß und Motivation beim Sport</p></li>
    <li><p>Andere sportbegeisterte Menschen kennen lernen</p></li>
    <li><p>Den perfekten Trainingspartner finden</p></li>
    <li><p>Gemeinsam neue Sportarten ausprobieren</p></li>
    <li><p>Schnell und unkompliziert Sport-Events erstellen oder an anderen teilnehmen</p></li>
  </ul>
  <div id="left-img" class="preview-img artwork"
       [ngStyle]="{'background-image': 'url(' + images.artworks.dumbbell + ')'}"></div>
  <div id="right-img" class="preview-img artwork"
       [ngStyle]="{'background-image': 'url(' + images.artworks.football + ')'}"></div>
</section>

<div class="dyn-triangle"><p class="left-up white"></p></div>

<section class="flex row grey" id="desktop">
  <div class="flex center col s6 m10 img-col">
    <div class="preview-img" [ngStyle]="{'background-image': 'url(' + images?.versions?.desktop + ')'}"></div>
  </div>
  <div class="flex center-v col s4 m10">
    <div class="title-box">
      <p>Desktop-Version</p>
      <hr>
    </div>
    <p class="text">
      Mit unserer Desktop-Version siehst du alle Events auf den ersten Blick und findest schnell und einfach
      Sportler in deiner Nähe oder kannst selber nach Mitmachern suchen. Registriere dich kostenlos
      und werde Teil unserer Community!
    </p>
    <a [ngxScrollToDuration]="300" [ngxScrollTo]="'top'" color="primary"
       mat-flat-button>Jetzt anmelden
    </a>
  </div>
</section>

<div class="dyn-triangle"><p class="left-down grey"></p></div>

<section class="flex row white" id="mobile">
  <div class="flex center-v col s6 m10">
    <div class="title-box">
      <p>Mobile Version</p>
      <hr>
    </div>
    <p class="text">
      Unsere Webseite wurde für Mobile Endgeräte und Tablets optimiert! Damit bist du auch unterwegs immer mit
      Sportlern in deiner Nähe verbunden und kannst sponatan an Sportevents teilnehmen. Falls unser Angebot gut genutzt
      wird, werden wir eine App für Android und iOS nachreichen.
    </p>
    <a [ngxScrollToDuration]="300" [ngxScrollTo]="'top'" color="primary"
       mat-flat-button>Jetzt anmelden
    </a>
  </div>
  <div class="flex center col s4 m10 img-col">
    <div class="preview-img" [ngStyle]="{'background-image': 'url(' + images?.versions?.mobile + ')'}"></div>
  </div>
</section>

<div class="egg"></div>

<section class="flex center grey" id="target">
  <div id="top-right" class="preview-img artwork">
    <div id="img-bb" class="preview-img artwork"
         [ngStyle]="{'background-image': 'url(' + images.artworks.basketball + ')'}"></div>
    <div id="img-drink" class="preview-img artwork"
         [ngStyle]="{'background-image': 'url(' + images.artworks.drink + ')'}"></div>
  </div>

  <div id="img-win" class="preview-img artwork"
       [ngStyle]="{'background-image': 'url(' + images.artworks.win + ')'}"></div>
  <div class="title-box">
    <p>Unsere Ziele</p>
    <hr>
  </div>
  <ul class="text start">
    <li>
      <p>
        Unser primäres Ziel liegt darin, wieder mehr Leute von der Couch zu holen und zum Sport zu animieren. Egal
        ob Anfänger, Freizeit- oder Leistungssportler - hier ist jeder willkommen!
      </p>
    </li>
    <li>
      <p>
        Wir versuchen durch unsere Community sportbegeisterte Menschen schnell und einfach zueinander zu
        führen.
      </p>
    </li>
    <li>
      <p>
        Es ist unsere Vision, in den nächsten Jahren die führende Sportcommunity im deutschsprachigen
        Raum zu werden.
      </p>
    </li>
  </ul>
</section>

<section class="flex center grey" id="support">
  <div class="title-box">
    <p>Wie kann ich euch Helfen?</p>
    <hr>
  </div>
  <div class="flex col center-h text">
    <p>
      Du hast coole Bilder, die unsere Sportarten bzw. Events noch besser zur Geltung bringen? Dann schicke uns doch
      einfach deine Vorschläge per E-Mail an <a href="mailto:photos@motifun.de?subject">photos@motifun.de</a>. Eine
      Einschränkung gibt es allerdings: Die Fotos müssen entweder lizenzfrei oder von dir aufgenommen worden sein.
      Andernfalls ist es uns nicht erlaubt sie zu nutzen.
    </p>
    <p>
      Dir ist ein Fehler aufgefallen? Zögere nicht ihn uns mitzuteilen! Es ist unser Anspruch dir die bestmögliche
      Nutzererfahrung zu geben. Wir haben <a href="https://gitlab.com/motifun/issue-tracker" target="_blank">hier eine
      Meldeseite</a>
      erstellt. Dort kannst zu zum einen Fehler melden, zum
      anderen uns aber auch deine Wünsche und Verbesserungsvorschläge mitteilen.
    </p>
    <p>
      Wie du vielleicht gemerkt hast, ist unsere Plattform standardmäßig werbe- und trackingfrei, weswegen wir auf
      deinen guten Willen angewiesen sind. <a [routerLink]="['/coffee']">Hier findest du unsere Kaffeekasse</a>. Dort
      freuen wir uns über jegliche Unterstützung. <br>
      <strong>Wir danken dir für deine Unterstützung!</strong>
    </p>
  </div>
</section>

<section class="flex center white" id="contact">
  <div class="title-box">
    <p>Kontakt</p>
    <hr>
  </div>

  <div id="img-tennis" class="preview-img artwork"
       [ngStyle]="{'background-image': 'url(' + images.artworks.tennis + ')'}"></div>
  <div id="img-skate" class="preview-img artwork"
       [ngStyle]="{'background-image': 'url(' + images.artworks.skate + ')'}"></div>

  <div id="to-the-top" class="flex center">
    <a [ngxScrollToDuration]="300"
       [ngxScrollTo]="'top'">
      <fa-icon [icon]="icons.arrowUp" class="motifun"></fa-icon>
    </a>
  </div>

  <div class="container text">
    <p class="text center">
      Du hast noch Fragen und/oder Verbesserungsvorschläge? Dann her damit! Wir freuen uns über dein Feedback. Verwende
      dazu entweder das Kontaktformular oder sende uns eine E-Mail an
      <a href="mailto:fragen@motifun.de?subject=">fragen@motifun.de</a>.
    </p>
    <form class="row">
      <div class="row">
        <mat-form-field class="col s10">
          <input matInput
                 type="text"
                 name="name"
                 placeholder="Dein Name"
                 [(ngModel)]="contact.name">
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field class="col s10">
          <input matInput
                 type="email"
                 name="email"
                 placeholder="Deine E-Mail"
                 [(ngModel)]="contact.email">
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field class="col s10">
          <textarea matInput
                    class="materialize-textarea"
                    name="text"
                    placeholder="Deine Nachricht"
                    [(ngModel)]="contact.text"></textarea>
        </mat-form-field>
      </div>
      <button mat-flat-button color="primary" type="submit"
              (click)="submitContact()">Senden
      </button>
    </form>
  </div>
</section>
