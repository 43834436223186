import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {EventComponent} from './event/event.component';
import {AuthGuard} from '../../core/guards/auth.guard';
import {EventFormComponent} from './event-form/event-form.component';

const eventRoutes: Routes = [
  {
    path: '',
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'create',
        component: EventFormComponent
      },
      {
        path: ':id',
        component: EventComponent
      },
      {
        path: '**',
        redirectTo: '/'
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(eventRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class EventsRoutingModule {
}
