import {Component, ViewChild} from '@angular/core';
import {ApiService} from '../../../core/services/api.service';
import {User} from '../../../shared/models/user';
import {BehaviorSubject, merge, Observable, Subject} from 'rxjs';
import {UserService} from '../../../core/services/user.service';
import {ScrollService} from '../../../core/services/scroll.service';
import {ResizeService} from '../../../core/services/resize.service';
import {debounceTime, filter, switchMap, tap} from 'rxjs/operators';
import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {UntypedFormControl, Validators} from '@angular/forms';
import {faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-friend-list',
  templateUrl: './friend-list.component.html',
  styleUrls: ['./friend-list.component.css']
})
export class FriendListComponent {

  private requestsPerPage: number;
  private friendsPerPage: number;
  private searchFriendsPerPage: number;
  private scrollOffset: number;

  friendSearch: UntypedFormControl = new UntypedFormControl('', [Validators.required, Validators.minLength(1)]);

  icons = {
    left: faChevronLeft,
    right: faChevronRight
  };

  loading = {
    friends: false,
    requests: false,
    searched: false
  };

  searching = false;
  friendsHeight: number;
  friendsWidth: number;
  requestsHeight: number;
  requestsWidth: number;

  me$: Observable<User>;

  friendsScroll$: Observable<number>;
  friendsResize$: Observable<number>;
  friendsInitial$: BehaviorSubject<number> = new BehaviorSubject(0);

  friendsLoad$: Observable<any>;
  friendsUpdate$: Subject<boolean> = new Subject();

  friends$: Observable<User[]>;

  requestsScroll$: BehaviorSubject<number> = new BehaviorSubject(0);
  requestsResize$: Observable<number>;

  requestsLoad$: Observable<number>;
  requestsUpdate$: Subject<number> = new Subject();

  requests$: Observable<User[]>;

  searchedScroll$: Observable<number>;
  searchedResize$: Observable<number>;
  searchedInitial$: Subject<number> = new Subject();

  searchedLoad$: Observable<number>;
  searchedUpdate$: Subject<number> = new Subject();

  searched$: Observable<User[]>;

  @ViewChild('requestsList') requestsList: CdkVirtualScrollViewport;

  constructor(private api: ApiService,
              private userService: UserService,
              private scroll: ScrollService,
              private resize: ResizeService) {
    this.scrollOffset = 50;
    this.friendsHeight = 296;
    this.friendsWidth = 275;
    this.requestsHeight = 150;
    this.requestsWidth = 180;
    this.friendsPerPage = this.searchFriendsPerPage = 12;
    this.requestsPerPage = 10;

    this.me$ = userService.getUser();

    this.friendsScroll$ = scroll.onWindowScrollY(document.body, this.scrollOffset, 250, this.friendsHeight, this.friendsPerPage);
    this.friendsResize$ = resize.onScreenResizeX(document.body, this.friendsWidth, this.friendsPerPage);

    this.friendsLoad$ = merge(this.friendsInitial$, this.friendsScroll$, this.friendsResize$)
      .pipe(
        tap(() => this.loading.friends = true)
      );

    this.friends$ = merge(this.friendsLoad$, this.friendsUpdate$)
      .pipe(
        switchMap((page: number) => {
          return merge(this.userService.getFriends(), this.userService.onFriendsChange());
        }),
        tap(() => this.loading.friends = false)
      );

    this.searchedScroll$ = scroll.onWindowScrollY(document.body, this.scrollOffset, 250, this.friendsHeight, this.searchFriendsPerPage);
    this.searchedResize$ = resize.onScreenResizeX(document.body, this.friendsWidth, this.searchFriendsPerPage);

    this.searchedLoad$ = merge(this.searchedInitial$, this.searchedScroll$, this.searchedResize$)
      .pipe(
        tap(() => this.loading.searched = true)
      );

    this.searched$ = merge(this.searchedLoad$, this.searchedUpdate$)
      .pipe(
        filter(() => this.friendSearch.valid),
        debounceTime(250),
        switchMap((page: number) => {
          return merge(this.userService.getFindFriends(this.friendSearch.value), this.userService.onSearchChange());
        }),
        tap(() => this.loading.searched = false)
      );

    this.requestsResize$ = resize.onScreenResizeX(document.body, this.requestsWidth, this.requestsPerPage);

    this.requestsLoad$ = merge(this.requestsScroll$, this.requestsResize$)
      .pipe(
        tap(() => this.loading.requests = true)
      );

    this.requests$ = merge(this.requestsLoad$, this.requestsUpdate$)
      .pipe(
        switchMap((page: number) => {
          return merge(this.userService.getFriendRequests(), this.userService.onRequestsChange());
        }),
        tap(() => this.loading.requests = false),
        tap((users: User[]) => console.log(users))
      );
  }

  onSearchFriends() {
    if (this.friendSearch.invalid) {
      return;
    }

    this.searchedInitial$.next(this.friendSearch.value.length);
  }

  handleGetNextRequests($event: number) {
    const end = this.requestsList.getRenderedRange().end;
    const total = this.requestsList.getDataLength();
    if (end === total) {
      this.requestsScroll$.next(this.requestsList.getDataLength() / this.requestsPerPage);
    }
  }

  trackByFriends(index: number, item: User): string {
    console.log(item);
    return item.id;
  }

  trackByRequests(index: number, item: User): string {
    console.log(item);
    return item.id;
  }
}
