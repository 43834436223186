import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export class PasswordValidator {

  static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        // if control is empty return no error
        return null;
      }

      // test the value of the control against the regexp supplied
      const valid = regex.test(control.value);

      // if true, return no error (no error), else return error passed in the second parameter
      return valid ? null : error;
    };
  }

  static equalPasswords(passwordKey: string, passwordConfirmKey: string) {
    return (control: AbstractControl): { [key: string]: any } => {

      if (control.get(passwordKey).value === control.get(passwordConfirmKey).value) {
        return null;
      }

      control.get(passwordConfirmKey).setErrors({differentPasswords: true});

      return {invalid: true};
    };
  }

  static passwordMatchValidator(control: AbstractControl) {
    const password: string = control.get('password').value; // get password from our password form control
    const confirmPassword: string = control.get('confirmPassword').value; // get password from our confirmPassword form control
    // compare is the password math
    if (password !== confirmPassword) {
      // if they don't match, set an error in our confirmPassword form control
      return {invalid: true};
    }

    return null;
  }

  static strengthValidator() {
    return (control: AbstractControl): { [key: string]: any } => {
      let count = 0;

      const lowerCase = new RegExp('[a-zäöüß]');
      const upperCase = new RegExp('[A-ZÄÖÜ]');
      const number = new RegExp('\\d');
      const special = new RegExp('[ !@#$%^&*()_+\\-=\\[\\]{};\':"\|,.<>\\/?]');

      if (!control.value) {
        return null;
      }

      if (lowerCase.test(control.value)) {
        count++;
      }

      if (upperCase.test(control.value)) {
        count++;
      }

      if (number.test(control.value)) {
        count++;
      }

      if (special.test(control.value)) {
        count++;
      }

      if (count < 2) {
        return {strength: count};
      } else {
        return null;
      }
    };
  }
}
