import {Injectable} from '@angular/core';
import {User} from '../../shared/models/user';
import {Notification} from '../../modules/notifications/notification';
import {ApiService} from './api.service';
import {AuthService} from './auth.service';
import {map, share} from 'rxjs/operators';
import {Sport} from '../../shared/models/sport';
import {Language} from '../../shared/models/language';
import {Observable, of, ReplaySubject, Subscription} from 'rxjs';
import {SettingsService} from './settings.service';
import {ChatService} from './chat.service';
import {EventService} from './event.service';
import {UserService} from './user.service';

@Injectable({
  providedIn: 'root'
})
export class DataStoreService {

  private notifications: Notification[] = [];
  private sports: Sport[];
  private languages: Language[] = [];

  private observables: { [key: string]: Observable<any> } = {
    sports: null,
    user: null,
    notifications: null
  };

  private notifications$: ReplaySubject<Notification> = new ReplaySubject(1);
  private notificationRemoved$: ReplaySubject<Notification> = new ReplaySubject(1);

  private loading = {
    notifications: false
  };

  constructor(private api: ApiService,
              private auth: AuthService,
              private settings: SettingsService,
              private chats: ChatService,
              private events: EventService,
              private user: UserService) {
  }

  handleLogout() {
    this.auth.logout();
  }

  getNotifications(user: User, lastId?: number): Observable<Notification[]> {
    if (!lastId && this.notifications.length > 0) {
      console.log('already received data');
      return of(this.notifications);
    } else if (this.observables.notifications && this.loading.notifications) {
      console.log('already loading notifications');
      return this.observables.notifications;
    } else {
      console.log('load notifications');
      let params = '';
      if (lastId > 0 && this.notifications.length > 0) {
        params = '?id=' + this.notifications[this.notifications.length - 1].id;
      }
      this.observables.notifications = this.api.get<any>(`notification${params}`)
        .pipe(
          map((data: any) => {
            const notifications: Notification[] = [];
            for (const notification of data) {
              notifications.push(new Notification(user).deserialize(notification));
            }

            this.notifications = this.notifications.concat(notifications);

            return notifications;
          }),
          share()
        );
      return this.observables.notifications;
    }
  }

  getCachedNotifications(): Notification[] {
    return this.notifications;
  }

  onNotification(): Observable<Notification> {
    return this.notifications$;
  }

  onNotificationRemoved(): Observable<Notification> {
    return this.notificationRemoved$;
  }

  setNotifications(notifications: Notification[]) {
    this.notifications = notifications;
  }

  appendNotification(notification: Notification) {
    // TODO: check if needed.
    this.notifications.push(notification);
    this.notifications$.next(notification);
  }

  removeNotification(notification: Notification) {
    for (let i = 0; i < this.notifications.length; i++) {
      if (this.notifications[i].id === notification.id) {
        this.notifications.splice(i, 1);
        this.notificationRemoved$.next(notification);
        return;
      }
    }
  }

  getSports() {
    if (this.sports) {
      // if `data` is available just return it as `Observable`
      return of(this.sports);
    } else if (this.observables.sports) {
      // if `this.observable` is set then the request is in progress
      // return the `Observable` for the ongoing request
      return this.observables.sports;
    } else {
      // example header (not necessary)
      this.observables.sports = this.api.get<any>('sports').pipe(
        map((data: any) => {
          this.sports = [];
          for (const s of data) {
            this.sports.push(new Sport().deserialize(s));
          }

          return this.sports;
        }),
        share()
      );
      return this.observables.sports;
    }
  }

  getLanguages(): Observable<Language[]> {
    if (this.languages && this.languages.length > 0) {
      return of(this.languages);
    }
    return this.api.get<any[]>('settings/languages')
      .pipe(
        map((languages: any) => {
          console.log('get languages');
          for (const l of languages) {
            this.languages.push(new Language().deserialize(l));
          }
          console.log('mapped');

          return this.languages;
        })
      );
  }

  clear() {
    this.chats.clear();
    this.events.clear();
    this.settings.clear();
    this.user.clear();
  }
}
