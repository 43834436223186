<div class="row flex-col motifun-box section">
  <div id="user" class="inner-row flex-row">
    <div class="col-6 flex-col">
      <div class="flex-row">
        <div style="width: 125px; margin-right: 25px;" [routerLink]="['/user', user.id]">
          <div class="img medium1 circle link"
               [ngStyle]="{'background-image':'url(' + user.getMedImgUrl() + ')'}"></div>
        </div>
        <div class="col-flex flex-col">
          <span id="name">{{user.getFullName()}}</span>
          <p class="fill-remaining-space">{{user.target}}</p>
        </div>
      </div>
      <div class="flex center" style="margin-top: 0.8rem; font-size: 1.2em;">
        <a [routerLink]="['/messages', user.id]">
          <fa-icon [icon]="icons.message"></fa-icon>
        </a>
        <a>
          <fa-icon [icon]="icons.block"></fa-icon>
        </a>
        <a>
          <fa-icon [icon]="icons.flag"></fa-icon>
        </a>
      </div>
    </div>
    <div class="spacer-v"></div>
    <div id="info" class="col-4">
      <p class="section-headline">Infos</p>
      <div class="inner-row">
        <span class="col-35 key">Alter</span>
        <span class="col-65 value">{{user.age}}</span>
      </div>
      <div class="inner-row">
        <span class="col-35 key">Wohnort</span>
        <span class="col-65 value">{{user.city}}</span>
      </div>
      <div class="inner-row">
        <span class="col-35 key">Letzter Login</span>
        <span class="col-65 value">{{user.getLastSignIn()}}</span>
      </div>
      <button mat-flat-button color="primary" [routerLink]="['/user', user.id]">
        <fa-icon [icon]="icons.link"></fa-icon>
        <span>Zum Profil</span>
      </button>
    </div>
  </div>
  <div class="spacer-h"></div>
  <div class="about inner-row flex-col">
    <ng-container>
      <span>Über {{user.firstName}}</span>
      <p
        *ngIf="(user.aboutMe && user.aboutMe.length > 0)">{{user.aboutMe ? user.aboutMe : user.firstName + ' hat diesen Teil seines Profils noch nicht ausgefüllt. Vielleicht kannst du ihn ja davon überzeugen.'}}</p>
    </ng-container>
  </div>
</div>

<div class="row flex-col motifun-box section">
  <p class="section-headline">Lieblingssportarten</p>
  <ul class="icon-list">
    <!--<li *ngFor="let icon of user.sports.icon">
      <fa-icon [icon]="icon.icon"></fa-icon>
      <span>{{icon.name}}</span>
    </li>-->
  </ul>
</div>
