<form class="form" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
  <div class="flex row">
    <mat-form-field class="col s10">
      <fa-icon matPrefix [icon]="icons?.email"></fa-icon>
      <input matInput
             type="email"
             name="email"
             placeholder="E-Mail"
             formControlName="email">
      <mat-error *ngIf="loginForm.controls['email'].hasError('required')">
        Die E-Mail Adresse wird <strong>benötigt</strong>
      </mat-error>
      <mat-error *ngIf="loginForm.controls['email'].hasError('email')">
        Die eingegebene E-Mail Adresse ist nicht gültig
      </mat-error>
    </mat-form-field>
  </div>
  <div class="flex row">
    <mat-form-field class="col s10">
      <fa-icon matPrefix [icon]="icons?.password"></fa-icon>
      <input matInput
             type="password"
             name="password"
             placeholder="Passwort"
             formControlName="password">
      <mat-error *ngIf="loginForm.controls['password'].hasError('required')">
        Das Passwort wird <strong>benötigt</strong>
      </mat-error>
    </mat-form-field>
  </div>
  <div class="flex row center oauth" *ngIf="false">
    <button mat-icon-button type="button" class="instagram" (click)="handleSignInWithClick()">
      <fa-icon [icon]="icons.instagram"></fa-icon>
    </button>
    <button mat-icon-button type="button" class="twitter" (click)="handleSignInWithClick()">
      <fa-icon [icon]="icons.twitter"></fa-icon>
    </button>
    <button mat-icon-button type="button" class="facebook" (click)="handleSignInWithClick()">
      <fa-icon [icon]="icons.facebook"></fa-icon>
    </button>
    <button mat-icon-button type="button" class="google" (click)="handleSignInWithClick()">
      <fa-icon [icon]="icons.google"></fa-icon>
    </button>
  </div>
  <div class="flex flex-row text-center flex-1">
    <a class="flex-col w-1/2 text-center" [routerLink]="['/forgot']"><span>Ich habe mein Passwort vergessen</span></a>
    <a class="flex-col w-1/2 text-center" [routerLink]="['/register']"><span>Ich habe bisher keinen Account</span></a>
  </div>
  <button mat-raised-button
          [disabled]="loginForm.invalid"
          type="submit"
          color="primary"
          class="flex-bottom">Anmelden
  </button>
</form>
