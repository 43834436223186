import {Injectable, Injector} from '@angular/core';
import {HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {AuthService} from './auth.service';
import {Alert} from '../../shared/models/alert';
import {AlertService} from './alert.service';
import {Router} from '@angular/router';
import {AlertType} from '../../shared/enums/alert-type';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {catchError, filter, finalize, switchMap, take} from 'rxjs/operators';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  authService: AuthService;
  isRefreshingToken = false;

  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(private alert: AlertService,
              private injector: Injector,
              private router: Router) {
  }

  addToken(req: HttpRequest<any>, token?: string): HttpRequest<any> {

    const headerToken = token ? ('Bearer ' + token) : this.authService.getAuthToken();

    return req.clone({
      headers: req.headers.set('Authorization', headerToken)
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    let authReq;
    if (!this.authService) {
      this.authService = this.injector.get(AuthService);
    }
    if (req.url.includes(environment.apiUrl) && this.authService.isLoggedIn()) {
      authReq = this.addToken(req);
    } else {
      authReq = req.clone();
    }
    return next.handle(authReq)
      .pipe(catchError((error: Error) => {
        if (error instanceof HttpErrorResponse) {
          switch ((<HttpErrorResponse> error).status) {
            case 400:
              return this.handle400Error(error);
            case 401:
              return this.handle401Error(error, req, next);
            case 403:
              if (error.error.msg.includes('Invalid Token | Your token is invalid')) {
                return this.authService.logout();
              }
              return throwError(error);
            case 404:
              this.alert.appendAlert(new Alert(
                'Nicht gefunden',
                'Die von dir gesuchte Seite wurde leider nicht gefunden',
                AlertType.ERROR));
              return this.router.navigate(['/not-found']);
            case 429:
              this.alert.appendAlert(new Alert(
                'Zu viele Versuche',
                'Du hast dich zu oft falsch angemeldet. Bitte versuche es später erneut',
                AlertType.ERROR));
              return throwError(error);
            case 500:
              this.alert.appendAlert(new Alert(
                'Server Fehler',
                'Auf unserer Seite ist leider etwas schief gelaufen. Versuche es bitte erneut.',
                AlertType.ERROR));
              return throwError(error);
            default:
              return throwError(error);
          }
        } else {
          console.log('unknown error occurred');
          return throwError(error);
        }
      }));

  }

  handle400Error(error: HttpErrorResponse) {
    if (error.error && (error.error.message === 'invalid_grant' || error.error.msg === 'invalid token')) {
      // If we get a 400 and the error message is 'invalid_grant', the token is no longer valid so logout.
      return this.logoutUser();
    }

    return throwError(error);
  }

  handle401Error(err: HttpErrorResponse, req: HttpRequest<any>, next: HttpHandler) {
    console.log(err);
    if (err.error && err.error.msg === 'Expired Token | Your token is expired.') {
      if (this.isRefreshingToken === false) {
        this.isRefreshingToken = true;

        // Reset here so that the following requests wait until the token
        // comes back from the refreshToken call.
        this.tokenSubject.next(null);

        return this.authService.refreshToken()
          .pipe(
            switchMap((newToken: any) => {
              if (newToken) {
                this.authService.setToken(newToken);
                this.tokenSubject.next(newToken.token);

                return next.handle(this.addToken(req, newToken.token));
              }

              // If we don't get a new token, we are in trouble so logout.
              return this.logoutUser();
            }),
            catchError(error => {
              console.log(error);
              // If there is an exception calling 'refreshToken', bad news so logout.
              return this.logoutUser();
            }),
            finalize(() => {
              this.isRefreshingToken = false;
            }));
      } else {
        return this.tokenSubject
          .pipe(
            filter(token => token != null),
            take(1),
            switchMap(token => {
              return next.handle(this.addToken(req, token));
            })
          );
      }
    }
    // handle other 401 errors
    console.log(req);
    console.log(err);
    this.alert.appendAlert(new Alert(
      'Fehlerhafte Login Daten',
      'Deine Logindaten stimmen nicht',
      AlertType.ERROR));
    return throwError(err);
  }

  logoutUser() {
    this.authService.logout();

    return throwError('');
  }
}
