<form [formGroup]="formGroup" class="flex col s10 motifun-box">
  <div class="title-box">
    <span>Wo</span>
    <hr>
  </div>
  <app-google-maps [defaultLocation]="null"
                   (location)="onLocationSet($event)"
                   [inputError]="error"
                   [useAutocomplete]="true"
                   [formGroup]="formGroup"
                   [user]="user"></app-google-maps>

  <div class="row">
    <button (click)="submitPlace()" class="col s10" color="primary" mat-raised-button>Ort bestätigen</button>
  </div>
</form>
