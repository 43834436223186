<div #filter id="filter">
  <div [class.sticky]="sticky">
    <button mat-raised-button color="white" (click)="showFilter = !showFilter">
      <mat-icon [style.transform]="showFilter ? 'rotate(180deg)' : ''">filter_list</mat-icon>
      Sucheinstellungen
    </button>

    <app-event-list-filter *ngIf="showFilter"
                           (optionsChange)="onFilterChange($event)"
                           [user]="user"
                           [type]="eventType"></app-event-list-filter>
  </div>
</div>
<div id="event-wrapper">
  <div id="event-data">
    <ng-container *ngIf="events$ | async as events;">
      <ul class="event-list">
        <li
          class="event-item"
          *ngFor="let event of events">
          <app-event-item
            (click)="onEventClick(event)"
            (join)="joinEvent($event)"
            (observe)="observeEvent($event)"
            [event]="event"></app-event-item>
        </li>
      </ul>
      <div class="flex center" *ngIf="loading.events">
        <mat-progress-spinner
          class="example-margin"
          color="primary"
          mode="indeterminate">
        </mat-progress-spinner>
      </div>
      <div class="content-end motifun-box"
           *ngIf="events.length === 0 && end.events">
        Kein Event gefunden - Bitte passe die Sucheinstellungen an oder <a [routerLink]="['/event/create']">erstelle</a>
        selbst ein Event.
      </div>
      <div class="content-end motifun-box" *ngIf="end.events && events.length > 0">
        Nicht fündig geworden? Es gibt leider kein weiteres Event, das deinen Sucheinstellungen entspricht. Versuche die
        Sucheinstellungen anzupassen oder <a [routerLink]="['/event/create']">erstelle</a> dein eigenes Event.
      </div>
    </ng-container>
    <div class="static-button-left">
      <a mat-fab color="primary" [routerLink]="['event', 'create']">
        <mat-icon aria-label="Event erstellen">add</mat-icon>
      </a>
    </div>
  </div>
</div>
