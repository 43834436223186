import {User} from './user';
import {Message} from './message';
import {Deserializable} from './deserializable.model';
import {DateTime} from 'luxon';

export class Chat implements Deserializable {

  id: string;
  unreadMessages: number;
  user: User;
  lastMessage: Message;
  messages: Message[];
  time: DateTime;
  selected = false;

  reachedEnd = false;

  constructor() {
    this.messages = [];
    this.unreadMessages = 0;
    this.lastMessage = new Message();
  }

  deserialize(input: any): this {
    if (!input) {
      return this;
    }
    Object.assign(this as any, input);
    this.time = DateTime.fromISO(input.time);
    if (input.lastMessage) {
      this.lastMessage = new Message().deserialize(input.lastMessage);
    }
    return this;
  }

  setLastMessage(message: Message) {
    this.lastMessage = message;
  }

  getLastMessage() {
    if (!this.lastMessage) {
      if (this.messages.length > 0) {
        this.lastMessage = this.messages[this.messages.length - 1];
      }
    }

    return this.lastMessage;
  }

  appendMessage(message: Message) {
    this.messages.push(message);
    this.lastMessage = message;
  }

  prependMessage(message: Message) {
    this.messages.unshift(message);
  }

  removeMessage(id: number) {
    // TODO:
    this.messages.pop();
  }

  setUser(user: User) {
    this.user = user;
  }

  getDate(): Date {
    if (!this.lastMessage) {
      return DateTime.local().toJSDate();
    }
    let time: DateTime;
    if (this.lastMessage) {
      time = this.lastMessage.time;
    } else {
      if (this.messages.length < 1) {
        time = this.time;
      } else {
        time = this.messages[this.messages.length - 1].time;
      }
    }

    return time.toJSDate();
  }

  formatDate(): string {
    const time: Date = this.getDate();
    if (!time) {
      return '';
    }
    const now = DateTime.local();
    const diff = this.getDiffDays(DateTime.fromJSDate(time), now);
    if (diff === 0) {
      return this.getChatTime(DateTime.fromJSDate(time));
    } else if (diff === 1) {
      return this.capitalizeFirstLetter(DateTime.fromJSDate(time).toRelativeCalendar().split(' ')[0]);
    } else if (diff > 1 && diff < 7) {
      return DateTime.fromJSDate(time).toFormat('dddd');
    } else {
      return DateTime.fromJSDate(time).toFormat('L');
    }
  }

  getChatTime(time: DateTime) {
    return time.toFormat('LL');
  }

  getDiffDays(start: DateTime, end: DateTime) {
    start = start.startOf('day');
    end = end.startOf('day');
    return Math.abs(DateTime.fromISO(end.toISO()).diff(DateTime.fromISO(start.toISO()), 'days').days);
  }

  capitalizeFirstLetter(s: string) {
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  setSelected(selected: boolean) {
    this.selected = selected;
  }

  clone() {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this as any);
  }

  compare(other: Chat) {
    const a = this.getDate();
    const b = other.getDate();
    if (!a && !b) {
      return 0;
    } else if (!a) {
      return -1;
    } else if (!b) {
      return 1;
    } else {
      const as = Number(a.getTime());
      const bs = Number(b.getTime());

      const e = as - bs;
      if (e < 0) {
        return 1;
      } else if (e > 0) {
        return -1;
      } else {
        return 0;
      }
    }
  }
}
