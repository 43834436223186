<section class="fullscreen img flex-center"
         [ngStyle]="{'background-image': 'url(' + images.background + ')'}">
  <div id="form-container" class="animated fadeInUp">
    <div class="logo-container grow" [routerLink]="['/']">
      <div class="logo" [ngStyle]="{'background-image': 'url(' + images.logo + ')'}"></div>
    </div>
    <div class="motifun-box section">
      <p class="section-headline">Passwort ändern</p>
      <form class="form" [formGroup]="resetPasswordFrom" (ngSubmit)="onSubmit()">
        <div class="flex-row">
          <mat-form-field class="input-field col-10">
            <fa-icon matPrefix [icon]="icons.password"></fa-icon>
            <input matInput
                   type="password"
                   name="password"
                   autocomplete="new-password"
                   placeholder="Neues Passwort"
                   formControlName="password">
            <mat-hint>Wähle ein möglichst langes Passwort mit verschiedenen Zeichen.</mat-hint>
            <mat-error *ngIf="showPasswordError()">
              Bitte gib ein gülties Passwort ein. Gültige Passwörter bestehen mindestens aus 8 <= x <= 32 Zeichen und
              beinhalten Buchstaben und Zahlen.
            </mat-error>
            <mat-error *ngIf="resetPasswordFrom.controls['password'].hasError('required')">
              Passwort wird <strong>benötigt</strong>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="flex-row">
          <mat-form-field class="input-field col-10">
            <fa-icon matPrefix [icon]="icons.password"></fa-icon>
            <input matInput
                   type="password"
                   name="passwordConfirm"
                   autocomplete="new-password-confirm"
                   placeholder="Neues Passwort bestätigen"
                   formControlName="passwordConfirm">
            <mat-error *ngIf="resetPasswordFrom.controls['passwordConfirm'].hasError('required')">
              Die Passwortbestätigung wird <strong>benötigt</strong>
            </mat-error>
            <mat-error *ngIf="showDifferentPasswordsError()">
              Die Passwörter stimmen nicht überein
            </mat-error>
            <mat-hint *ngIf="!showDifferentPasswordsError() && resetPasswordFrom.get('passwordConfirm').value.length > 0">
              Deine Passwörter stimmen überein
            </mat-hint>
          </mat-form-field>
        </div>
        <div class="flex-row">
          <div class="col-5 flex-center">
            <a class="center" [routerLink]="['/login']"><span>Ich kenne mein Passwort</span></a>
          </div>
          <div class="col-5 flex-center">
            <a class="center" [routerLink]="['/register']"><span>Ich habe noch keinen Account</span></a>
          </div>
        </div>
        <button mat-raised-button
                [disabled]="resetPasswordFrom.invalid"
                color="primary"
                class="flex-bottom"
                type="submit">
          Passwort ändern
        </button>
      </form>
    </div>
  </div>
</section>
