import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {User} from '../../../shared/models/user';

@Component({
  selector: 'app-user-image-preview-dialog',
  templateUrl: './user-image-preview-dialog.component.html',
  styleUrls: ['./user-image-preview-dialog.component.css']
})
export class UserImagePreviewDialogComponent {

  user: User;

  constructor(private dialogRef: MatDialogRef<UserImagePreviewDialogComponent>,
              @Inject(MAT_DIALOG_DATA) private passedData: any) {
    this.user = passedData;
  }

}
