import {AfterViewChecked, AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Notification} from '../notification';
import {User} from '../../../shared/models/user';
import {EventService} from '../../../core/services/event.service';
import {Event} from '../../../shared/models/event';

@Component({
  selector: 'app-notification-eventattender',
  templateUrl: './notification-event-attender.component.html',
  styleUrls: ['./notification-event-attender.component.css']
})
export class NotificationEventAttenderComponent implements OnInit, OnDestroy, AfterViewChecked {

  attendees: User[] = [];

  isSlickLoaded = false;

  @Input() notification: Notification;

  constructor(private eventService: EventService) {
  }

  ngOnInit() {
    this.eventService.getAttendants(this.notification.event)
      .subscribe((event: Event) => {
        this.attendees = event.attendees;
      });
  }

  ngAfterViewChecked() {

    if (this.attendees.length > 0 && document.querySelector('.attendees-list-slick') && !this.isSlickLoaded) {
      /*$('.attendees-list-slick').slick({
        dots: true,
        infinite: true,
        slidesToShow: 3,
        centerMode: true,
        centerPadding: '60px'
      });*/
      this.isSlickLoaded = true;
    }
  }

  ngOnDestroy() {
    /*$('.attendees-list').slick('unslick');*/

    this.isSlickLoaded = false;
  }
}
