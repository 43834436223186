import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ApiInterceptor} from './core/services/api.interceptor';
import {environment} from '../environments/environment';
import {AuthGuard} from './core/guards/auth.guard';
import {NonAuthGuard} from './core/guards/non-auth.guard';
import {AppRoutingModule} from './app-routing.module';
import {CoreModule} from './core/core.module';
import {ChatModule} from './modules/chat/chat.module';
import {EventsModule} from './modules/events/events.module';
import {NotificationsModule} from './modules/notifications/notifications.module';
import {UserModule} from './modules/users/user.module';
import {SettingsModule} from './modules/settings/settings.module';
import {SharedModule} from './shared/shared.module';
import {LoginModule} from './modules/login/login.module';
import {RegisterModule} from './modules/register/register.module';
import {ForgotPasswordModule} from './modules/forgot-password/forgot-password.module';
import {DeleteModule} from './modules/delete/delete.module';
import {ScrollToModule} from './modules/scroll-to/scroll-to.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {ServiceWorkerModule} from '@angular/service-worker';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    AppRoutingModule,
    ScrollToModule.forRoot(),
    CoreModule,
    ChatModule,
    EventsModule,
    NotificationsModule,
    UserModule,
    SettingsModule,
    LoginModule,
    RegisterModule,
    ForgotPasswordModule,
    DeleteModule,
    FontAwesomeModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    [{
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true
    }],
    AuthGuard,
    NonAuthGuard
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
}
