<div class="scrollable-content flex-col flex-center" *ngIf="user$ | async as u">
  <div class="flex-center flex-col box">
    <div class="img medium1 circle motifun-border link" [routerLink]="['/user']"
         [ngStyle]="{'background-image': 'url(' + u.getLowImgUrl() + ')'}"></div>
    <span>Willkommen zurück,</span>
    <span>{{u.firstName}}!</span>
    <br>
    <div id="shadow"></div>
  </div>
  <mat-tab-group mat-align-tabs="center">
    <mat-tab label="Suche Events">
      <ng-template matTabContent>
        <app-event-list [user]=u [eventType]="searched"></app-event-list>
      </ng-template>
    </mat-tab>
    <mat-tab label="Meine Events">
      <ng-template matTabContent>
        <app-event-list [user]=u [eventType]="created"></app-event-list>
      </ng-template>
    </mat-tab>
    <mat-tab label="Teilgenommene Events">
      <ng-template matTabContent>
        <app-event-list [user]=u [eventType]="joined"></app-event-list>
      </ng-template>
    </mat-tab>
    <mat-tab label="Vorgemerkte Events">
      <ng-template matTabContent>
        <app-event-list [user]=u [eventType]="observed"></app-event-list>
      </ng-template>
    </mat-tab>
    <mat-tab label="Vergangene Events">
      <ng-template matTabContent>
        <app-event-list [user]=u [eventType]="passed"></app-event-list>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
