import {AfterViewChecked, AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ApiService} from '../../core/services/api.service';
import {User} from '../../shared/models/user';
import {Chat} from '../../shared/models/chat';
import {Message} from '../../shared/models/message';
import {SocketIOService} from '../../core/services/socketio.service';
import {AuthService} from '../../core/services/auth.service';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {BehaviorSubject, fromEvent, merge, Observable, of, Subject, Subscription, throwError} from 'rxjs';
import {catchError, debounceTime, distinct, distinctUntilChanged, filter, map, share, shareReplay, switchMap, tap} from 'rxjs/operators';
import {ResizeService} from '../../core/services/resize.service';
import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {UserService} from '../../core/services/user.service';
import {ChatService} from '../../core/services/chat.service';
import {Location} from '@angular/common';
import {MessageType} from '../../shared/enums/message-type';
import {Alert} from '../../shared/models/alert';
import {AlertType} from '../../shared/enums/alert-type';
import {AlertService} from '../../core/services/alert.service';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {faChevronLeft, faComment, faLink, faPaperPlane, faSearch, faTimes} from '@fortawesome/free-solid-svg-icons';
import {SocketIOEvent} from '../../shared/enums/socket-io-event';
import {DateTime} from 'luxon';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {

  icons = {
    search: faSearch,
    message: faComment,
    back: faChevronLeft,
    send: faPaperPlane,
    link: faLink,
    close: faTimes
  };

  loading = {
    search: false,
    chats: false,
    messages: false
  };

  end = {
    chats: false,
    messages: false,
    search: false
  };

  empty = {
    chats: false,
    message: false,
    search: false
  };

  private mobile: { [key: string]: Element } = {
    root: document.getElementsByTagName('html')[0],
    body: document.body,
    sideNavContainer: document.getElementsByTagName('mat-sidenav-container')[0],
    sideNavContent: document.getElementsByTagName('mat-sidenav-content')[0],
    main: document.getElementsByTagName('main')[0]
  };

  searching = false;
  searchQuery = '';
  searchQueryLast: string;

  messageForm: UntypedFormGroup;

  currentChat: Chat;
  private disableScrollDown = false;

  screenWidth: number;
  screenHeight: number;

  private chats: Chat[] = [];
  private user: User;

  user$: Observable<User>;
  private chatDelete$: Subject<string> = new Subject(); // should return chatsArray
  private chatsInitial$: Subject<number> = new BehaviorSubject(-1); // try to show chats from cache otherwise init loading
  private chatsCache$: Observable<Chat[]>; // returns cached chats
  private chatsRefreshManually$: Subject<Chat> = new Subject(); // should return chatsArray
  private chatsUpdate$: Observable<Chat[]>; // parent stream of chat updating
  private chatsScrolling$: Subject<number> = new Subject(); // need to load more chats; reached end of list
  private chatsResizing$: Observable<number>; // need to load more chats; reached end of list

  private chatsLoading$: Observable<Chat[]>; // should return loaded chats

  chats$: Observable<Chat[]>; // should return chatsArray, last stream

  private urlRequestedChat$: Observable<string>;
  private selectChat$: Subject<string> = new Subject<string>(); // manually select chat

  currentChat$: Observable<Chat>; // should return current chat
  currentChatSubscription: Subscription;

  chatsSearching$: Observable<Chat[]>;
  private chatsSearchingInitial$: Subject<string> = new Subject();
  private chatsSearchingScrolling$: Subject<string> = new Subject();
  private chatsSearchingResize$: Observable<string>;

  private messagesInitial$: Subject<number> = new Subject();
  private messagesScrolling$: Observable<number>;
  private messagesResizing$: Observable<number>;
  private messagesLoading$: Observable<Message[]>;

  private messageReceiving$: Observable<Message[]>; // should return chat with new message

  private messagesRefreshing$: Subject<Message[]> = new Subject();

  messages$: Observable<Message[]>;

  private globalUserId: string;

  chatItemHeight = 100;
  private chatListHeight = window.innerHeight;

  private maxMobilePageSize = 900;

  @ViewChild('messageList', {static: true}) private messageList?: ElementRef<HTMLElement>;
  @ViewChild('chatList') chatList?: CdkVirtualScrollViewport;
  @ViewChild('search', {static: true}) private search?: ElementRef<HTMLElement>;
  @ViewChild('chatSearchList') chatSearchList?: CdkVirtualScrollViewport;

  constructor(private api: ApiService,
              private auth: AuthService,
              private alertService: AlertService,
              private userService: UserService,
              private chatService: ChatService,
              private location: Location, // url location
              private io: SocketIOService,
              private resize: ResizeService,
              private route: ActivatedRoute,
              private router: Router) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    this.initMobileElements();
    if (this.screenWidth < this.maxMobilePageSize) {
      this.setMobileStyles();
    }

    this.messageForm = new UntypedFormGroup({
      message: new UntypedFormControl(
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(255)
        ]
      )
    });
  }

  ngOnInit() {
    this.user$ = this.userService.getUser()
      .pipe(
        map((user: User) => {
          this.user = user;

          return user;
        }),
        share()
      );

    this.initStreams();
  }

  ngAfterViewInit(): void {
    this.chatListHeight = this.chatList.elementRef.nativeElement.scrollHeight;
  }

  ngAfterViewChecked() {
    if (this.disableScrollDown) {
      return;
    }

    this.scrollToBottom();
  }

  ngOnDestroy() {
    if (this.currentChat) {
      this.currentChat.setSelected(false);
    }
    this.globalUserId = this.currentChat = undefined;

    if (this.currentChatSubscription) {
      this.currentChatSubscription.unsubscribe();
    }

    this.removeMobileStyles();

    console.log('destroy');
  }

  private initStreams() {
    this.chatsResizing$ = this.resize.onScreenResizeY(document.body, 100, 10)
      .pipe(
        map((data) => {
          return this.chatService.getCachedChats().length;
        }),
        tap(() => {
          this.chatList.checkViewportSize();
        })
      );
    this.chatsLoading$ = merge(this.chatsInitial$, this.chatsScrolling$, this.chatsResizing$)
      .pipe(
        distinct(),
        tap(() => {
          this.loading.chats = true;
        }),
        switchMap((c: number) => {
          return this.chatService.getChats(c === -1);
        }),
        catchError((error) => {
          console.log(error);
          // TODO: handle in http interceptor
          if (error.error.status === 403) {
            this.unselectChat();
            // TODO: alert service!
          }
          return throwError(error);
        })
      );


    // TODO: + handle new chats
    this.chatsUpdate$ = merge(this.chatsRefreshManually$, this.chatDelete$)
      .pipe(
        map((data) => {
          return this.chatService.getCachedChats(true);
        })
      );

    this.chatsCache$ = of(this.chatService.getCachedChats());

    this.chats$ = this.user$
      .pipe(
        switchMap(() => merge(this.chatsLoading$, this.chatsUpdate$)),
        map((chats: Chat[]) => {
          if (!chats || chats.length === 0) {
            this.empty.chats = true;
            this.empty.message = true;
          }

          if (chats.length * this.chatItemHeight <= (this.chatListHeight + (2 * this.chatItemHeight)) && !this.end.chats) {
            this.chatsInitial$.next(chats.length);
          }

          this.chats = chats;

          return chats;
        }),
        tap(() => {
          this.end.chats = this.chatService.isChatEnd();
          this.loading.chats = false;
        }),
        shareReplay(1)
      );

    this.urlRequestedChat$ = this.route.paramMap
      .pipe(
        map((params: ParamMap) => {
          const urlElements = this.location.path().split('/');
          let id: string = urlElements[urlElements.length - 1];

          return id;
        })
      );

    this.chatsSearchingResize$ = this.resize.onScreenResizeY(document.body, 100, 10).pipe(map(() => this.searchQuery));
    this.chatsSearching$ = merge(this.chatsSearchingInitial$, this.chatsSearchingScrolling$, this.chatsSearchingResize$)
      .pipe(
        switchMap((query: string) => {
          return this.chatService.getSearchedChats(this.searchQuery);
        }),
        share()
      );

    // returns the url param requested chat or null if it's not valid
    this.currentChat$ = this.chats$
      .pipe(
        tap(() => console.log('current chat: chats update')),
        switchMap(() => {
          return merge(this.urlRequestedChat$, this.selectChat$);
        }),
        switchMap((userId: string) => {
          this.globalUserId = userId;

          if (this.screenWidth < this.maxMobilePageSize && (!this.globalUserId || this.globalUserId.length <= 0)) {
            this.currentChat = null;
            return of(null);
          } else if (this.globalUserId && this.globalUserId.length > 0) {
            this.location.go('messages/' + this.chats[0].user.id);
            return of(this.chats[0]);
          }

          const found = this.containsChat(this.chats, this.globalUserId);

          return found ? of(found) : this.chatService.getChat(this.globalUserId)
            .pipe(tap((chat: Chat) => this.chatsRefreshManually$.next(chat)));
        }),
        map((chat: Chat) => {
          if (!chat) {
            return null;
          }
          this.selectChat(chat);

          return chat;
        }),
        share()
      );

    this.currentChatSubscription = this.currentChat$
      .subscribe((chat: Chat) => {
        if (!chat) {
          return;
        }
        this.location.go('/messages/' + this.currentChat.user.id);
        if (chat.messages.length > 0 || this.loading.messages) {
          console.log('refresh messages');
          this.messagesRefreshing$.next(this.currentChat.messages);
          return;
        }
        this.messagesRefreshing$.next([]);
        this.messagesInitial$.next(chat.messages.length);
      });

    this.messagesResizing$ = this.resize.onScreenResizeY(document.body, 100, 10);
    this.messagesScrolling$ = fromEvent(this.messageList.nativeElement, 'scroll')
      .pipe(
        debounceTime(250),
        map(() => {
          const element = this.messageList.nativeElement;
          const isAtBottom = element.scrollHeight - element.scrollTop === element.clientHeight;
          this.disableScrollDown = !this.disableScrollDown || !isAtBottom;
          if (isAtBottom) {
            element.scrollTop--;
          }
          return element.scrollTop;
        }),
        filter((current: number) => {
          const b = current <= 50;
          return b && !this.loading.messages;
        }),
        map(() => {
          if (this.currentChat && this.currentChat.messages.length > 0) {
            return this.currentChat.messages.length;
          } else {
            return 0;
          }
        })
      );

    this.messagesLoading$ = merge(
      this.messagesInitial$.pipe(tap(() => this.disableScrollDown = false)),
      this.messagesScrolling$,
      this.messagesResizing$
    )
      .pipe(
        distinctUntilChanged(),
        tap((n: number) => {
          console.log('start loading messages');
          this.loading.messages = true;
          this.messageList.nativeElement.scrollTop = 1;
        }),
        switchMap((id: number) => {
          if ((id === -1 && this.currentChat.messages.length > 0) || this.end.messages) {
            return of(this.currentChat.messages);
          }

          return this.chatService.getMessages(this.currentChat, this.user);
        }),
        tap((messages: Message[]) => {
          this.end.messages = this.currentChat ? this.chatService.isMessageEnd(this.currentChat.id) : false;
        })
      );

    this.messageReceiving$ = this.io.onMessage()
      .pipe(
        switchMap((msg: any) => {
          console.log('message subscribe event');
          if (!msg.type) {
            return of(null);
          }
          const message = new Message().deserialize(msg.message);
          const chatId = msg.message.chat;
          const receiverId = msg.receiver;
          message.type = msg.type;

          // if no chat selected and on desktop version set currentChat to chat of receiving message
          if ((!this.currentChat || !this.currentChat.id) && this.maxMobilePageSize < this.screenWidth) {
            console.log('no current chat and desktop');
            if (!this.currentChat) {
              this.currentChat = new Chat();
            }
            this.currentChat.id = chatId;
          }

          // if current chat is received chat return current
          if (this.currentChat && this.currentChat.id === chatId) {
            console.log('current chat received new message');
            this.currentChat.appendMessage(message);
            const element = this.messageList.nativeElement;
            if (message.type === MessageType.SENT || element.scrollHeight - element.scrollTop <= element.clientHeight + 50) {
              this.disableScrollDown = false;
            }

            return of(this.currentChat);
          }

          // look for received chat in chats but return current because the received messages are not for current chat
          for (const chat of this.chats) {
            if (chat.id === chatId) {
              chat.appendMessage(message);
              if (message.type === MessageType.RECEIVED) {
                chat.unreadMessages++;
              }
              return of(this.currentChat);
            }
          }

          // did not find received chat
          console.log('need to request chat');
          return this.chatService.getChat(message.type === MessageType.RECEIVED ? message.sender : msg.receiver)
            .pipe(
              map((chat: Chat) => {
                if (message.type === MessageType.RECEIVED) {
                  chat.unreadMessages++;
                }
                chat.appendMessage(message);

                return this.currentChat;
              })
            );
        }),
        map((chat: Chat) => {
          this.chatsRefreshManually$.next(chat);

          return chat.messages;
        }),
        catchError((error) => {
          // TODO: alert service
          this.unselectChat();
          console.log(error);
          console.log(this.currentChat);
          return of(null);
        }),
      );

    this.messages$ = merge(this.messageReceiving$, this.messagesLoading$, this.messagesRefreshing$)
      .pipe(
        tap((messages: Message[]) => {
          this.loading.messages = false;
          if (this.messageList.nativeElement.scrollHeight === 0) {
            return;
          }
          if (this.messageList.nativeElement.scrollHeight <= this.messageList.nativeElement.clientHeight && this.currentChat) {
            this.messagesInitial$.next(messages.length);
          }
        }),
        share()
      );
  }

  selectFirstChat() {
    if (this.chats.length > 0) {
      this.selectChat(this.chats[0]);
    }
  }

  handleChatClick(chat: Chat) {
    this.location.go('/messages/' + chat.user.id);
    this.selectChat$.next(chat.user.id);
  }

  selectChat(chat: Chat) {
    if (!chat) {
      return;
    }
    if (!chat.user) {
      return;
    }
    if (this.currentChat && chat.id === this.currentChat.id) {
      return;
    }

    this.unselectChat();

    this.currentChat = chat;
    this.currentChat.unreadMessages = 0;
    this.currentChat.setSelected(true);

    this.end.messages = false;
  }

  unselectChat(removeParam: boolean = false) {
    if (this.currentChat) {
      this.currentChat.setSelected(false);
    }

    if (removeParam) {
      this.router.navigate([], {skipLocationChange: true});
      this.location.go('/messages');
    }

    this.currentChat = null;
  }

  onSearch(event: KeyboardEvent) {
    if (event.key === 'Escape' || (event.key === 'Backspace' && this.searchQueryLast.length === 0)) {
      this.searchQuery = this.searchQueryLast = '';
      this.searching = false;
      return;
    }

    if (this.searchQueryLast === this.searchQuery) {
      return;
    }

    this.searchQueryLast = this.searchQuery;
    if (this.searchQuery.length > 0) {
      this.searching = true;
      this.chatsSearchingInitial$.next(this.searchQuery);
    }
  }

  onEnterSend(event: any): boolean {
    // TODO: support for older browsers
    if (event.key === 'Enter' && !event.shiftKey) {
      event.stopPropagation();
      event.preventDefault();
      this.sendMessage();
      return false;
    }
    return true;
  }

  sendMessage() {
    if (this.messageForm.invalid) {
      return this.alertService.appendAlert(new Alert(
        'Ungültige Nachricht',
        'Die eingegebene Nachricht ist ungültig.',
        AlertType.ERROR
      ));
    }

    const text = this.messageForm.getRawValue().message.trim();
    if (text.length <= 0) {
      return this.alertService.appendAlert(new Alert(
        'Kein Text',
        'Die eingegebene Nachricht besitzt keinen Text.',
        AlertType.ERROR
      ));
    }

    if (!this.currentChat) {
      return this.alertService.appendAlert(new Alert(
        'Wähle einen Chat',
        'Du musst einen Chat auswählen, bevor du eine Nachricht senden kannst.',
        AlertType.ERROR
      ));
    }

    const message = {
      text,
      userId: this.currentChat.user.id
    };

    this.scrollToBottom();
    this.io.send(SocketIOEvent.MESSAGE, message);

    this.messageForm.patchValue({message: ''});
  }

  scrollToBottom(): void {
    try {
      this.messageList.nativeElement.scrollTop = this.messageList.nativeElement.scrollHeight;
    } catch (err) {
    }
  }

  isNewDay(time1: DateTime, time2: DateTime): boolean {
    const isCurrentDate = time1.diff(time2, 'day').days === 0;
    return !isCurrentDate;
  }

  deleteChat(chat: Chat) {
    this.chatService.deleteChat(chat);
    if (this.currentChat.id === chat.id) {
      this.currentChat = undefined;
      this.location.go('/messages/');
      if (this.chats.length > 0) {
        this.selectFirstChat();
      }
    }
    this.chatDelete$.next(chat.id);
  }

  reportChat(chat: Chat) {
    console.log('should report chat - but is not implemented', chat);
  }

  handleGetNextChats($event: number) {
    const total = this.chatList.getDataLength();
    if (this.end.chats || this.loading.chats || total === 0) {
      this.chatsInitial$.next(-1);
      return;
    }

    const end = this.chatList.getRenderedRange().end;
    const length = this.chatService.getCachedChats().length;
    if (end === total) {
      this.chatsScrolling$.next(length > 0 ? length : -1);
    }
  }

  handleGetNextSearchedChats($event: number) {
    if (this.end.search || this.loading.search) {
      return;
    }

    const end = this.chatSearchList.getRenderedRange().end;
    const total = this.chatSearchList.getDataLength();
    console.log(`${end}, '>=', ${total}`);
    if (end === total) {
      this.chatsSearchingScrolling$.next(this.searchQuery);
    }
  }

  private containsChat(chats: Chat[], userId: string): Chat {
    return chats.find((chat) => chat.user.id === userId);
  }

  onSearchClick() {
    this.searching = !this.searching;
    // TODO: focus does not work.. find out why.
    setTimeout(() => this.search.nativeElement.focus(), 100);
  }

  private setMobileStyles() {
    const className = 'mobile';
    for (const key in this.mobile) {
      if (this.mobile.hasOwnProperty(key)) {
        this.mobile[key].classList.add(className);
      }
    }
  }

  private removeMobileStyles() {
    const className = 'mobile';
    for (const key in this.mobile) {
      if (this.mobile.hasOwnProperty(key)) {
        this.mobile[key].classList.remove(className);
      }
    }
  }

  private initMobileElements() {
    this.mobile.root = document.getElementsByTagName('html')[0];
    this.mobile.body = document.body;
    this.mobile.sideNavContainer = document.getElementsByTagName('mat-sidenav-container')[0];
    this.mobile.sideNavContent = document.getElementsByTagName('mat-sidenav-content')[0];
    this.mobile.main = document.getElementsByTagName('main')[0];
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    if (window.innerWidth < this.maxMobilePageSize) {
      this.setMobileStyles();
    } else {
      this.removeMobileStyles();
    }
  }
}
