<div id="main-row" class="row flex-row m-flex-col s-flex-col main-fixed main-gap" *ngIf="(event$ | async) as e">
  <div class="col-7 l-margin-h m-margin-v s-margin-v flex-col m-full s-full s-row">
    <div id="row-info" class="row flex-row s-flex-col">
      <div class="col-55 l-margin-h m-margin-h s-margin-v flex-col s-full motifun-box editable">
        <div class="edit-icon abs" *ngIf="e.isCreator" (click)="onEditInfo()">
          <fa-icon [icon]="icons.edit"></fa-icon>
        </div>

        <div class="inner-row flex-col" style="height: 320px;">
          <div class="col-10 fill-remaining-space flex-col"
               [ngStyle]="{'background-image': 'url(' + e.getImgUrl() + '), url('+ e.getFallbackUrl() +')'}"
               style="background-size: cover;">
            <div class="inner-row flex-row" style="margin-top: 15px;">
              <div class="col-25" style="display: flex; justify-content: center;">
                <div [ngStyle]="{'background-image': 'url(' + e.creator.getLowImgUrl() + ')'}"
                     style="height: 60px; width: 60px; border: 1px solid #FFFFFF; border-radius: 38px; background-size: cover;">
                  <a [routerLink]="['/user', e.creator.id]"></a>
                </div>
              </div>
              <div class="col-flex">
              </div>
              <div class="col-45" style="display: flex; justify-content: flex-end;">
                <div style="align-self: baseline; background: rgba(0, 0, 0, 0.8); color: #FFFFFF; padding: 5px 15px;">
                  {{e.sport.name}}
                </div>
              </div>
            </div>
            <div class="inner-row fill-remaining-space">

            </div>
            <div class="inner-row flex-row"
                 style="height: 70px; padding: 10px 0; display: flex; justify-content: center; text-align: center; background: rgba(0, 0, 0, 0.8); color: #FFFFFF;">
              <div class="col-2">
                <fa-icon [icon]="icons.calendar"></fa-icon>
                <br/>{{e.startDate.toJSDate() | date : 'shortDate'}}
              </div>
              <div class="col-2">
                <fa-icon [icon]="icons.clock"></fa-icon>
                <br/>{{e.startTime.toJSDate() | date : 'shortTime'}}
              </div>
              <div class="col-2">
                <fa-icon [icon]="icons.users"></fa-icon>
                <br/>{{e.attendeesCount}}{{e.maxAttendees ? '/' + e.maxAttendees : ''}}
              </div>
              <div class="col-2">
                <fa-icon [icon]="icons.comments"></fa-icon>
                <br/>{{e.commentsCount}}
              </div>
              <div class="col-2">
                <fa-icon [icon]="icons.skill"></fa-icon>
                <br/>{{e.skill}}/5
              </div>
            </div>
          </div>
        </div>
        <div class="inner-row overflow-y editable" style="padding: 15px 0.8rem;">
          <div class="edit-icon abs" *ngIf="e.isCreator" (click)="edit.about = !edit.about">
            <fa-icon [icon]="icons.edit"></fa-icon>
          </div>
          <div class="col-10" *ngIf="!edit.about">
            <span style="font-size: 1.25em; line-height: 0.75em; display: block;">{{e.title}}</span>
            <span style="font-size: 0.75em;"><fa-icon [icon]="icons.location"></fa-icon>
              {{e.getDistanceString()}}{{e.city}}</span>
            <p>{{e.description}}</p>
          </div>
          <form class="col-10" *ngIf="edit.about" [formGroup]="editAbout">
            <div class="row">
              <mat-form-field class="col s10">
                <input matInput
                       type="text"
                       placeholder="Titel"
                       name="title"
                       maxlength="50"
                       [formControlName]="'title'">
                <mat-hint align="end">{{editAbout.get('title').value.length || 0}}/50
                </mat-hint>
              </mat-form-field>
            </div>
            <div class="row">
              <mat-form-field class="col s10">
                <textarea matInput
                          matAutosize
                          cdkAutosizeMinRows="1"
                          cdkAutosizeMaxRows="2"
                          maxlength="400"
                          placeholder="Beschreibung"
                          name="description"
                          [formControlName]="'description'"></textarea>
                <mat-hint align="end">{{editAbout.get('description').value.length || 0}}/400
                </mat-hint>
              </mat-form-field>
            </div>
            <button (click)="onEditAbout()" class="col s10" color="primary" mat-raised-button>Event ändern</button>
          </form>
        </div>
      </div>
      <div class="col-45 l-margin-h m-margin-h s-margin-v flex-col s-full motifun-box">
        <div class="inner-row" style="height: 320px;">
          <div class="col-10">
            <div [ngStyle]="{'background-image': 'url(' + e.getMapUrl(430, 320) + ')'}"
                 class="img"
                 style="width: 100%; height: 320px;"></div>
          </div>
        </div>
        <div class="inner-row editable" style="margin-top: 0.8rem;">
          <div class="edit-icon abs" *ngIf="e.isCreator" (click)="onEditLocation()">
            <fa-icon [icon]="icons.edit"></fa-icon>
          </div>
          <div class="col-10 flex-row" style="align-items: center; justify-content: center;">
            <div style="font-size: 2em; padding: 0 10px 0 0;">
              <fa-icon [icon]="icons.location"></fa-icon>
            </div>
            <div>
              <span style="display: block;">
                {{e.location.postalCode}} {{e.location.city}}
              </span>
              <span style="display: block;">
                {{e.location.street}} {{e.location.houseNumber}}
              </span>
            </div>
          </div>
        </div>
        <div class="inner-row fill-remaining-space flex-row"
             style="align-items: center; justify-content: center; text-align: center;"
             *ngIf="!e.isCreator">
          <div class="col-5 margin grow" (click)="joinEvent()" [ngClass]="e.isAttender ? 'motifun text' : ''">
            <span style="display: block; padding: 0 0 10px 0; font-size: 2em;">
              <fa-icon [icon]="icons.join"></fa-icon>
            </span>
            <span style="display: block; font-size: 1.25em;">
              Teilnehmen
            </span>
          </div>
          <div class="col-5 margin grow" (click)="observeEvent()" [ngClass]="e.isObserver ? 'motifun text' : ''">
            <span style="display: block; padding: 0 0 10px 0; font-size: 2em;">
              <fa-icon [icon]="icons.observe"></fa-icon>
            </span>
            <span style="display: block; font-size: 1.25em;">
              Interessiert
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row flex-row fill-remaining-space motifun-box">
      <div class="col-10">
        <div class="inner-row" style="margin: 10px 0 0;">
          <span style="display: block; font-size: 1.25em; text-align: center;">Teilnehmer</span>
        </div>
        <div class="inner-row flex-row">
          <div class="col-05 nav-arrows" (click)="onAttendantsScroll('left')">
            <fa-icon [icon]="icons.arrowLeft"></fa-icon>
          </div>
          <div class="col-9">
            <ul>
              <cdk-virtual-scroll-viewport
                #attendantsList
                itemSize="250"
                orientation="horizontal"
                (scrolledIndexChange)="handleGetNextAttendants($event, e.attendees.length)"
                class="attendantsList horizontal"
                style="height: 298px;">
                <li *cdkVirtualFor="let attender of attendants$ | async"
                    style="float: left;">
                  <app-event-attendees [attender]="attender" [eventCreatorId]="e.creator.id"
                                       [ownUserId]="user.id"></app-event-attendees>
                </li>
                <li *ngIf="loading.attendants">
                  <mat-progress-spinner
                    class="example-margin"
                    color="primary"
                    mode="indeterminate">
                  </mat-progress-spinner>
                </li>
              </cdk-virtual-scroll-viewport>
            </ul>
          </div>
          <div class="col-05 nav-arrows" (click)="onAttendantsScroll('right')">
            <fa-icon [icon]="icons.arrowRight"></fa-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-3 l-margin-h m-margin-v s-margin-v s-row m-full s-full flex-col motifun-box">
    <span style="display: block; font-size: 1.25em; text-align: center;">Kommentare</span>
    <mat-form-field style="width: 100%;">
      <textarea matInput
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="5"
                type="text"
                placeholder="Dein Kommentar"
                [(ngModel)]="comment"></textarea>
      <button mat-button
              matSuffix
              mat-icon-button
              style="font-size: 1.5em"
              (click)="createComment()">
        <mat-icon>send</mat-icon>
      </button>
      <mat-hint align="end">{{comment.length}} / 256</mat-hint>
    </mat-form-field>
    <ng-container *ngIf="comments$ | async as comments">
      <cdk-virtual-scroll-viewport
        *ngIf="desktop; else mobileList"
        #commentsList
        autosize
        [itemSize]="comments.length"
        (scroll)="handleGetNextComments($event, e.comments[e.comments.length - 1])"
        style="flex-grow: 1">
        <ul>
          <li *cdkVirtualFor="let comment of comments">
            <app-event-comment [comment]="comment" [eventCreatorId]="e.creator.id"></app-event-comment>
          </li>
          <li *ngIf="loading.comments">
            <mat-progress-spinner
              class="example-margin"
              color="primary"
              mode="indeterminate">
            </mat-progress-spinner>
          </li>
        </ul>
      </cdk-virtual-scroll-viewport>
      <ng-template #mobileList>
        <ul style="flex: 1; width: 100%">
          <li *ngFor="let comment of comments">
            <app-event-comment [comment]="comment" [eventCreatorId]="e.creator.id"></app-event-comment>
          </li>
          <li *ngIf="loading.comments">
            <mat-progress-spinner
              class="example-margin"
              color="primary"
              mode="indeterminate">
            </mat-progress-spinner>
          </li>
        </ul>
      </ng-template>
    </ng-container>
  </div>
</div>
