import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild} from '@angular/core';
import {Location} from '../../../shared/models/location';
import {UntypedFormGroup} from '@angular/forms';
import {GeoService} from '../../../core/services/geo.service';
import {User} from '../../../shared/models/user';
import {GoogleMapsService} from '../services/google-maps.service';
import {GoogleMaps} from '../interfaces/google-maps';

declare const google: any;

@Component({
  selector: 'app-google-maps-autocomplete',
  templateUrl: './google-maps-autocomplete.component.html',
  styleUrls: ['./google-maps-autocomplete.component.scss']
})
export class GoogleMapsAutocompleteComponent implements AfterViewInit, OnDestroy {

  private autocomplete: any;
  private places: any;

  @Input() marker: any;
  @Input() formGroup: UntypedFormGroup;
  @Input() inputError: string;
  @Input() user: User;

  @Output() location: EventEmitter<Location> = new EventEmitter<Location>();
  @ViewChild('input', {static: true}) input: ElementRef<HTMLInputElement>;

  constructor(private geo: GeoService,
              private googleMapsService: GoogleMapsService) {
  }

  ngAfterViewInit(): void {
    this.googleMapsService.getMap()
      .subscribe((data: GoogleMaps) => {
        this.init(data.map, data.marker);
      });
  }

  ngOnDestroy(): void {
    this.autocomplete.unbindAll();
  }

  setCurrentLocation() {
    this.geo.locateUser(this.user.settings.language)
      .subscribe((location: Location) => {
        this.formGroup.get('address').patchValue(location.getAddressString());
        this.location.emit(location);
      });
  }

  private init(map: any, marker: any, options?: any) {
    if (!options) {
      options = {
        types: [],
        // componentRestrictions: countryRestrict
      };
    }
    this.autocomplete = new google.maps.places.Autocomplete(this.input.nativeElement, options);

    this.autocomplete.addListener('place_changed', (event: any) => { // TODO: find types!
      const place: any = this.autocomplete.getPlace();

      const location: Location = new Location().parseGoogle(place);

      if (map) {
        if (place.geometry.viewport) {
          map.fitBounds(place.geometry.viewport);
        } else {
          map.setCenter(place.geometry.location);
          map.setZoom(13);
        }
      }

      if (marker) {
        marker.setVisible(false);
        marker.setPosition(place.geometry.location);
        marker.setVisible(true);
      }

      this.location.emit(location);
    });
  }

  private _placeChanged(place: any) { //TODO: find type
    // infowindow.close();
    // marker.setVisible(false);
    if (!place.geometry) {
      // User entered the name of a Place that was not suggested and
      // pressed the Enter key, or the Place Details request failed.

      // TODO: schöneres popup und mehrsprachiger text
      window.alert('No details available for input: \'' + place.name + '\'');
      return;
    }

    // If the place has a geometry, then present it on a map.
    if (place.geometry.viewport) {
      // map.fitBounds(place.geometry.viewport);
    } else {
      // map.setCenter(place.geometry.tourLocation);
      // map.setZoom(17);  // Why 17? Because it looks good.
    }

    if (place.address_components) {
      const location = new Location().parseGoogle(place);
      console.log(location);
    }
  }
}
