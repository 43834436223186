<h3>Sportarten</h3>
<mat-dialog-content class="mat-typography">
  <mat-selection-list #sportList>
    <mat-list-option *ngFor="let sport of sports">
      {{sport.name}}
    </mat-list-option>
  </mat-selection-list>
  <p>
    Options selected: {{sportList.selectedOptions.selected.length}}
  </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button color="primary" (click)="save()">Speichern</button>
  <button mat-button mat-dialog-close>Abbrechen</button>
</mat-dialog-actions>
