import {Component, Input} from '@angular/core';
import {Event} from '../../../shared/models/event';
import {
  faBookmark,
  faCalendar,
  faCheck,
  faChevronLeft,
  faChevronRight,
  faClock,
  faCommentAlt,
  faDumbbell,
  faEdit,
  faExternalLinkAlt,
  faHeartbeat,
  faLocationArrow,
  faMapMarkerAlt,
  faSun,
  faUsers
} from '@fortawesome/free-solid-svg-icons';
import {EventService} from '../../../core/services/event.service';

@Component({
  selector: 'app-notification-event-info',
  templateUrl: './notification-event-info.component.html',
  styleUrls: ['./notification-event-info.component.css']
})
export class NotificationEventInfoComponent {

  icons = {
    sports: faBookmark,
    location: faMapMarkerAlt,
    distance: faLocationArrow,
    attendees: faUsers,
    edit: faEdit,
    weather: faSun,
    calendar: faCalendar,
    clock: faClock,
    users: faUsers,
    comments: faCommentAlt,
    skill: faDumbbell,
    join: faCheck,
    observe: faHeartbeat,
    arrowLeft: faChevronLeft,
    arrowRight: faChevronRight,
    link: faExternalLinkAlt
  };

  @Input() event: Event;

  constructor(private eventService: EventService) {
  }

  joinEvent() {
    this.eventService.joinEvent(this.event)
      .subscribe((event: Event) => {
      });
  }

  observeEvent() {
    this.eventService.observeEvent(this.event)
      .subscribe((event: Event) => {
      });
  }
}
