import {Component, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {BehaviorSubject, merge, Observable} from 'rxjs';
import {Activity} from '../../../shared/models/activity';
import {map, switchMap, tap} from 'rxjs/operators';
import {User} from '../../../shared/models/user';
import {UserService} from '../../../core/services/user.service';
import {ResizeService} from '../../../core/services/resize.service';
import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {ScrollService} from '../../../core/services/scroll.service';

@Component({
  selector: 'app-user-activities',
  templateUrl: './user-activities.component.html',
  styleUrls: ['./user-activities.component.css']
})
export class UserActivitiesComponent {

  loading = false;

  isMobile: boolean = false;

  activitiesScroll$: BehaviorSubject<number> = new BehaviorSubject(0);
  activitiesResize$: Observable<number>;
  activitiesScroll2$: Observable<number>;

  activitiesLoad$: Observable<Activity[]>;

  activities$: Observable<Activity[]>;

  @Input() user: User;
  @ViewChild(CdkVirtualScrollViewport) viewport: CdkVirtualScrollViewport;

  constructor(private userService: UserService,
              private resize: ResizeService,
              private scroll: ScrollService) {
    this.activitiesResize$ = this.resize.onScreenResizeX(document.body, 200, 12);
    this.activitiesScroll2$ = this.scroll.onWindowScrollY(document.body, 30, 250, 200, 12);

    this.activitiesLoad$ = merge(this.activitiesScroll$, this.activitiesResize$, this.activitiesScroll2$)
      .pipe(
        tap(() => this.loading = true),
        switchMap((page: number) => {
          return this.userService.getActivities(this.user);
        })
      );

    this.activities$ = this.activitiesLoad$
      .pipe(
        map((activities: Activity[] | any) => {
          return activities.value ? activities.value : activities;
        }),
        tap(() => this.loading = false)
      );

    this.isMobile = this.calcIsMobile();
  }

  handleGetNextActivities(e: number) {
    if (this.loading) {
      return;
    }
    const end = this.viewport.getRenderedRange().end;
    const total = this.viewport.getDataLength();
    if (end === total) {
      this.activitiesScroll$.next(total / 8);
    }
  }

  @HostListener('window:resize')
  onResize() {
    this.isMobile = this.calcIsMobile();
  }

  private calcIsMobile() {
    return window.innerWidth < 1096;
  }
}
