import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ApiService} from '../../../core/services/api.service';
import {Notification} from '../notification';
import {UserService} from '../../../core/services/user.service';
import {User} from '../../../shared/models/user';
import {faUserCheck, faUserTimes} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-notification-friendrequest',
  templateUrl: './notification-friend-request.component.html',
  styleUrls: ['./notification-friend-request.component.css']
})
export class NotificationFriendRequestComponent {

  icons = {
    add: faUserCheck,
    deny: faUserTimes
  };

  @Input() notification: Notification;
  @Output() remove: EventEmitter<Notification> = new EventEmitter<Notification>();

  constructor(private api: ApiService,
              private userService: UserService) {
  }

  handleAddFriend() {
    this.userService.addUser(this.notification.sender)
      .subscribe((user: User) => {
        console.log(user);
        this.remove.emit(this.notification);
      });
  }

  handleDenyFriend() {
    this.userService.denyUser(this.notification.sender)
      .subscribe((user: User) => {
        console.log(user);
        this.remove.emit(this.notification);
      });
  }
}
