<div class="cnt_rght_title_pic" [ngStyle]="{'background-image': 'url(' + notification?.getImgUrl() + ')'}">
  <div class="lft_infos">
    <div class="lft_infos_top">
      <p>Event wurde als beendet markiert</p>
    </div>
    <div class="lft_infos_mid_evt_clock">
      <i class="material-icons">update</i>
      <!-- <div class="comment_ntfc">
           <p class="nmbr_inf">3</p>
           <p class="txt_inf">Neue Kommentare</p>
       </div>
       <div class="join_ntfc">
           <p class="nmbr_inf">1</p>
           <p class="txt_inf">Neuer Teilnehmer</p>
       </div>  !-->
    </div>

    <div class="lft_infos_bottom_evt">

    </div>

  </div>
</div>

<div id="cnt_rght_evt_clock">
  <div id="cnt_rght_evt_wrapper">
    <div id="cnt_rght_evt_clock_i">
      <i class="material-icons">schedule</i>
      <p>14:00</p>
    </div>
    <div id="cnt_rght_evt_date_i">
      <i class="material-icons">date_range</i>
      <p>30.05.2017</p>
    </div>
    <div id="cnt_rght_evt_usr_i">
      <i class="material-icons">people</i>
      <p>4</p>
    </div>
    <div id="cnt_rght_evt_cmm_i">
      <i class="material-icons">question_answer</i>
      <p>24</p>
    </div>
    <div id="cnt_rght_evt_skill_i">
      <i class="material-icons">fitness_center</i>
      <p>5/5</p>
    </div>
  </div>
</div>

<div id="cnt_rght_txt_evt">
  <p id="cnt_rght_evt_title">
    Yoga am Attersee
  </p>
  <p id="cnt_rght_evt_plce">
    <i class="material-icons">place</i> 5 Km, Karlsruhestraße, Dortmund
    <i class="material-icons book_lft">bookmark</i> Yoga
  </p>

  <p id="cnt_rght_evt_desc">
    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
  </p>

</div>
<button mat-raised-button class="fill-x" color="primary" [routerLink]="['/event', notification?.event?.id]">Event</button>
