<div class="card-item event motifun-box">
  <div class="header img"
       [ngStyle]="{'background-image': 'url(' + event.getImgUrl() + '), url('+ event.getFallbackUrl() +')'}"
       (click)="openEvent()">
    <div class="user circle lite-border img xsmall link"
         [ngStyle]="{'background-image': 'url(' + event.creator.getLowImgUrl() + ')'}"
         [routerLink]="['/user', event.creator.id]"></div>
    <div class="sports">
      <span>{{event.sport.name}}</span>
    </div>

    <div class="info flex-row">
      <div class="flex-col flex-center">
        <span><fa-icon [icon]="icons.date"></fa-icon></span>
        <span>{{event.startDate.toJSDate() | date : 'shortDate'}}</span>
      </div>
      <div class="flex-col flex-center">
        <span><fa-icon [icon]="icons.time"></fa-icon></span>
        <span>{{event.startTime.toJSDate() | date : 'shortTime'}}</span>
      </div>
      <div class="flex-col flex-center">
        <span><fa-icon [icon]="icons.attendees"></fa-icon></span>
        <span>{{event.attendeesCount}}{{event.maxAttendees ? '/' + event.maxAttendees : ''}}</span>
      </div>
      <div class="flex-col flex-center">
        <span><fa-icon [icon]="icons.comments"></fa-icon></span>
        <span>{{event.commentsCount}}</span>
      </div>
      <div class="flex-col flex-center">
        <span><fa-icon [icon]="icons.skill"></fa-icon></span>
        <span>{{event.skill}}/5</span>
      </div>
    </div>
  </div>

  <div class="flex-col flex-center">
    <div class="description">
      <p>{{event.title}}</p>
      <p>{{event.description}}</p>
    </div>

    <div class="flex-row flex-center icons" *ngIf="!isCreatedEvent(); else created">
      <p class="action grow" [ngClass]="event.isAttender ? 'motifun text' : ''" (click)="joinEvent()">
        <fa-icon [icon]="icons.attend"></fa-icon>
        Teilnehmen
      </p>
      <p class="action grow" [ngClass]="event.isObserver ? 'motifun text' : ''" (click)="observeEvent()">
        <fa-icon [icon]="icons.observe"></fa-icon>
        Interessiert
      </p>
    </div>
    <ng-template #created>
      <div class="flex-row flex-center icons">
        <button mat-flat-button (click)="onCopyEvent()">
          <fa-icon [icon]="icons.copy"></fa-icon>
          Neu erstellen
        </button>
      </div>
    </ng-template>

    <p class="location">
      <fa-icon [icon]="icons.location"></fa-icon>
      {{event.distance ? event.getDistanceString() + ', ' : ''}}{{event.city}}
    </p>
  </div>
</div>
