import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {User} from '../../shared/models/user';
import {map, share, switchMap} from 'rxjs/operators';
import {ApiService} from './api.service';
import {Notification} from '../../modules/notifications/notification';
import {EventService} from './event.service';
import {NotificationType} from '../../shared/enums/notification-type';
import {Comment} from '../../shared/models/comment';
import {SocketIOService} from './socketio.service';
import {UserService} from './user.service';
import {Event} from '../../shared/models/event';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private counter = 0;

  private notificationsChanged$: Subject<Notification[]> = new Subject<Notification[]>();
  private notificationsCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  private notificationsCache: Notification[] = [];
  private notificationProfileCache: any = {};

  private notificationsEnd = false;

  constructor(private api: ApiService,
              private eventService: EventService,
              private userService: UserService,
              private ioService: SocketIOService) {
  }

  getNotifications(user: User): Observable<Notification[]> {
    if (this.notificationsEnd) {
      return of(this.notificationsCache.concat([]));
    }
    let param = '';
    if (this.notificationsCache.length > 0) {
      param = '?id=' + this.notificationsCache[this.notificationsCache.length - 1].id;
    }
    return this.api.get(`notification${param}`)
      .pipe(
        map((data: any) => {
          if (!data || data.length === 0) {
            this.notificationsEnd = true;
          }

          for (const n of data) {
            this.notificationsCache.push(new Notification(user).deserialize(n));
          }

          return this.notificationsCache.concat([]);
        })
      );
  }

  getNotification(user: User, id: number) {
    if (this.notificationProfileCache[id]) {
      return of(this.notificationProfileCache[id]);
    }
    let notification: Notification;
    return this.api.get<any>(`notification/${id}`)
      .pipe(
        switchMap((data: any) => {
          if (!data) {
            return null;
          }

          notification = new Notification(user).deserialize(data);

          if (notification.type === NotificationType.eventComment) {
            return this.eventService.getComment(notification.event, notification.subId);
          }

          return of(null);
        }),
        map((comment: Comment) => {
          if (comment) {
            notification.comment = comment;
          }
          this.notificationProfileCache[id] = notification;

          return notification;
        })
      );
  }

  getNotificationProfile(notification: Notification) {
    const n: Notification = this.notificationProfileCache[notification.id];
    if (n && ((n.event && n.event.creator) || (n.sender && n.sender.sports))) {
      return of(n);
    }

    // TODO: think about better solution
    if (notification.hasEvent()) {
      return this.eventService.getEvent(notification.event.id)
        .pipe(
          switchMap((event: Event) => {
            notification.event = event;
            this.notificationProfileCache[notification.id] = notification;

            if (notification.type === NotificationType.eventComment) {
              return this.eventService.getComment(event, notification.subId);
            }

            return of(null);
          }),
          map((comment: Comment) => {
            if (comment) {
              notification.comment = comment;
            }
            return notification;
          })
        );
    } else if (notification.hasUser()) {
      return this.userService.getFavoriteSports(notification.sender)
        .pipe(
          map((user: User) => {
            notification.sender = user;
            this.notificationProfileCache[notification.id] = notification;

            return notification;
          })
        );
    } else {
      return of(notification);
    }
  }

  prependNotification(notification: Notification) {
    this.notificationsCache.unshift(notification);
    this.notificationsChanged$.next(this.notificationsCache);
  }

  removeNotification(notification: Notification) {
    if (this.notificationProfileCache[notification.id]) {
      delete this.notificationProfileCache[notification.id];
    }

    for (let i = 0; i < this.notificationsCache.length; i++) {
      if (notification.id === this.notificationsCache[i].id) {
        this.notificationsCache.splice(i, 1);
        break;
      }
    }

    this.notificationsChanged$.next(this.notificationsCache.concat([]));
  }

  onNotificationChanged() {
    return this.notificationsChanged$;
  }

  onNewNotification(user: User) {
    return this.ioService.onNotification()
      .pipe(
        switchMap((data: any) => {
          return this.getNotification(user, data.id);
        }),
        map((notification: Notification) => {
          this.counter++;
          this.notificationsCount$.next(this.counter);
          return notification;
        }),
        share()
      );
  }

  onUnreadNotificationCount() {
    return this.notificationsCount$.pipe(share());
  }

  resetCounter() {
    this.counter = 0;
    this.notificationsCount$.next(0);
  }

  clear() {
    this.notificationsCache = [];
    this.notificationProfileCache = {};
  }
}
