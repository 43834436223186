import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Chat} from '../../../shared/models/chat';
import {ApiService} from '../../../core/services/api.service';
import {faEllipsisV, faFlag, faTrash} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-chat-user',
  templateUrl: './chat-user.component.html',
  styleUrls: ['./chat-user.component.css']
})
export class ChatUserComponent {

  icons = {
    more: faEllipsisV,
    delete: faTrash,
    flag: faFlag
  };

  @Input() chat: Chat;
  @Output() chatDeleted = new EventEmitter();
  @Output() chatReported = new EventEmitter();

  constructor(private api: ApiService) {
  }

  deleteChat() {
    if (!this.chat.id || this.chat.id.length <= 0) {
      this.chatDeleted.emit(this.chat);
      return;
    }
    this.api.delete<any>('chat/' + this.chat.id)
      .subscribe(data => {
        if (data.deleted) {
          this.chatDeleted.emit(this.chat);
        }
      });
  }

  reportChat() {
    this.api.get<any>('chat/' + this.chat.id + '/report')
      .subscribe((data: any) => {
        if (data.reported) {
          this.chatReported.emit(this.chat);
        }
      });
  }
}
