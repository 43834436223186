import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {User} from '../../../shared/models/user';
import {UserService} from '../../../core/services/user.service';
import {faUserCheck, faUserTimes} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-friend-request-item',
  templateUrl: './friend-request-item.component.html',
  styleUrls: ['./friend-request-item.component.css']
})
export class FriendRequestItemComponent {

  icons = {
    add: faUserCheck,
    deny: faUserTimes
  };

  @Input() user: User;
  @Output() changed: EventEmitter<User> = new EventEmitter<User>();

  constructor(private userService: UserService) {
  }

  addFriend(user: User) {
    if (!user) {
      return;
    }

    this.userService.addUser(user)
      .subscribe(data => {
        console.log(data);
      });
  }

  denyFriend(user: User) {
    if (!user) {
      return;
    }

    this.userService.denyUser(user)
      .subscribe(data => {
        console.log(data);
      });
  }

  private handleEmitUser(data: any) {
    this.user.userRelation = data.status;
    this.changed.emit(this.user);
  }
}
