<div class="row flex-col motifun-box" style="padding: 5px 40px;">
  <div id="user" class="inner-row flex-row">
    <div class="col-6 margin flex-col">
      <div class="flex-row">
        <div class="editable" style="width: 125px; margin-right: 25px;">
          <a class="abs edit-icon" (click)="onEditUserImg()" *ngIf="ownProfile">
            <fa-icon [icon]="icons.control.edit"></fa-icon>
          </a>
          <div class="img medium1 circle link" (click)="onShowUserImg()"
               [ngStyle]="{'background-image':'url(' + user.getMedImgUrl() + ')'}"></div>
        </div>
        <div class="col-flex flex-col editable">
          <a class="abs edit-icon" (click)="edit.target = !edit.target" *ngIf="ownProfile">
            <fa-icon [icon]="icons.control.edit"></fa-icon>
          </a>
          <span id="name">{{user.getFullName()}}</span>
          <ng-container *ngIf="!edit.target || !ownProfile">
            <div class="motifun text" *ngIf="false">Trainer</div>
            <p class="fill-remaining-space" *ngIf="ownProfile && (!user.target || user.target?.length === 0)"
               (click)="edit.target = !edit.target">Hier kannst du ein Ziel angeben. </p>
            <p class="fill-remaining-space">{{user.target}}</p>
          </ng-container>
          <form class="flex col" *ngIf="edit.target" [formGroup]="editTarget">
            <mat-form-field class="col-10">
              <input matInput
                     type="text"
                     placeholder="Ziel eingeben"
                     formControlName="target">
            </mat-form-field>
            <button mat-raised-button color="primary" class="col s10" (click)="onEditTarget()">Ziel ändern</button>
          </form>
        </div>
      </div>
      <div class="flex center" *ngIf="!ownProfile" style="margin-top: 0.8rem; font-size: 1.2em;">
        <a *ngIf="user.userRelation === -1 || user.userRelation === 0" (click)="sendFriendRequest(user)">
          <fa-icon [icon]="icons.friend.add"></fa-icon>
        </a>
        <a *ngIf="user.userRelation === 1" (click)="sendFriendRequest(user)">
          <fa-icon [icon]="icons.friend.remove"></fa-icon>
        </a>
        <a *ngIf="user.userRelation === 2 || user.userRelation === 3" (click)="denyFriendRequest(user)">
          <fa-icon [icon]="icons.friend.deny"></fa-icon>
        </a>
        <a *ngIf="user.userRelation === 3" (click)="sendFriendRequest(user)">
          <fa-icon [icon]="icons.friend.accept"></fa-icon>
        </a>
        <a [routerLink]="['/messages', user.id]">
          <fa-icon [icon]="icons.control.message"></fa-icon>
        </a>
        <a (click)="blockUser(user)">
          <fa-icon [icon]="icons.control.block"></fa-icon>
        </a>
        <a (click)="reportUser(user)">
          <fa-icon [icon]="icons.control.flag"></fa-icon>
        </a>
      </div>
    </div>
    <div class="spacer-v"></div>
    <div id="info" class="col-4 margin editable">
      <a class="abs edit-icon" (click)="edit.info = !edit.info" *ngIf="ownProfile">
        <fa-icon [icon]="icons.control.edit"></fa-icon>
      </a>
      <p class="section-headline">Infos</p>
      <ng-container *ngIf="!edit.info">
        <div class="inner-row">
          <span class="col-35 key">Alter</span>
          <span class="col-65 value">{{user?.age | number : '1.0-0'}}</span>
        </div>
        <div class="inner-row">
          <span class="col-35 key">Wohnort</span>
          <span class="col-65 value" *ngIf="!edit.info">{{user?.city}}</span>
        </div>
        <div class="inner-row">
          <span class="col-35 key">Letzter Login</span>
          <span class="col-65 value">{{user?.getLastSignIn()}}</span>
        </div>
        <div class="inner-row" *ngIf="false">
          <span class="col-35 key">Sprachen</span>
          <span class="col-65 value">{{user?.settings?.language}}</span>
        </div>
      </ng-container>
      <form class="flex col" *ngIf="edit.info" [formGroup]="editInfo">
        <mat-form-field class="col-10">
          <input matInput [min]="minDate" [max]="maxDate"
                 [matDatepicker]="dayOfBirth"
                 (click)="dayOfBirth.open()"
                 [formControlName]="'birthday'"
                 placeholder="Wann hast du Geburstag?">
          <mat-datepicker-toggle matSuffix [for]="dayOfBirth"></mat-datepicker-toggle>
          <mat-datepicker #dayOfBirth startView="multi-year" [startAt]="startDate"></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="col-10">
          <input matInput
                 type="text"
                 placeholder="Ort wählen"
                 [formControlName]="'location'"
                 [matAutocomplete]="placesAuto">
          <button mat-icon-button matSuffix (click)="onHandleLocateUser()">
            <mat-icon>location_on</mat-icon>
          </button>
          <mat-autocomplete #placesAuto="matAutocomplete" [displayWith]="displayNull()"
                            (optionSelected)="onSelectPlace($event)">
            <mat-option *ngIf="!places$" class="is-loading"><mat-spinner diameter="50"></mat-spinner></mat-option>
            <mat-option *ngFor="let place of places$ | async" [value]="place">
              {{place.getAutocompleteString()}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <button mat-raised-button color="primary" class="col s10" (click)="onEditInfo()">Infos ändern</button>
      </form>
    </div>
  </div>
  <div class="spacer-h"></div>
  <div class="about inner-row flex-col editable">
    <a class="abs edit-icon" (click)="edit.about = !edit.about" *ngIf="ownProfile">
      <fa-icon [icon]="icons.control.edit"></fa-icon>
    </a>
    <ng-container *ngIf="!edit.about">
      <span *ngIf="ownProfile">Über dich</span>
      <span *ngIf="!ownProfile">Über {{user.firstName}}</span>
      <p
        *ngIf="(user.aboutMe && user.aboutMe.length > 0)">{{user.aboutMe ? user.aboutMe : user.firstName + ' hat diesen Teil seines Profils noch nicht ausgefüllt. Vielleicht kannst du ihn ja davon überzeugen.'}}</p>
      <p *ngIf="(!user.aboutMe || user.aboutMe.length <= 0) && ownProfile" (click)="edit.about = !edit.about">Hier
        könntest du etwas über dich erzählen,
        wie z.B. was für Sporterfahrungen du bereits gesammelt hast.</p>
    </ng-container>
    <form class="flex col" *ngIf="edit.about" [formGroup]="editAbout">
      <mat-form-field class="col-10">
                <textarea matInput
                          matAutosize
                          cdkAutosizeMinRows="3"
                          cdkAutosizeMaxRows="5"
                          maxlength="400"
                          placeholder="Über Dich"
                          name="about"
                          [formControlName]="'about'"></textarea>
        <mat-hint align="end">{{editAbout.get('about').value.length || 0}}/400
        </mat-hint>
      </mat-form-field>
      <button mat-raised-button color="primary" class="col s10" (click)="onEditAbout()">Über dich ändern</button>
    </form>
  </div>
</div>

<div class="row flex-col motifun-box editable" style="padding: 5px 40px; margin-top: 20px;">
  <a class="abs edit-icon" (click)="onEditFavoriteSports()" *ngIf="ownProfile">
    <fa-icon [icon]="icons.control.edit"></fa-icon>
  </a>
  <p class="section-headline">Lieblingssportarten</p>
  <ul class="icon-list">
    <li *ngFor="let icon of sports$">
      <fa-icon [icon]="icon.icon"></fa-icon>
      <span>{{icon.name}}</span>
    </li>
  </ul>
</div>

<div class="row flex-col motifun-box" style="padding: 5px 40px; margin-top: 20px;">
  <p class="section-headline">Events</p>
  <div class="inner-row flex-row" style="text-align: center;">
    <div class="col-5 margin">
      <span class="events-count">{{user?.participatedEventsCount}}</span>
      Teilgenommen
    </div>
    <div class="col-5 margin">
      <span class="events-count">{{user?.createdEventsCount}}</span>
      Erstellt
    </div>
  </div>
</div>

<div class="row flex-col motifun-box flex-center" style="padding: 5px 40px; margin-top: 20px;">
  <p class="section-headline">Auszeichnungen</p>
  <ul class="icon-list">
    <li>
      <fa-icon [icon]="icons.awards.trophy"></fa-icon>
      <span>10 Events erstellt</span>
    </li>
    <li>
      <fa-icon [icon]="icons.awards.medal"></fa-icon>
      <span>10 Events erstellt</span>
    </li>
    <li>
      <fa-icon [icon]="icons.awards.award"></fa-icon>
      <span>10 Events erstellt</span>
    </li>
    <li>
      <fa-icon [icon]="icons.awards.cert"></fa-icon>
      <span>10 Events erstellt</span>
    </li>
  </ul>
</div>
