import {Component, OnInit} from '@angular/core';
import {DataStoreService} from '../../../core/services/data-store.service';
import {MatDialogRef} from '@angular/material/dialog';
import {Sport} from '../../../shared/models/sport';

@Component({
  selector: 'app-event-form-sports-dialog',
  templateUrl: './event-form-sports-dialog.component.html',
  styleUrls: ['./event-form-sports-dialog.component.css']
})
export class EventFormSportsDialogComponent implements OnInit {

  filteredSports: { headline: string, sports: Sport[] }[] = [];

  sports: Sport[];

  constructor(private data: DataStoreService,
              private dialogRef: MatDialogRef<EventFormSportsDialogComponent>) {
  }

  ngOnInit() {
    this.data.getSports()
      .subscribe((sports: Sport[]) => {
        this.sports = sports;
        this.generateSportsArray();
      });
  }

  setSport(sport: Sport) {
    this.dialogRef.close(sport);
  }

  generateSportsArray(arr?: Sport[]) {
    let sports: Sport[];
    if (arr) {
      sports = arr;
    } else {
      sports = this.sports;
    }
    this.filteredSports = this.range('A', 'Z');

    for (const cat of this.filteredSports) {
      cat.sports = sports.filter((sport: Sport) => {
        return sport.name.startsWith(cat.headline);
      });
    }
  }

  range(start: string, stop: string): { headline: string, sports: Sport[] }[] {
    const arr: { headline: string, sports: Sport[] }[] = [];
    for (let idx = start.charCodeAt(0), end = stop.charCodeAt(0); idx <= end; ++idx) {
      arr.push({headline: String.fromCharCode(idx), sports: []});
    }
    return arr;
  }

  filterSports(filterText: string) {
    filterText = filterText.toLocaleLowerCase();

    if (!this.sports) {
      return;
    }

    const filtered = this.sports.filter((sport) => {
      return sport.name.toLocaleLowerCase().includes(filterText);
    });

    this.generateSportsArray(filtered);
  }
}
