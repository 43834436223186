import {Component, OnInit} from '@angular/core';
import {Sport} from '../../../shared/models/sport';
import {DataStoreService} from '../../../core/services/data-store.service';

@Component({
  selector: 'app-user-favorite-sports-dialog',
  templateUrl: './user-favorite-sports-dialog.component.html',
  styleUrls: ['./user-favorite-sports-dialog.component.css']
})
export class UserFavoriteSportsDialogComponent {

  sports: Sport[] = [];

  constructor(private data: DataStoreService) {
    this.data.getSports()
      .subscribe((sports: Sport[]) => {
        this.sports = sports;
      });
  }

  save() {

  }
}
