import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Event} from '../../../shared/models/event';
import {EventType} from '../event-type';
import {
  faBookmark,
  faCalendarDay,
  faCheck,
  faClock,
  faComment,
  faCopy,
  faDumbbell,
  faHeart,
  faMapMarkerAlt,
  faUsers
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-event-item',
  templateUrl: './event-item.component.html',
  styleUrls: ['./event-item.component.css']
})
export class EventItemComponent {

  icons = {
    skill: faDumbbell,
    sports: faBookmark,
    attend: faCheck,
    observe: faHeart,
    location: faMapMarkerAlt,
    date: faCalendarDay,
    time: faClock,
    comments: faComment,
    attendees: faUsers,
    copy: faCopy
  };

  @Input() event: Event;
  @Input() type: EventType;
  @Input() isDarkMode: boolean;
  @Output() join: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() observe: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() clicked: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() copied: EventEmitter<Event> = new EventEmitter<Event>();

  constructor() {
  }

  joinEvent() {
    if (this.event.id <= 0) {
      return;
    }

    this.join.emit(this.event);
  }

  observeEvent() {
    if (this.event.id <= 0) {
      return;
    }

    this.observe.emit(this.event);
  }

  openEvent() {
    this.clicked.emit(this.event);
  }

  isCreatedEvent(): boolean {
    return this.type === EventType.CREATED;
  }

  onCopyEvent() {
    this.copied.emit(this.event);
  }
}
