import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RegisterComponent} from './register/register.component';
import {RegisterFormComponent} from './register-form/register-form.component';
import {RegisterRoutingModule} from './register-routing.module';
import {SharedModule} from '../../shared/shared.module';


@NgModule({
  declarations: [
    RegisterComponent,
    RegisterFormComponent
  ],
  imports: [
    CommonModule,
    RegisterRoutingModule,
    SharedModule
  ],
  exports: [
    RegisterFormComponent
  ]
})
export class RegisterModule {
}
