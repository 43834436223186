import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {create} from 'nouislider';

@Component({
  selector: 'app-range-slider',
  templateUrl: './range-slider.component.html',
  styleUrls: ['./range-slider.component.css']
})
export class RangeSliderComponent implements AfterViewInit {

  @Input() config: any;
  @Output() valueChanged: EventEmitter<[number, number]> = new EventEmitter<[number, number]>();
  @ViewChild('slider', {static: true}) slider: ElementRef;

  constructor() {
  }

  ngAfterViewInit() {
    create(this.slider.nativeElement, this.config);
    this.slider.nativeElement.noUiSlider.on('change', (e: (number | string)[]) => {
      console.log([Number(e[0]), Number(e[1])]);
      this.valueChanged.emit([Number(e[0]), Number(e[1])]);
    });
  }
}
