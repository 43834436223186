import {Deserializable} from './deserializable.model';
import {IEventWhere} from '../interface/IEventWhere';

export class Location implements Deserializable {

  address: string;
  displayName: string;
  street: string;
  houseNumber: string;
  postalCode: string;
  city: string;
  state: string;
  country: string;
  lat: number;
  lng: number;

  osmKey: string;
  osmValue: string;

  constructor() {
  }

  deserialize(input: any, assign: boolean = false) {
    if (!input) {
      return this;
    }

    if (assign) {
      Object.assign(this, input);
    }

    this.address = input.address;
    this.street = input.street;
    this.houseNumber = input.houseNumber;
    this.postalCode = input.postalCode;
    this.city = input.city;
    this.state = input.state;
    this.country = input.country;

    this.lat = input.latitude ? input.latitude : this.lat;
    this.lng = input.longitude ? input.longitude : this.lng;

    return this;
  }

  parseLatLng(lat: number, lng: number) {
    this.lat = lat;
    this.lng = lng;

    return this;
  }

  getAddressString() {
    let s = '';
    if (this.street) {
      s += this.street;
    }
    if (this.houseNumber) {
      s += s.length > 0 ? ' ' + this.houseNumber : '';
    }
    if (this.postalCode) {
      s += s.length > 0 ? ', ' + this.postalCode : this.postalCode;
    }
    if (this.city) {
      s += s.length > 0 ? ' ' + this.city : this.city;
    }
    if (this.country) {
      s += s.length > 0 ? ', ' + this.country : this.country;
    }
    return s;
  }

  getAutocompleteString() {
    return this.city + ', ' + this.country;
  }

  parseGoogle(place: any) {
    this.lat = place.geometry.location.lat();
    this.lng = place.geometry.location.lng();
    if (place.types.indexOf('store') >= 0
      || place.types.indexOf('point_of_interest') >= 0
      || place.types.indexOf('establishment') >= 0) {
      this.address = place.name + ', ' + (place.formatted_address || place.address);
    } else {
      this.address = place.formatted_address || place.address;
    }

    for (const comp of place.address_components) {
      if (comp.types.indexOf('locality') >= 0 && comp.types.indexOf('political') >= 0) {
        this.city = comp.long_name;
      }
      if (comp.types.indexOf('country') >= 0 && comp.types.indexOf('political') >= 0) {
        this.country = comp.long_name;
      }
      if (comp.types.indexOf('postal_code') >= 0) {
        this.postalCode = comp.long_name;
      }
      if (comp.types.indexOf('administrative_area_level_1') >= 0 && comp.types.indexOf('political')) {
        this.state = comp.long_name;
      }
      if (comp.types.indexOf('street_number') >= 0) {
        this.houseNumber = comp.long_name;
      }
    }

    return this;
  }

  parseOsm(data: any) {
    console.log(data);
    this.lat = data.geometry.coordinates[1];
    this.lng = data.geometry.coordinates[0];
    this.city = data.properties.name;
    this.country = data.properties.country;
    this.state = data.properties.state;
    this.postalCode = data.properties.postcode;
    this.osmKey = data.properties.osm_key;
    this.osmValue = data.properties.osm_value;

    return this;
  }

  parseNominatim(data: any) {
    this.lat = data.lat;
    this.lng = data.lon;

    if (data.address) {
      this.street = data.address.street || data.address.road || data.address.pedestrian;
      this.houseNumber = data.address.house_number;
      this.postalCode = data.address.postcode;
      this.city = data.address.city || data.address.village || data.address.town || data.address.state;
      this.state = data.address.state;
      this.country = data.address.country;
    }
    this.displayName = data.formatted_address || data.display_name;

    this.address = this.getAddressString();

    return this;
  }

  parseKomoot(data: any) {
    data = data.features ? data.features.length > 0 ? data.features[0] : data : data;
    this.lat = data.geometry.coordinates[1];
    this.lng = data.geometry.coordinates[0];
    this.city = data.properties.name;
    this.country = data.properties.country;
    this.state = data.properties.state;
    this.postalCode = data.properties.postcode;
    this.osmKey = data.properties.osm_key;
    this.osmValue = data.properties.osm_value;

    return this;
  }

  parseLocationIQ(data: any) {
    console.log(data);
    this.lat = data.geometry.coordinates[1];
    this.lng = data.geometry.coordinates[0];
    this.city = data.properties.name;
    this.country = data.properties.country;
    this.state = data.properties.state;
    this.postalCode = data.properties.postcode;
    this.osmKey = data.properties.osm_key;
    this.osmValue = data.properties.osm_value;

    return this;
  }

  parseTilehosting(data: any) {
    console.log(data);
    return this;
  }

  parseGeo(data: any) {
    this.lat = data.coords.latitude;
    this.lng = data.coords.longitude;

    return this;
  }

  parseIpInfo(data: any) {
    this.city = data.city;
    this.country = data.country;
    this.address = data.city + ', ' + data.country;
    const latlng = data.loc.split(',');
    this.lat = latlng[0];
    this.lng = latlng[1];

    return this;
  }

  parseGeoIp(data: any) {
    this.city = data.city;
    this.country = data.country_name;
    this.address = this.city + ', ' + this.country;
    this.lat = data.latitude;
    this.lng = data.longitude;
    this.postalCode = data.zip_code;

    return this;
  }

  getDistanceInKm(location?: Location) {
    if (!location) {
      location = this;
    }
    const R = 6371;
    const dLat = this.deg2rad(location.lat - this.lat);
    const dLon = this.deg2rad(location.lng - this.lng);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2rad(this.lat)) * Math.cos(this.deg2rad(location.lat)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2)
    ;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    return d;
  }

  private deg2rad(deg: number) {
    return deg * (Math.PI / 180);
  }

  getIEventWhere(): IEventWhere {
    return {
      address: this.getAddressString(),
      city: this.city,
      state: this.state,
      country: this.country,
      street: this.street,
      houseNumber: this.houseNumber,
      postalCode: this.postalCode,
      lat: Number(this.lat),
      lng: Number(this.lng)
    };
  }

  clone() {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
  }
}
