import {Component, OnInit} from '@angular/core';
import {Alert} from '../../../shared/models/alert';
import {AlertService} from '../../services/alert.service';
import {Observable} from 'rxjs';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-alert-list',
  templateUrl: './alert-list.component.html',
  styleUrls: ['./alert-list.component.css'],
  animations: [
    trigger('flyInOut', [
      state('in', style({transform: 'translateX(0)'})),
      transition('void => *', [
        style({transform: 'translateX(100%)'}),
        animate(200)
      ])
    ])
  ]
})
export class AlertListComponent {

  alerts$: Observable<Alert[]>;

  constructor(private alertService: AlertService) {
    this.alerts$ = alertService.getAlerts();
  }

  onRemove(alert: Alert) {
    this.alertService.remove(alert);
  }
}
