import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {User} from '../../../shared/models/user';
import {EventType} from '../event-type';
import {UserService} from '../../../core/services/user.service';

@Component({
  selector: 'app-event-start',
  templateUrl: './event-start.component.html',
  styleUrls: ['./event-start.component.css']
})
export class EventStartComponent {

  user$: Observable<User>;

  searched: EventType = EventType.SEARCHED;
  created: EventType = EventType.CREATED;
  joined: EventType = EventType.JOINED;
  observed: EventType = EventType.OBSERVED;
  passed: EventType = EventType.PASSED;

  constructor(private userService: UserService) {
    this.user$ = userService.getUser();
  }
}
