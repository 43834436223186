import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ChatRoutingModule} from './chat-routing.module';
import {ChatMessageComponent} from './chat-message/chat-message.component';
import {ChatUserComponent} from './chat-user/chat-user.component';
import {ChatComponent} from './chat.component';
import {SharedModule} from '../../shared/shared.module';

@NgModule({
  declarations: [
    ChatComponent,
    ChatMessageComponent,
    ChatUserComponent
  ],
  imports: [
    CommonModule,
    ChatRoutingModule,
    SharedModule
  ]
})
export class ChatModule {
}
