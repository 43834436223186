import {Injectable} from '@angular/core';
import {fromEvent} from 'rxjs';
import {debounceTime, distinct, filter, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ScrollService {

  private oldPos: number;

  constructor() {
  }

  onWindowScrollY(elem: Element, offset: number, debounce: number, itemHeight: number, itemsSize: number, bottomUp: boolean = false) {
    return fromEvent(window, 'scroll')
      .pipe(
        map(() => window.pageYOffset),
        filter((current: number) => {
          let b: boolean;
          if (!this.oldPos) {
            this.oldPos = current;
          }
          if (!bottomUp) {
            b = current >= elem.scrollHeight - window.innerHeight - offset && current > this.oldPos;
          } else {
            b = current <= offset && current < this.oldPos;
          }
          this.oldPos = current;
          return b;
        }),
        debounceTime(debounce),
        distinct(),
        map((y: number) => {
          const c = Math.ceil((y + window.innerHeight) / (itemHeight * itemsSize));
          return c;
        })
      );
  }

  onScrollY(elem: Element, offset: number, debounce: number, itemHeight: number, itemsSize: number, bottomUp: boolean = false) {
    return fromEvent(window, 'scroll')
      .pipe(
        map(() => elem.scrollTop),
        filter((current: number) => {
          let b: boolean;
          if (!this.oldPos) {
            this.oldPos = current;
          }
          if (!bottomUp) {
            b = current >= elem.scrollHeight - elem.clientHeight - offset && current > this.oldPos;
          } else {
            b = current <= offset && current < this.oldPos;
          }
          this.oldPos = current;
          return b;
        }),
        debounceTime(debounce),
        distinct(),
        map((y: number) => {
          const c = Math.ceil((y + window.innerHeight) / (itemHeight * itemsSize));
          return c;
        })
      );
  }
}
