import {Component, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SocketIOService} from '../../services/socketio.service';
import {User} from '../../../shared/models/user';
import {environment} from '../../../../environments/environment';
import {SettingsService} from '../../services/settings.service';
import {Settings} from '../../../shared/models/settings';
import {DataStoreService} from '../../services/data-store.service';
import {OverlayContainer} from '@angular/cdk/overlay';
import {MatSidenav} from '@angular/material/sidenav';
import {merge, Observable, Subscription} from 'rxjs';
import {NavigationEnd, Router} from '@angular/router';
import {UserService} from '../../services/user.service';
import {MessageType} from '../../../shared/enums/message-type';
import {ChatService} from '../../services/chat.service';
import {NotificationService} from '../../services/notification.service';
import {share, shareReplay, switchMap, tap} from 'rxjs/operators';
import {Notification} from '../../../modules/notifications/notification';
import {AlertService} from '../../services/alert.service';
import {Alert} from '../../../shared/models/alert';
import {AlertType} from '../../../shared/enums/alert-type';
import {
  faBars,
  faBell,
  faCogs,
  faEnvelope,
  faHome,
  faPlusSquare,
  faPowerOff,
  faSearch,
  faTimes,
  faUser,
  faUserFriends
} from '@fortawesome/free-solid-svg-icons';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';

@Component({
  selector: 'app-signed-in',
  templateUrl: './signed-in.component.html',
  styleUrls: ['./signed-in.component.scss']
})
export class SignedInComponent implements OnInit, OnDestroy {

  year: number;

  images = {
    logo: {
      lite: environment.imageHost + 'logos/logo_white_small.png',
      dark: environment.imageHost + 'logos/logo_t.png'
    },
    logoMenu: environment.imageHost + 'logos/logo-160x160.png',
    background: environment.imageHost + '',
    closedMenu: environment.imageHost + 'logos/logo_w_56x56.png',
    openedMenu: environment.imageHost + 'logos/logo_w_160x160.png'
  };

  icons: { [key: string]: IconDefinition } = {
    notification: faBell,
    logout: faPowerOff,
    menu: faBars,
    close: faTimes,
    start: faHome,
    add: faPlusSquare,
    profile: faUser,
    messages: faEnvelope,
    friends: faUserFriends,
    settings: faCogs,
    search: faSearch
  };
  openMenu: boolean;

  user$: Observable<User>;
  private user: User;

  unreadNotificationCount$: Observable<number>;
  unreadMessageCount$: Observable<number>;

  innerHeight: number;
  innerWidth: number;

  settings: Settings;

  private router$: Subscription;

  @ViewChild(MatSidenav, {static: true}) mobileSideNav: MatSidenav;

  constructor(private alertService: AlertService,
              private io: SocketIOService,
              private chatService: ChatService,
              private dataService: DataStoreService,
              private userService: UserService,
              private settingsService: SettingsService,
              private notificationService: NotificationService,
              private overlay: OverlayContainer,
              private router: Router) {
    this.year = new Date().getFullYear();
    this.innerHeight = (window.innerHeight);
    this.innerWidth = (window.innerWidth);
    this.openMenu = innerWidth >= 1620;
  }

  ngOnInit() {
    this.user$ = this.userService.getUser()
      .pipe(
        tap((user: User) => {
          const s = user.settings;
          if (!s.isDarkMode && this.overlay.getContainerElement().classList.contains('dark-theme')) {
            this.overlay.getContainerElement().classList.remove('dark-theme');
          } else if (s.isDarkMode && !this.overlay.getContainerElement().classList.contains('dark-theme')) {
            this.overlay.getContainerElement().classList.add('dark-theme');
          }
          this.settings = s;
        }),
        share()
      );

    this.unreadNotificationCount$ = this.notificationService.onUnreadNotificationCount()
      .pipe(
        tap((value) => console.log(value + ' -> notificationcount')),
        shareReplay(1)
      );

    this.user$
      .pipe(
        switchMap((user: User) => {
          this.user = user;
          return this.notificationService.onNewNotification(user);
        })
      )
      .subscribe((notification: Notification) => {
        this.alertService.appendAlert(
          new Alert(
            'Neue Benachrichtigung',
            notification.text,
            AlertType.INFO
          )
        );
      });

    this.unreadMessageCount$ = merge(this.chatService.onUnreadMessageCount(), this.chatService.getUnreadMessagesCount())
      .pipe(
        tap((value) => console.log(value + ' -> message-count')),
        shareReplay(1)
      );

    this.chatService.onNewMessage()
      .subscribe((msg: any) => {
        if (msg.type === MessageType.RECEIVED) {
          this.alertService.appendAlert(
            new Alert(
              'Neue Nachticht',
              msg.text,
              AlertType.INFO
            )
          );
        }
      });

    this.router$ = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.mobileSideNav.close();
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.innerWidth = window.innerWidth;
  }

  logout() {
    this.dataService.handleLogout();
    this.dataService.clear();
    this.io.disconnect();
    this.closeMenu();
  }

  closeMenu() {
    this.mobileSideNav.close();
  }

  ngOnDestroy() {
    this.router$.unsubscribe();
  }
}
