import {Component, OnInit} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {ActivatedRoute, Params} from '@angular/router';
import {ApiService} from '../../services/api.service';
import {catchError, finalize, map, share, switchMap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';

@Component({
  selector: 'app-activate-account',
  templateUrl: './activate-account.component.html',
  styleUrls: ['./activate-account.component.css']
})
export class ActivateAccountComponent implements OnInit {

  images: { [key: string]: string } = {
    background: environment.imageHost + 'landing/bg_activate.jpg',
    logo: environment.imageHost + 'logos/logo_mt_384x384.png'
  };

  loading: boolean = true;
  activated: boolean = false;
  error: boolean = false;
  invalid: boolean = false;

  constructor(private route: ActivatedRoute,
              private api: ApiService) {
  }

  ngOnInit() {
    this.route.params
      .pipe(
        switchMap((params: Params) => {
          if (!params['token']) {
            return of(null);
          }
          return this.api.get('activate/' + params['token']);
        }),
        map(data => data),
        finalize(() => {
          console.log('second finalize');
          this.loading = false;
        }),
        catchError((err: any, caught: Observable<any>) => {
          this.error = true;
          console.log(err);
          return err;
        }),
        share()
      )
      .subscribe((data: any) => {
        this.loading = false;
        if (!data) {
          this.invalid = true;
        }
        if (data.msg) {
          this.activated = true;
        }
      });
  }

}
