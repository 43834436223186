import {Injectable} from '@angular/core';
import {merge, Observable, of, Subject} from 'rxjs';
import {User} from '../../shared/models/user';
import {catchError, filter, map, shareReplay, switchMap} from 'rxjs/operators';
import {Settings} from '../../shared/models/settings';
import {ApiService} from './api.service';
import {SettingsService} from './settings.service';
import {FriendState} from '../../shared/enums/friend-state';
import {Activity} from '../../shared/models/activity';
import {Router} from '@angular/router';
import {HttpErrorResponse} from '@angular/common/http';
import {Sport} from '../../shared/models/sport';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private user$: Observable<User>;
  private activities$: Observable<Activity[]>;

  private user: User;
  private profileCache: any = {};
  private friendsCache: User[] = [];
  private friendsRequestCache: User[] = [];
  private searchedFriendsCache: User[] = [];
  private blockedUsersCache: User[] = [];

  private updateRequests$: Subject<User[]> = new Subject();
  private updateFriends$: Subject<User[]> = new Subject();
  private updateSearched$: Subject<User[]> = new Subject();

  private updateActivities$: Subject<Activity[]> = new Subject();

  private end: { [key: string]: any } = {
    friends: false,
    requests: false,
    searchFriends: false,
    blocked: false,
    activities: {},
    userActivities: false
  };

  private lastSearchQuery = '';

  constructor(private api: ApiService,
              private router: Router,
              private settings: SettingsService) {
  }

  getUser(): Observable<User> {
    if (this.user) {
      return of(this.user);
    } else if (!this.user$) {
      this.user$ = this.api.get('user')
        .pipe(
          switchMap(user => {
            this.user = new User().deserialize(user);
            this.user.isSignedIn = true;

            return merge(this.settings.observeSettings(), this.settings.getSettings());
          }),
          map((settings: Settings) => {
            console.log('user service: received settings');
            this.user.settings = settings;

            if (!this.user.settings.language) {
              this.user.settings.language = 'de';
            }

            return this.user;
          }),
          shareReplay(1)
        );
    }

    return this.user$;
  }

  getProfile(id: string) {
    if (this.user && this.user.id === id) {
      return this.user$;
    } else if (this.profileCache[id]) {
      return of(this.profileCache[id]);
    }
    return this.api.get(`user/${id}`)
      .pipe(
        map((user: any) => {
          this.profileCache[id] = new User().deserialize(user);

          return this.profileCache[id];
        }),
        catchError((err: HttpErrorResponse, caught) => {
          if (err.error.code === 404) {
            return this.router.navigate(['/not-found']);
          } else {
            return this.router.navigate(['/error']);
          }
        })
      );
  }

  getActivities(user: User): Observable<Activity[]> {
    if (this.end.activities[user.id]) {
      return of(this.profileCache[user.id].activities);
    }
    if (this.end.userActivities) {
      return of(this.user.activities);
    }

    let params;
    if (user && this.profileCache[user.id] && this.profileCache[user.id].activities.length > 0) {
      params = {
        t: this.profileCache[user.id].activities[user.activities.length - 1].timestamp.utc().format('x')
      };
    } else if ((!user || user.id === this.user.id) && this.user.activities && this.user.activities.length > 0) {
      params = {
        t: this.user.activities[this.user.activities.length - 1].timestamp.toUTC().toMillis()
      };
    }

    const url = this.user.id === user.id ? `activities` : `${user.id}/activities`;
    return this.api.get<any>(`user/${url}`, params)
      .pipe(
        filter(() => !this.end.activities[user.id]),
        map((activities: any) => {
          if (!user || user.id === this.user.id) {
            return this.mapUserActivities(activities);
          }
          return this.mapProfileActivities(user, activities);
        })
      );
  }

  private mapUserActivities(activities: any) {
    if (!activities || activities.length === 0) {
      this.end.userActivities = true;
      return of(this.user.activities);
    }
    let activity;
    for (const a of activities) {
      activity = new Activity(this.user).deserialize(a);
      this.user.activities.push(activity);
    }

    return this.user.activities.concat();
  }

  private mapProfileActivities(user: User, activities: any) {
    if (!activities || activities.length === 0) {
      this.end.activities[user.id] = {end: true};
      return of(this.profileCache[user.id].activities);
    }
    let activity;
    for (const a of activities) {
      activity = new Activity(this.profileCache[user.id]).deserialize(a);
      this.profileCache[user.id].activities.push(activity);
    }

    return this.profileCache[user.id].activities.concat();
  }

  getFriends() {
    if (this.end.friends) {
      return of(this.friendsCache);
    }

    let params = null;
    if (this.friendsCache.length > 0) {
      params = {
        s: this.friendsCache.length
      };
    }

    return merge(this.api.get('user/friends', params)
      .pipe(
        map((friends: any) => {
          if (!friends || friends.length === 0) {
            this.end.friends = true;
          } else {
            let u: User;
            for (const user of friends) {
              u = new User().deserialize(user.friend);
              u.userRelation = FriendState.FRIENDS;

              this.friendsCache.push(u);
            }
          }

          return this.friendsCache;
        })
      ), this.updateFriends$);
  }

  getFriendRequests() {
    if (this.end.requests) {
      return of(this.friendsRequestCache);
    }

    let params = null;
    if (this.friendsRequestCache.length > 0) {
      params = {
        s: this.friendsRequestCache.length
      };
    }

    return this.api.get<any>('user/friends/requests', params)
      .pipe(
        map((requests: any) => {
          if (!requests || requests.length === 0) {
            this.end.requests = true;
          } else {
            let u: User;
            for (const user of requests) {
              u = new User().deserialize(user.friend);

              this.friendsRequestCache.push(u);
            }
          }

          return this.friendsRequestCache.concat([]);
        })
      );
  }

  getFindFriends(query: string) {
    // if reached end and query did not change or is more specific
    if (this.end.requests && this.lastSearchQuery.length <= query.length) {
      return of(this.searchedFriendsCache);
    }

    // only if less than 12 elements and more specific search, then filter on client side.
    if (this.searchedFriendsCache.length < 12 && this.searchedFriendsCache.length > 0 && this.lastSearchQuery.length < query.length) {
      this.searchedFriendsCache = this.searchedFriendsCache.filter(user => user.getFullName().indexOf(query) >= 0);
      return of(this.searchedFriendsCache);
    }

    // if query changed reset items
    if (this.lastSearchQuery.length !== query.length) {
      this.end.searchFriends = false;
      this.searchedFriendsCache = [];
    }

    const params = {
      q: query,
      s: this.searchedFriendsCache.length > 0 ? this.searchedFriendsCache.length : undefined
    };

    return this.api.get<any>('user/friends/find', params)
      .pipe(
        map((friends: any) => {
          if (!friends || friends.length === 0) {
            this.end.friends = true;
          } else {
            let u: User;
            for (const user of friends) {
              u = new User().deserialize(user.user2);

              this.searchedFriendsCache.push(u);
            }
          }

          return this.searchedFriendsCache.concat([]);
        })
      );
  }

  getBlockedUsers() {
    return this.api.get('user/blocked');
  }

  addUser(user: User) {
    if (!user) {
      return null;
    }

    return this.api.get<any>('user/' + user.id + '/add')
      .pipe(
        map((data: any) => {
          if (data.msg === 'accepted') {
            user.userRelation = FriendState.FRIENDS;
            this.friendsCache.push(user);
            this.removeUserFromArray(this.friendsRequestCache, user);
            this.updateFriends$.next(this.friendsCache.concat());
            this.updateRequests$.next(this.friendsRequestCache.concat());
            if (this.searchedFriendsCache.length > 0 && user.getFullName().indexOf(this.lastSearchQuery) >= 0) {
              this.updateSearched$.next(this.searchedFriendsCache.concat());
            }
          } else if (data.msg === 'removed') {
            user.userRelation = FriendState.NO_FRIENDS;

            this.removeUserFromArray(this.friendsCache, user);
            this.updateFriends$.next(this.friendsCache.concat());

            if (this.searchedFriendsCache.length > 0) {
              this.removeUserFromArray(this.searchedFriendsCache, user);
              this.updateSearched$.next(this.searchedFriendsCache.concat([]));
            }
          }

          return user;
        })
      );
  }

  denyUser(user: User) {
    if (!user) {
      return null;
    }

    return this.api.get<any>('user/' + user.id + '/deny')
      .pipe(
        map((data: any) => {
          if (data.msg === 'denied') {
            user.userRelation = FriendState.NO_FRIENDS;
            this.removeUserFromArray(this.friendsRequestCache, user);
            this.updateRequests$.next(this.friendsRequestCache.concat([]));

            return user;
          }
          return null;
        })
      );
  }

  private removeUserFromArray(users: User[], user: User) {
    for (let i = 0; i < users.length; i++) {
      if (users[i].id === user.id) {
        users.splice(i, 1);
        break;
      }
    }
  }

  blockUser(user: User) {
    return this.api.get<any>('user/' + user.id + '/block')
      .pipe(
        map((data: any) => {
          console.log(data);
          if (data.msg === 'unblocked') {
            for (let i = 0; i < this.blockedUsersCache.length; i++) {
              if (this.blockedUsersCache[i].id === user.id) {
                this.blockedUsersCache.splice(i, 1);
                break;
              }
            }
          } else if (data.msg === 'blocked') {
            return true;
          }

          return false;
        })
      );
  }

  setUser(user: User) {
    this.user = user;
  }

  onSearchChange(): Observable<User[]> {
    return this.updateSearched$;
  }

  onRequestsChange(): Observable<User[]> {
    return this.updateRequests$;
  }

  onFriendsChange(): Observable<User[]> {
    return this.updateFriends$;
  }

  updateUser(user: User): Observable<User> {
    return this.api.put('user', user)
      .pipe(
        map((data: any) => {
          this.user.deserialize(data);
          return this.user;
        })
      );
  }

  getFavoriteSports(user: User) {
    const u: User = this.profileCache[user.id];
    if (u && u.sports) {
      return of(u);
    }

    if (!u) {
      this.profileCache[user.id] = user;
    }
    return this.api.get(`user/${user.id}/sports`)
      .pipe(
        map((data) => {
          const sports = [];
          for (const s of data) {
            sports.push(new Sport().deserialize(s));
          }
          this.profileCache[user.id].sports = sports;

          return this.profileCache[user.id];
        })
      );
  }

  clear() {
    this.user = null;
    this.profileCache = {};
    this.friendsCache = [];
    this.friendsRequestCache = [];
    this.searchedFriendsCache = [];
    this.blockedUsersCache = [];
  }
}
