<div id="content" class="flex-row" *ngIf="(notifications$ | async) as notifications">
  <ul *ngIf="notifications && notifications.length > 0">
    <li *ngFor="let notification of notifications"
        (click)="onNotificationSelected(notification)"
        class="motifun-box" [ngClass]="notification.selected ? 'selected' : ''">
      <app-notification-item [notification]="notification"></app-notification-item>
    </li>
    <li *ngIf="loading.notification" class="flex center">
      <mat-progress-spinner color="primary" [mode]="'indeterminate'"></mat-progress-spinner>
    </li>
    <li class="info" *ngIf="notificationEnd && notifications.length > 0">
      <p>Keine weiteren Benachrichtigungen vorhanden</p>
    </li>
  </ul>

  <div id="line" *ngIf="notifications && notifications.length > 0">
    <div id="center-line">
      <fa-icon [icon]="icons.arrowDown"></fa-icon>
    </div>
  </div>

  <div #profile id="profile" *ngIf="notifications && notifications.length > 0">
    <div class="flex center" *ngIf="loading.profile">
      <mat-progress-spinner color="primary" [mode]="'indeterminate'"></mat-progress-spinner>
    </div>

    <ng-container *ngIf="(notificationProfile$ | async) as currentNotification">
      <div *ngIf="!loading.profile"
           [style.width.px]="profile.offsetWidth"
           [ngSwitch]="currentNotification.type">
        <app-notification-event-updated *ngSwitchCase="1"
                                        [notification]="currentNotification"></app-notification-event-updated>
        <app-notification-eventattender *ngSwitchCase="2"
                                        [notification]="currentNotification"></app-notification-eventattender>
        <app-notification-friendrequest *ngSwitchCase="7" [notification]="currentNotification"
                                        (remove)="handleRemoveNotification($event)"></app-notification-friendrequest>
        <app-notification-friendship *ngSwitchCase="8"
                                     [notification]="currentNotification"></app-notification-friendship>
      </div>
    </ng-container>
  </div>

  <div *ngIf="notifications && notifications.length === 0" class="flex col s10 text center">
    <h2>Es gibt nichts, worüber wir dich benachrichtigen könnten.</h2>
  </div>
</div>

