import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {ApiService} from './api.service';
import {Settings} from '../../shared/models/settings';
import {Observable, of, ReplaySubject, Subscriber} from 'rxjs';
import {map, share, switchMap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  settings: Settings;

  themeElement: HTMLElement;
  bgImgElement: HTMLElement;

  settingsObservable$: ReplaySubject<Settings> = new ReplaySubject<Settings>(1);

  settings$: Observable<any>;
  update$: Observable<any>;

  updateTheme$: Observable<any>;
  updateImage$: Observable<any>;
  updateColor$: Observable<any>;

  constructor(private api: ApiService) {
    this.themeElement = document.getElementById('color');
    this.bgImgElement = document.getElementById('bg');
  }

  private load(settings: Settings): Observable<Settings> {
    let needToUpdate = false;
    let old: any;
    if (!settings.backgroundColor) {
      old = settings.backgroundColor;
      settings.backgroundColor = this.getBackgroundColorFromStorage();
      needToUpdate = old !== settings.backgroundColor;
    }
    if (!settings.backgroundImage) {
      old = settings.backgroundImage;
      settings.backgroundImage = this.getBackgroundImgFromStorage();
      needToUpdate = old !== settings.backgroundColor;
    }
    if (!settings.language) {
      settings.language = window.navigator.language.split('-')[0];
      needToUpdate = true;
    }

    if (!settings.isDarkMode) {
      old = Boolean(settings.isDarkMode);
      settings.isDarkMode = this.isDarkModeFromStorage();
      needToUpdate = old !== settings.isDarkMode;
    }

    if (needToUpdate) {
      return this.saveSettings(settings);
    }

    return of(settings);
  }

  getSettings() {
    if (this.settings) {
      return of(this.settings);
    } else if (!this.settings$) {
      this.settings$ = this.api.get('settings')
        .pipe(
          map((settings: any) => {
            return new Settings().deserialize(settings);
          }),
          switchMap((settings: Settings) => {
            return this.load(settings);
          }),
          map((settings: Settings) => {
            this.settings = settings;

            return settings;
          }),
          share()
        );
    }

    return this.settings$;
  }

  observeSettings() {
    return this.settingsObservable$
      .pipe(share());
  }

  updateBackgroundImage(image: string): Observable<Settings> {
    if (image.indexOf('00') >= 0) {
      this.settings.backgroundImage = image = 'none';
    } else {
      this.settings.backgroundImage = image;
    }

    return this.updateLookAndFeel(this.updateImage$, 'image', {image: image});
  }

  updateBackgroundTheme(isDarkMode: boolean): Observable<Settings> {
    this.settings.isDarkMode = isDarkMode;

    return this.updateLookAndFeel(this.updateTheme$, 'theme', {isDarkMode: isDarkMode});
  }

  updateBackgroundColor(color: string): Observable<Settings> {
    this.settings.backgroundColor = color;

    return this.updateLookAndFeel(this.updateColor$, 'color', {color: color});
  }

  private updateLookAndFeel(subscriber: any, url: string, payload: any): Observable<Settings> {
    this.saveSettingsInStorage(this.settings);

    if (subscriber instanceof Subscriber) {
      subscriber.unsubscribe();
    }

    subscriber = this.api.put('settings/' + url, payload)
      .pipe(
        map((data: any) => {
          this.settings.deserialize(data);
          this.settingsObservable$.next(this.settings);
          return this.settings;
        })
      );

    return subscriber;
  }

  saveSettings(settings: Settings) {
    this.saveSettingsInStorage(settings);

    if (this.update$ instanceof Subscriber) {
      this.update$.unsubscribe();
    }

    this.update$ = this.api.put('settings', settings)
      .pipe(
        map((data: any) => {
          return new Settings().deserialize(data);
        })
      );

    return this.update$;
  }

  private saveSettingsInStorage(settings: Settings) {
    const c = JSON.stringify(settings);
    localStorage.setItem(environment.storageKeys.settings, c);
  }

  private getBackgroundImgFromStorage() {
    const s = localStorage.getItem(environment.storageKeys.settings);
    if (s) {
      const settings: Settings = JSON.parse(s);

      if (settings.backgroundImage) {
        return settings.backgroundImage;
      }
    }
    return undefined;
  }

  private getBackgroundColorFromStorage() {
    const s = localStorage.getItem(environment.storageKeys.settings);
    if (s) {
      const settings: Settings = JSON.parse(s);

      if (settings.backgroundColor) {
        return settings.backgroundColor;
      }
    }
    return undefined;
  }

  private isDarkModeFromStorage() {
    const s = localStorage.getItem(environment.storageKeys.settings);
    if (s) {
      const settings: Settings = JSON.parse(s);

      if (settings.isDarkMode) {
        return settings.isDarkMode;
      }
    }
    return false;
  }

  clear() {
    this.settings = null;
  }
}
