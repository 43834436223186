<ng-container *ngIf="(activities$ | async) as activities">
  <cdk-virtual-scroll-viewport
    *ngIf="!isMobile"
    itemSize="150"
    (scrolledIndexChange)="handleGetNextActivities($event)"
    class="viewport">
    <li *cdkVirtualFor="let activity of activities" class="fadeInRight animated"
        [routerLink]="['/' + activity.url]">
      <app-timeline-item [activity]="activity"></app-timeline-item>
    </li>
    <li *ngIf="loading">
      <mat-progress-spinner
        class="example-margin"
        color="primary"
        mode="indeterminate">
      </mat-progress-spinner>
    </li>
  </cdk-virtual-scroll-viewport>
  <ul *ngIf="isMobile">
    <li *ngFor="let activity of activities" [routerLink]="['/' + activity.url]">
      <app-timeline-item [activity]="activity"></app-timeline-item>
    </li>
    <li *ngIf="loading">
      <mat-progress-spinner
        class="example-margin"
        color="primary"
        mode="indeterminate">
      </mat-progress-spinner>
    </li>
  </ul>
</ng-container>
