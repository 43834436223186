import {Component, Input} from '@angular/core';
import {Notification} from '../notification';

@Component({
  selector: 'app-notification-event-ended',
  templateUrl: './notification-event-ended.component.html',
  styleUrls: ['./notification-event-ended.component.css']
})
export class NotificationEventEndedComponent {

  @Input() notification: Notification;

  constructor() {
  }

}
