<section id="top" class="fullscreen img flex-center"
         [ngStyle]="{'background-image': 'url(' + images.background + ')'}">
  <div id="form-container" class="animated fadeInUp">
    <div class="logo-container grow" [routerLink]="['/']">
      <div class="logo" [ngStyle]="{'background-image': 'url(' + images.logo + ')'}"></div>
    </div>
    <div class="motifun-box section">
      <p class="section-headline">Registrieren</p>
      <app-register-form></app-register-form>
    </div>
  </div>
</section>
