<div class="message {{message.type}}">
  <div class="content {{message.type}}">
    <p>
      {{message.text}}
    </p>
  </div>
  <div class="info {{message.type}}">
    <span>{{message.getTime()}}</span>
  </div>
</div>
