<div class="row flex-col flex-center motifun-box section">
  <p class="section-headline">Neuer Kommentar von {{notification.sender.firstName}}</p>
  <app-event-comment *ngIf="notification.comment; else error"
                     [comment]="notification.comment"
                     [eventCreatorId]="notification.user.id"></app-event-comment>
  <ng-template #error>
    <p>Fehler beim Laden des Kommentars.</p>
  </ng-template>
</div>

<app-notification-event-info [event]="notification.event"></app-notification-event-info>
