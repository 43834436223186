import {Component, Inject} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {GeoService} from '../../../core/services/geo.service';
import {User} from '../../../shared/models/user';
import {Location} from '../../../shared/models/location';
import {UserService} from '../../../core/services/user.service';
import {IMapDialog} from '../../../shared/interface/IMapDialog';

@Component({
  selector: 'app-map-form-dialog',
  templateUrl: './event-form-map-dialog.component.html',
  styleUrls: ['./event-form-map-dialog.component.css']
})
export class EventFormMapDialogComponent {

  error: string;
  formGroup: UntypedFormGroup;
  user: User;

  private place: Location;

  constructor(private geo: GeoService,
              private userService: UserService,
              private dialogRef: MatDialogRef<EventFormMapDialogComponent>,
              @Inject(MAT_DIALOG_DATA) private passedData: IMapDialog) {
    this.place = passedData.location;
    this.user = passedData.user;
    this.formGroup = passedData.formGroup;

    console.log(this.formGroup);
  }

  submitPlace() {
    if (this.formGroup.valid) {
      this.dialogRef.close(this.place);
    }
  }

  onLocationSet(location: Location) {
    this.place = location;
    this.formGroup.patchValue(location.getIEventWhere());
  }
}
