import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Location} from '../../shared/models/location';
import {catchError, map, share, switchMap} from 'rxjs/operators';
import {Observable, of, Subscription} from 'rxjs';
import {environment} from '../../../environments/environment';
import {GeolocationType} from '../../shared/enums/geolocation-type';

@Injectable({providedIn: 'root'})
export class GeoService {

  observables: { [key: string]: Observable<any> } = {
    geo: null,
    reverse: null
  };

  constructor(private api: ApiService) {
  }

  locateUser(language?: string) {
    return this.locateUserByGeo()
      .pipe(
        switchMap((location: Location) => {
          console.log(location);
          if (!location) {
            return this.getLocationByIp();
          } else {
            return this.getAddressOfCoordinates(location, GeolocationType.NOMINATIM, language, 1);
          }
        }),
        share()
      );
  }

  getLocationByIp(): Observable<Location> {
    return this.getLocationByIpByIpInfo()
      .pipe(
        switchMap((location: Location) => {
          if (!location) {
            return this.getLocationByIpFreeGeo();
          }

          return of(location);
        }),
        catchError((err, caught) => {
          console.log(err);
          console.log(caught);

          return this.getLocationByIpFreeGeo();
        })
      );
  }

  getLocationByIpByIpInfo(): Observable<Location> {
    return this.api.getOther<any>(`https://ipinfo.io?token=${environment.ipInfo}`)
      .pipe(
        map((data: any) => {
          return new Location().parseIpInfo(data);
        })
      );
  }

  getLocationByIpFreeGeo(): Observable<Location> {
    return this.api.getOther<any>(`https://freegeoip.app/json/`)
      .pipe(
        map((data: any) => {
          return new Location().parseGeoIp(data);
        })
      );
  }

  locateUserByGeo() {
    return new Observable<Location>(observer => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          const location = new Location().parseGeo(position);

          return observer.next(location);
        }, () => {
          return observer.next(null);
        });
      } else {
        return observer.next(null);
      }
    });
  }

  findPlace(value: string, url: string) {
    if (value.trim().length <= 0) {
      return of(null);
    }

    if (this.observables.geo instanceof Subscription) {
      this.observables.geo.unsubscribe();
    }
    this.observables.geo = this.api.getOther(url);

    return this.observables.geo;
  }

  getAddressOfCoordinates(location: Location, type?: GeolocationType, language?: string, limit?: number) {
    if (!language) {
      language = 'de';
    }
    if (!limit) {
      limit = 1;
    }
    let url: string;
    switch (type) {
      case GeolocationType.GOOGLE:
        url = `https://maps.googleapis.com/maps/api/geocode/json?key=${environment.googleAPIKey}&latlng=${location.lat},${location.lng}`;
        break;
      case GeolocationType.NOMINATIM:
        url = `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${location.lat}&lon=${location.lng}&accept-language=${language}`;
        break;
      case GeolocationType.KOMOOT:
        url = `https://photon.komoot.io/reverse?lon=${location.lng}&lat=${location.lat}&limit=1&lang=${language}`;
        break;
      case GeolocationType.TILEHOSTING:
        url = `https://geocoder.tilehosting.com/r/${location.lng}/${location.lat}.js?key=${environment.tileHostingAPIKey}`;
        break;
      case GeolocationType.LOCATION_IQ:
        url = `https://eu1.locationiq.com/v1/reverse.php?format=json&key=${environment.locationIqAPIKey}&lat=${location.lat}&lon=${location.lng}`;
        break;
      default:
        url = `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${location.lat}&lon=${location.lng}&accept-language=${language}`;
    }
    return this.api.getOther<any>(url)
      .pipe(
        map(data => {
          let loc: Location;
          switch (type) {
            case GeolocationType.GOOGLE:
              loc = new Location().parseGoogle(data);
              break;
            case GeolocationType.NOMINATIM:
              loc = new Location().parseNominatim(data);
              break;
            case GeolocationType.KOMOOT:
              loc = new Location().parseKomoot(data);
              break;
            case GeolocationType.LOCATION_IQ:
              loc = new Location().parseLocationIQ(data);
              break;
            case GeolocationType.TILEHOSTING:
              loc = new Location().parseTilehosting(data);
              break;
            default:
              return data;
          }

          return loc;
        })
      );
  }

  getSearchPlace(query: string, type?: GeolocationType, onlyCity = false, language?: string, limit?: number) {
    if (!language) {
      language = 'de';
    }
    if (!limit) {
      limit = 5;
    }
    let url: string;
    switch (type) {
      case GeolocationType.GOOGLE:
        // photos,formatted_address,name,rating,opening_hours,geometry
        const fields = 'formatted_address,name';
        url = `https://maps.googleapis.com/maps/api/place/autocomplete/json?language=${language}&input=${query}&inputtype=textquery&fields=${fields}&key=${environment.googleAPIKey}`;
        break;
      case GeolocationType.NOMINATIM:
        url = `https://nominatim.openstreetmap.org/search/?format=jsonv2&addressdetails=1&q=${query}&limit=${limit}&accept-language=${language}`;
        break;
      case GeolocationType.KOMOOT:
        let onlyCityString = '';
        if (onlyCity) {
          onlyCityString = '&osm_tag=place:city&osm_tag=place:town&osm_tag=place:village';
        }
        url = `https://photon.komoot.io/api/?q=${query}&limit=${limit}&lang=${language}${onlyCityString}`;
        break;
      case GeolocationType.TILEHOSTING:
        url = `https://geocoder.tilehosting.com/q/${query}.js?key=${environment.tileHostingAPIKey}`;
        break;
      case GeolocationType.LOCATION_IQ:
        url = `https://eu1.locationiq.com/v1/search.php?format=json&key=${environment.locationIqAPIKey}&q=${query}`;
        break;
      default:
        url = `https://photon.komoot.de/api/?q=${query}&limit=${limit}&lang=${language}`;
    }

    return this.api.getOther<any>(url)
      .pipe(
        map((data: any) => {
          const places: Location[] = [];
          switch (type) {
            case GeolocationType.GOOGLE:
              // TODO:
              break;
            case GeolocationType.NOMINATIM:
              for (const l of data) {
                places.push(new Location().parseNominatim(l));
              }
              break;
            case GeolocationType.KOMOOT:
              if (!data.features) {
                return null;
              }

              for (const p of data.features) {
                places.push(new Location().parseKomoot(p));
              }
              break;
            case GeolocationType.TILEHOSTING:
              // TODO:
              break;
            case GeolocationType.LOCATION_IQ:
              if (!data.features) {
                return null;
              }

              for (const p of data.features) {
                places.push(new Location().parseLocationIQ(p));
              }
              break;
            default:
              if (!data.features) {
                return null;
              }

              for (const p of data.features) {
                places.push(new Location().parseKomoot(p));
              }
          }
          return places;
        })
      );
  }
}
