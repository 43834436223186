import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Alert} from '../../../shared/models/alert';
import {Subscription, timer} from 'rxjs';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {faTimes} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css'],
  animations: [
    trigger('toast', [
      state('inactive', style({opacity: 0})),
      state('active', style({opacity: 1})),
      transition('* => *', [
        animate('0.5s')
      ])
    ])
  ]
})
export class AlertComponent implements AfterViewInit, OnDestroy {

  icons = {
    close: faTimes
  };

  close = false;

  private timeout$: Subscription;

  @Input() alert: Alert;
  @Output() remove: EventEmitter<Alert> = new EventEmitter();

  constructor() {
  }

  ngAfterViewInit(): void {
    this.timeout$ = timer(5 * 1000)
      .subscribe(() => {
        this.onClose();
      });
  }

  ngOnDestroy(): void {
    this.timeout$.unsubscribe();
  }

  onClose() {
    this.close = true;
    timer(500)
      .subscribe(() => {
        return this.remove.emit(this.alert);
      });
  }
}
