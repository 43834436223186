import {Component, OnInit} from '@angular/core';
import {AuthService} from './core/services/auth.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  isAuthenticated$: Observable<boolean>;

  constructor(private auth: AuthService) {
  }

  ngOnInit(): void {
    this.isAuthenticated$ = this.auth.isAuthenticated();
  }
}
