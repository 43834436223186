<div class="motifun-box flex-col">
  <div class="flex col" style="height: 320px;">
    <div class="col-10 fill-remaining-space flex-col"
         [ngStyle]="{'background-image': 'url(' + event.getImgUrl() + '), url('+ event.getFallbackUrl() +')'}"
         style="background-size: cover;">
      <div class="flex-row fill-remaining-space" style="margin-top: 15px;">
        <div style="display: flex; justify-content: center; margin-left: 20px;">
          <div class="img circle xsmall pointer"
               [ngStyle]="{'background-image': 'url(' + event.creator.getLowImgUrl() + ')'}"
               style="border: 1px solid #FFFFFF;"
               [routerLink]="['/user', event.creator.id]">
          </div>
        </div>
        <div style="display: flex; justify-content: flex-end; flex: 1;">
          <div style="align-self: baseline; background: rgba(0, 0, 0, 0.8); color: #FFFFFF; padding: 5px 15px;">
            {{event.sport.name}}
          </div>
        </div>
      </div>
      <div class="flex-row"
           style="height: 70px; padding: 10px 0; display: flex; justify-content: center; text-align: center; background: rgba(0, 0, 0, 0.8); color: #FFFFFF;">
        <div class="col-2">
          <fa-icon [icon]="icons.calendar"></fa-icon>
          <br/>{{event.startDate}}
        </div>
        <div class="col-2">
          <fa-icon [icon]="icons.clock"></fa-icon>
          <br/>{{event.startTime}}
        </div>
        <div class="col-2">
          <fa-icon [icon]="icons.users"></fa-icon>
          <br/>{{event.attendeesCount}}{{event.maxAttendees ? '/' + event.maxAttendees : ''}}
        </div>
        <div class="col-2">
          <fa-icon [icon]="icons.comments"></fa-icon>
          <br/>{{event.commentsCount}}
        </div>
        <div class="col-2">
          <fa-icon [icon]="icons.skill"></fa-icon>
          <br/>{{event.skill}}/5
        </div>
      </div>
    </div>
  </div>
  <div class="overflow-y" style="padding: 15px 0.8rem;">
    <div class="col-10">
      <span style="font-size: 1.25em; line-height: 0.75em; display: block;">{{event.title}}</span>
      <span style="font-size: 0.75em;">
        <fa-icon [icon]="icons.location"></fa-icon>
        {{event.getDistanceString()}}{{event.city}}
      </span>
      <p>{{event.description}}</p>
    </div>
  </div>
  <button mat-flat-button color="primary" [routerLink]="['/event', event.id]">
    <fa-icon [icon]="icons.link"></fa-icon>
    <span>Zum Event</span>
  </button>
</div>

<div class="motifun-box section">
  <p class="section-headline">Dein Status</p>

  <div class="flex-row">
    <div class="col-5 grow center" (click)="joinEvent()" [ngClass]="event.isAttender ? 'motifun text' : ''">
      <span style="display: block; padding: 0 0 10px 0; font-size: 2em;">
        <fa-icon [icon]="icons.join"></fa-icon>
      </span>
      <span style="display: block; font-size: 1.25em;">Teilnehmen</span>
    </div>
    <div class="col-5 grow center" (click)="observeEvent()" [ngClass]="event.isObserver ? 'motifun text' : ''">
      <span style="display: block; padding: 0 0 10px 0; font-size: 2em;">
        <fa-icon [icon]="icons.observe"></fa-icon>
      </span>
      <span style="display: block; font-size: 1.25em;">Interessiert</span>
    </div>
  </div>
</div>
