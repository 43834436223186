<form [formGroup]="eventGroup" class="flex col s10">
  <mat-vertical-stepper #stepper [linear]="stepperConfig.linear">
    <mat-step [formGroupName]="'eventInfoGroup'" [stepControl]="eventGroup.controls.eventInfoGroup">
      <ng-template matStepLabel>Was & Wann?</ng-template>

      <app-event-form-what [formGroup]="eventGroup"
                           [user]="user"></app-event-form-what>

      <div>
        <button color="primary" mat-flat-button matStepperNext>Nächster Schritt</button>
      </div>
    </mat-step>
    <mat-step [formGroupName]="'eventPlaceGroup'" [stepControl]="eventGroup.controls.eventPlaceGroup">
      <ng-template matStepLabel>Wo?</ng-template>

      <app-event-form-where [formGroup]="eventGroup"
                            [mobileMode]="true"
                            [user]="user"></app-event-form-where>

      <div>
        <button mat-flat-button matStepperPrevious type="button">Zurück</button>
        <button color="primary" mat-flat-button matStepperNext type="button">Nächster Schritt</button>
      </div>
    </mat-step>
    <mat-step [formGroupName]="'eventTextGroup'" [stepControl]="eventGroup.controls.eventTextGroup">
      <ng-template matStepLabel>Wie?</ng-template>

      <app-event-form-how [formGroup]="eventGroup"></app-event-form-how>

      <div class="row">
        <div class="col-12 center">
          <button (click)="submitEvent()" [disabled]="eventGroup.invalid" class="fill-x" color="primary"
                  mat-raised-button
                  type="submit">Event
            erstellen
          </button>
        </div>
      </div>
      <div>
        <button mat-flat-button matStepperPrevious>Zurück</button>
        <button (click)="stepper.reset()" mat-flat-button>Zurücksetzen</button>
      </div>
    </mat-step>
  </mat-vertical-stepper>
</form>
