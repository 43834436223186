<div *ngIf="useAutocomplete" class="flex row">
  <div class="col s10">
    <app-google-maps-autocomplete
      (location)="onPlaceChanged($event)"
      [formGroup]="formGroup"
      [inputError]="inputError"
      [user]="user"></app-google-maps-autocomplete>
  </div>
</div>
<div class="flex-row fill-remaining-space map-wrapper">
  <div class="lh">
    <button (click)="setCurrentLocation()" color="white" mat-mini-fab type="button">
      <mat-icon color="orange">location_on</mat-icon>
    </button>
  </div>
  <div #mapRef class="fill-remaining-space" id="map"></div>
</div>
