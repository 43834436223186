<div id="ntfctns_rght_wrp_fr">
  <div class="cnt_rght_title_pic_fr_y" [ngStyle]="{'background-image': 'url(' + notification?.getImgUrl() + ')'}">
    <div class="lft_infos_fr">
      <div class="lft_infos_top_fr">

      </div>
      <div class="lft_infos_mid_fr">
        <div id="fr_pic" [ngStyle]="{'background-image': 'url(' + notification?.getImgUrl() + ')'}"></div>
      </div>
      <div class="lft_infos_bottom_fr">
        <p>{{notification.sender.getFullName()}}, {{notification.sender.age}}</p>
        <p id="fr_ort">{{notification.sender.city}}</p>
      </div>

    </div>
  </div>

  <div id="cnt_rght_fr">
    <p> {{notification.sender.firstName}} hat deine Freundschaftsanfrage angenommen</p>
  </div>


  <div id="fr_y_mail">
    <div class="fr_a_y">
      <i class="material-icons">done</i>
      <p>Nachricht senden</p>
    </div>
  </div>
  <div id="fr_y_invite">
    <div class="fr_a_y">
      <i class="material-icons">clear</i>
      <p>Einladung schicken</p>
    </div>
  </div>
  <div id="fr_y_profile">
    <div class="fr_a_y">
      <i class="material-icons">clear</i>
      <p>Zum Profil</p>
    </div>
  </div>
</div>
