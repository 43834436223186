import {Component, Input} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'app-event-form-how',
  templateUrl: './event-form-how.component.html',
  styleUrls: ['./event-form-how.component.scss']
})
export class EventFormHowComponent {

  errors = {
    title: 'Es muss ein Titel mit max. 50 Zeichen vergeben werden!',
    description: 'Es muss eine Beschreibung mit max. 400 Zeichen vergeben werden!'
  };

  @Input() formGroup: UntypedFormGroup;

  constructor() {
  }

  onTitleChange($event: any): void {
    this.formGroup.patchValue({
      title: $event.target.value
    });
  }

  onDescriptionChange($event: any): void {
    this.formGroup.patchValue({
      description: $event.target.value
    });
  }
}
