import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DeleteAccountComponent} from './delete-account/delete-account.component';
import {DeleteRoutingModule} from './delete-routing.module';
import {SharedModule} from '../../shared/shared.module';
import {DeleteComponent} from './delete/delete.component';


@NgModule({
  declarations: [
    DeleteComponent,
    DeleteAccountComponent
  ],
  imports: [
    CommonModule,
    DeleteRoutingModule,
    SharedModule
  ]
})
export class DeleteModule {
}
