<div [formGroup]="formGroup" class="flex col s10">
  <div class="title-box">
    <h3>Wo</h3>
    <hr>
  </div>

  <ng-container *ngIf="!mobileMode; else dialog">
    <app-google-maps (location)="onLocation($event)"
                     [formGroup]="formGroup"
                     [useAutocomplete]="true"
                     [user]="user"></app-google-maps>
  </ng-container>
  <ng-template #dialog>
    <div class="flex col s10">
      <div class="row">
        <mat-form-field class="col s10">
          <input formControlName="address" matInput placeholder="Adresse" readonly required type="text">
        </mat-form-field>
      </div>
      <div class="row">
        <button (click)="onChoseLocation()" [color]="'primary'" mat-flat-button>
          <fa-icon [icon]="icons.location" matPrefix></fa-icon>
          Ort auswählen
        </button>
      </div>
    </div>
  </ng-template>
</div>
