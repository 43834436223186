import {User} from './user';
import {Deserializable} from './deserializable.model';
import {DateTime} from 'luxon';

export class Comment implements Deserializable {

  id: number;
  text: string;
  time: DateTime;
  user: User;

  constructor() {
  }

  deserialize(input: any): this {
    Object.assign(this as any, input);
    this.time = DateTime.fromISO(input.timestamp);
    this.user = new User().deserialize(input.user);
    return this;
  }

  getTime() {
    const diff = new Date().getTime() - this.time.toJSDate().getTime();
    const limit = 1000 * 60 * 60 * 3;
    if (diff <= limit) {
      return this.time.diffNow().milliseconds;
    } else {
      return this.time.toFormat('LLL');
    }
  }

  clone() {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this as any);
  }
}
