import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {faAngleUp, faEnvelope, faKey, faUser} from '@fortawesome/free-solid-svg-icons';
import {faFacebookF, faInstagram, faTwitter} from '@fortawesome/free-brands-svg-icons';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-start-signed-out',
  templateUrl: './start-signed-out.component.html',
  styleUrls: ['./start-signed-out.component.scss']
})
export class StartSignedOutComponent {

  images = {
    howTo: {
      register: environment.imageHost + 'preview/howto1.png',
      search: environment.imageHost + 'preview/howto2.png',
      sport: environment.imageHost + 'preview/howto3.png',
    },
    what: {
      beach: environment.imageHost + 'landing/beachvb.jpg',
      stretching: environment.imageHost + 'landing/stretching.jpg',
      swimming: environment.imageHost + 'landing/swimming.jpg',
    },
    backgrounds: {
      top: environment.imageHost + 'landing/bg_community.jpg',
    },
    logos: {
      top: environment.imageHost + 'logos/logo_w_600x600.png'
    },
    versions: {
      desktop: environment.imageHost + 'preview/desktop.png',
      mobile: environment.imageHost + 'preview/mobile.png'
    },
    artworks: {
      dumbbell: environment.imageHost + 'home/dumbbell.jpg',
      football: environment.imageHost + 'home/football.jpg',
      drink: environment.imageHost + 'home/drink.png',
      basketball: environment.imageHost + 'home/bb.png',
      win: environment.imageHost + 'home/win.png',
      tennis: environment.imageHost + 'home/tennis.png',
      skate: environment.imageHost + 'home/skate.jpg'
    }
  };

  isProduction: boolean = environment.production;

  icons = {
    name: faUser,
    email: faEnvelope,
    password: faKey,
    facebook: faFacebookF,
    instagram: faInstagram,
    twitter: faTwitter,
    arrowUp: faAngleUp
  };

  contact = {
    name: '',
    email: '',
    text: ''
  };

  constructor() {
  }

  submitContact() {
    // TODO:
  }

  handlePreLaunchClick() {
    // TODO:
    console.log('not implemented yet');
  }
}
