import {AlertType} from '../enums/alert-type';

export class Alert {

  id: number;
  title: string;
  message: string;
  type: AlertType;

  constructor(title: string, message: string, type: AlertType) {
    this.title = title;
    this.message = message;
    this.type = type;
  }

}
