import {Location} from './location';
import {Activity} from './activity';
import {Deserializable} from './deserializable.model';
import {environment} from '../../../environments/environment';
import {Settings} from './settings';
import {Chat} from './chat';
import {Sport} from './sport';
import {UserEditPayload} from '../types/user-edit-payload';
import {DateTime} from 'luxon';

export class User implements Deserializable {

  id: string;
  firstName: string;
  lastName: string;
  gender: 'm' | 'f' | undefined;
  hasImg: boolean;
  image: string;
  city: string;
  country: string;
  lat: number;
  lng: number;

  birth: string;
  dayOfBirth: DateTime;
  age: number;
  status: string;
  aboutMe: string;

  target: string;

  regDate: DateTime;
  lastSignIn: string;
  profileChanged: any;

  createdEventsCount: number;
  participatedEventsCount: number;

  currentLocation: Location;
  userRelation: number;

  activities: Activity[] = [];

  settings: Settings;

  chats: Chat[];

  sports: Sport[];

  isSignedIn: boolean;

  deserialize(input: any): this {
    if (!input) {
      return null;
    }

    Object.assign(this as any, input);
    this.userRelation = input.userRelation || -1;

    this.currentLocation = new Location().deserialize(input);

    this.createdEventsCount = this.createdEventsCount ? this.createdEventsCount : 0;
    this.participatedEventsCount = this.participatedEventsCount ? this.participatedEventsCount : 0;

    this.profileChanged = input.userChanged;

    if (this.birth) {
      this.dayOfBirth = DateTime.fromFormat(this.birth, 'yyyy-MM-dd');
      this.calcAge();
    }

    this.gender = this.setGender(input.gender);

    this.setImg();

    this.activities = [];

    this.settings = new Settings();

    return this;
  }

  private setImg() {
    this.hasImg = this.image != null;
    if (!this.image) {
      switch (this.gender) {
        case 'm':
          this.image = 'general/male.png';
          break;
        case 'f':
          this.image = 'general/female.png';
          break;
        default:
          this.image = 'general/unknown.png';
      }
    }
  }

  private getImgUrl() {
    if (this.image.indexOf('https://') >= 0) {
      return this.image;
    }
    return environment.userImgUrl + this.image;
  }

  /**
   * returns img-url for high quality img
   */
  getHighImgUrl() {
    if (this.image.indexOf('.') >= 0) {
      return this.getImgUrl();
    }
    return environment.userImgUrl + this.image + '_high.jpg';
  }

  /**
   * returns img-url for low quality img
   */
  getLowImgUrl() {
    if (this.image.indexOf('.') >= 0) {
      return this.getImgUrl();
    }
    return environment.userImgUrl + this.image + '_low.jpg';
  }

  /**
   * returns img-url for med quality img
   */
  getMedImgUrl() {
    if (this.image.indexOf('.') >= 0) {
      return this.getImgUrl();
    }
    return environment.userImgUrl + this.image + '_med.jpg';
  }

  private setGender(gender: string): 'm' | 'f' | undefined {
    if (gender === 'm' || 'M') {
      return 'm';
    } else if (gender === 'f' || 'F') {
      return 'f';
    } else {
      return undefined;
    }
  }

  getLocationString() {
    if (this.city && this.country) {
      return this.city + ', ' + this.country;
    } else if (this.city) {
      return this.city;
    } else if (this.country) {
      return this.country;
    } else {
      return '';
    }
  }

  calcAge() {
    this.age = Math.abs(this.dayOfBirth.diffNow('year').years);
  }

  getFullName() {
    return this.firstName + ' ' + this.lastName;
  }

  updateImage(img: string) {
    if (!img) {
      this.setImg();
    } else {
      this.image = img;
      this.hasImg = true;
    }
  }

  getLastSignIn() {
    if (!this.lastSignIn) {
      return '-';
    }

    return DateTime.fromISO(this.lastSignIn).diffNow().days;
  }

  getEditPayload(): UserEditPayload {
    const favoriteSports: Sport[] = [];
    return {
      aboutMe: this.aboutMe,
      city: this.city,
      country: this.country,
      dayOfBirth: this.dayOfBirth.toISO(),
      favoritesSports: favoriteSports
    };
  }

  setSettings(settings: Settings) {
    this.settings = settings;
  }

  clone() {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this as any);
  }

  cloneEditable() {
    const user = new User();
    user.lat = this.lat;
    user.lng = this.lng;
    user.city = this.city;
    user.country = this.country;
    user.birth = this.birth;
    user.aboutMe = this.aboutMe;
    user.target = this.target;

    return user;
  }
}
