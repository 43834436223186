<div class="motifun-box flex-center">
  <span class="title">Profilbild ändern</span>
</div>
<mat-dialog-content class="flex-col flex-center">
  <div class="flex-col flex-center">
    <div class="cropper-container">
      <img #cropperImg id="image" [src]="user?.getHighImgUrl()">
    </div>
    <div class="menu-bar">
      <a mat-icon-button (click)="cropper.reset()">
        <fa-icon [icon]="icons.faRedo"></fa-icon>
      </a>
      <a mat-icon-button (click)="cropper.zoom(0.1)">
        <fa-icon [icon]="icons.zoomPlus"></fa-icon>
      </a>
      <a mat-icon-button (click)="cropper.zoom(-0.1)">
        <fa-icon [icon]="icons.zoomMinus"></fa-icon>
      </a>
      <a mat-icon-button (click)="cropper.move(0, -1)">
        <fa-icon [icon]="icons.angleUp"></fa-icon>
      </a>
      <a mat-icon-button (click)="cropper.move(0, 1)">
        <fa-icon [icon]="icons.angleDown"></fa-icon>
      </a>
      <a mat-icon-button (click)="cropper.move(-1, 0)">
        <fa-icon [icon]="icons.angleLeft"></fa-icon>
      </a>
      <a mat-icon-button (click)="cropper.move(1, 0)">
        <fa-icon [icon]="icons.angleRight"></fa-icon>
      </a>
      <a mat-icon-button>
        <label for="inputImage" title="Foto auswählen">
          <input type="file" class="sr-only" #img id="inputImage" name="file" accept="image/*"
                 (change)="choseImage(img)">
          <fa-icon [icon]="icons.upload"></fa-icon>
        </label>
      </a>
    </div>
  </div>
  <div class="previews flex-row flex-center">
    <div class="preview large"></div>
    <div class="preview mid"></div>
    <div class="preview small"></div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button color="primary" (click)="uploadImage()">Hochladen</button>
  <button mat-button id="remove" (click)="deleteImage()">Bild löschen</button>
  <button mat-button mat-dialog-close>Abbrechen</button>
</mat-dialog-actions>
