import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {ApiService} from '../../../core/services/api.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Event} from '../../../shared/models/event';
import {User} from '../../../shared/models/user';
import {Comment} from '../../../shared/models/comment';
import {DataStoreService} from '../../../core/services/data-store.service';
import {GeoService} from '../../../core/services/geo.service';
import {Location} from '../../../shared/models/location';
import {
  faBookmark,
  faCalendar,
  faCheckCircle,
  faChevronLeft,
  faChevronRight,
  faClock,
  faCommentAlt,
  faDumbbell,
  faEdit,
  faHeartbeat,
  faLocationArrow,
  faMapMarkerAlt,
  faSun,
  faUsers
} from '@fortawesome/free-solid-svg-icons';
import {GeolocationType} from '../../../shared/enums/geolocation-type';
import {distinct, map, mergeMap, shareReplay, switchMap, tap, throttleTime} from 'rxjs/operators';
import {BehaviorSubject, merge, Observable, Subject, Subscriber, Subscription} from 'rxjs';
import {AlertService} from '../../../core/services/alert.service';
import {Alert} from '../../../shared/models/alert';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {ResizeService} from '../../../core/services/resize.service';
import {SocketIOService} from '../../../core/services/socketio.service';
import {EventService} from '../../../core/services/event.service';
import {MatDialog} from '@angular/material/dialog';
import {EventFormMapDialogComponent} from '../event-form-map-dialog/event-form-map-dialog.component';
import {EventFormDialogComponent} from '../event-form-dialog/event-form-dialog.component';
import {AlertType} from '../../../shared/enums/alert-type';
import {UserService} from '../../../core/services/user.service';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.css']
})
export class EventComponent implements OnInit {

  private event: Event;
  user: User;

  icons = {
    sports: faBookmark,
    location: faMapMarkerAlt,
    distance: faLocationArrow,
    attendees: faUsers,
    edit: faEdit,
    weather: faSun,
    calendar: faCalendar,
    clock: faClock,
    users: faUsers,
    comments: faCommentAlt,
    skill: faDumbbell,
    join: faCheckCircle,
    observe: faHeartbeat,
    arrowLeft: faChevronLeft,
    arrowRight: faChevronRight
  };

  editEvent: Event = new Event();

  edit = {
    about: false,
    info: false,
    location: false
  };

  editAbout = new UntypedFormGroup({
    title: new UntypedFormControl('', [
      Validators.required,
      Validators.maxLength(256)
    ]),
    description: new UntypedFormControl('', [
        Validators.required,
        Validators.maxLength(400)
      ]
    )
  });

  desktop: boolean = window.innerWidth >= 1250;

  mapUrl: string;

  comment = '';

  end = {
    comments: false,
    attendants: false
  };

  loading = {
    comments: false,
    attendants: false,
    event: false,
    comment: false
  };

  subscriptions: { [key: string]: Subscription } = {
    comment: null,
    editAbout: null,
    editInfo: null
  };

  event$: Observable<Event>;
  user$: Observable<User>;
  distance$: Observable<string>;

  eventAddress$: Observable<any>;

  comments$: Observable<any>;
  commentsLoading$: Observable<Comment[]>;
  commentReceiving$: Observable<Comment[]>;
  commentNew$: Subject<Comment> = new Subject();
  commentsScroll$: Subject<number> = new Subject();
  commentsResize$: Observable<any>;
  commentsInitial$: BehaviorSubject<number> = new BehaviorSubject(-1);

  attendants$: Observable<any>;
  attendantsLoading$: Observable<User[]>;
  attendantsReceiving$: Observable<User[]>;
  attenderNew$: Subject<User> = new Subject();
  attendantsScroll$: Subject<number> = new Subject();
  attendantsResize$: Observable<any>;
  attendantsInitial$: BehaviorSubject<number> = new BehaviorSubject(-1);

  updateEvent$ = new Subject<Event>();

  private attendantsListOffset = 0;

  @ViewChild('commentsList') commentsList: CdkVirtualScrollViewport;
  @ViewChild('attendantsList') attendantsList: CdkVirtualScrollViewport;

  constructor(private activatedRoute: ActivatedRoute,
              private alertService: AlertService,
              private api: ApiService,
              private data: DataStoreService,
              private dialog: MatDialog,
              private eventService: EventService,
              private geo: GeoService,
              private io: SocketIOService,
              private resize: ResizeService,
              private router: Router,
              private userService: UserService) {
  }

  ngOnInit() {
    this.user$ = this.userService.getUser();

    this.event$ = this.user$
      .pipe(
        switchMap((user: User) => {
          this.user = user;

          return this.activatedRoute.params;
        }),
        map((params: Params) => {
          const eventId = params.id;
          if (!eventId) {
            // TODO: redirect start, etc
            this.alertService.appendAlert(
              new Alert(
                'Event nicht gefunden',
                'Das angefragte Event existiert nicht',
                AlertType.ERROR
              )
            );

            return this.router.navigate(['/not-found']);
          }

          return eventId;
        }),
        switchMap((id: number) => {
          return merge(this.eventService.getEvent(id), this.updateEvent$);
        }),
        map((event: Event) => {
          if (event instanceof Event) {
            this.editEvent = event.clone();
            event.isCreator = event.creator.id === this.user.id;

            this.event = event;

            this.editAbout.patchValue({
              title: event.title,
              description: event.description
            });

            return event;
          } else {
            this.router.navigate(['/not-found', '?event']);
          }

          return null;
        }),
        shareReplay(1)
      );

    this.distance$ = this.event$
      .pipe(
        switchMap((event: Event) => {
          return this.geo.locateUser(this.user.settings.language);
        }),
        map((location: Location) => {
          const distance = this.event.location.getDistanceInKm(location);
          this.event.distance = distance;

          return this.event.getDistanceString();
        })
      );

    this.eventAddress$ = this.event$
      .pipe(
        switchMap(() => {
          return this.geo.getAddressOfCoordinates(this.event.location, GeolocationType.KOMOOT);
        }),
        map((location: Location) => {
          this.event.location = location;

          return location;
        })
      );

    this.commentsResize$ = this.resize.onScreenResizeY(document.getElementsByTagName('main')[0], 160, 5);
    this.commentsLoading$ = merge(this.commentsInitial$, this.commentsResize$, this.commentsScroll$)
      .pipe(
        tap(() => this.loading.comments = true),
        distinct(),
        throttleTime(300),
        mergeMap((payload: any) => {
          return this.eventService.getComments(this.event);
        }),
        map((event: Event) => {
          this.loading.comments = false;

          return event.comments;
        })
      );

    // TODO: add socket io
    this.commentReceiving$ = this.commentNew$
      .pipe(
        map((comment: Comment) => {
          this.event.appendComment(comment);
          // TODO: find better way..
          return this.event.comments.concat([]);
        })
      );

    this.comments$ = this.event$
      .pipe(
        switchMap(() => {
          return merge(this.commentReceiving$, this.commentsLoading$);
        })
      );

    this.attendantsResize$ = this.resize.onScreenResizeX(document.getElementsByTagName('main')[0], 160, 5);

    this.attendantsLoading$ = merge(this.attendantsInitial$, this.attendantsResize$, this.attendantsScroll$)
      .pipe(
        tap(() => this.loading.attendants = true),
        distinct(),
        throttleTime(300),
        mergeMap((payload: any) => {
          return this.eventService.getAttendants(this.event);
        }),
        map((event: Event) => {
          this.loading.attendants = false;

          return event.attendees;
        })
      );

    this.attendantsReceiving$ = this.attenderNew$
      .pipe(
        map((user: User) => {
          if (user) {
            this.event.prependAttender(user);
          }

          return this.event.attendees.concat([]);
        })
      );

    this.attendants$ = this.event$
      .pipe(
        switchMap(() => {
          return merge(this.attendantsLoading$, this.attendantsReceiving$);
        })
      );
  }

  createComment() {
    if (!this.event) {
      return;
    } else if (this.loading.comment) {
      return;
    }

    this.comment = this.comment.trim();
    if (this.comment.length === 0) {
      // TODO: alert no text
      return;
    }

    const payload = {
      text: this.comment
    };

    if (this.subscriptions.comment instanceof Subscriber) {
      this.subscriptions.comment.unsubscribe();
    }

    // TODO: store observable to restrict double send
    this.loading.comment = true;
    this.subscriptions.comment = this.api.post<any>('event/' + this.event.id + '/comment', payload)
      .pipe(
        tap(() => this.loading.comment = false),
      )
      .subscribe((data: any) => {
        const comment = new Comment().deserialize(data);
        this.comment = '';

        this.commentNew$.next(comment);
      });
  }

  joinEvent() {
    if (!this.event) {
      // TODO: user error
      console.log('event is not available');
      return;
    }
    this.api.get<any>('event/' + this.event.id + '/join')
      .subscribe(res => {
        if (res.joined === true || res.joined === 1) {
          this.event.appendAttender(this.user);
          this.event.isAttender = true;

          this.attenderNew$.next(this.user);
        } else {
          this.event.removeAttender(this.user);
          this.event.isAttender = false;

          this.attenderNew$.next(null);
        }

        if (this.event.isObserver) {
          this.event.isObserver = false;
        }
      });
  }

  observeEvent() {
    if (!this.event) {
      // TODO: user error
      console.log('event is not available');
      return;
    }
    this.api.get<any>('event/' + this.event.id + '/observe')
      .subscribe(res => {
        this.event.isObserver = res.observed === true || res.observed === 1;
        if (this.event.isAttender) {
          this.event.removeAttender(this.user);
          this.event.isAttender = false;
          this.attenderNew$.next(null);
        }
      });
  }

  updateEvent() {
    if (!this.event) {
      return;
    }

    this.eventService.updateEvent(this.editEvent)
      .subscribe((event: Event) => {
        console.log(event);
        this.updateEvent$.next(event);
      });
  }

  onSubmitAbout() {
    if (!this.editAbout.valid) {
      // TODO: alert user
      return;
    }
  }

  handleGetNextComments($event: any, comment?: Comment) {
    if (this.end.comments || this.loading.comments) {
      return;
    }

    const end = this.commentsList.getRenderedRange().end;
    const total = this.commentsList.getDataLength();
    console.log(`${end}, '>=', ${total}`);
    if (end === total) {
      const id = comment ? comment.id : -1;
      this.commentsScroll$.next(id);
    }
  }

  handleGetNextAttendants($event: any, size: number) {
    if (this.end.attendants || this.loading.attendants) {
      return;
    }

    const end = this.attendantsList.getRenderedRange().end;
    const total = this.attendantsList.getDataLength();
    console.log(`${end}, '>=', ${total}`);
    if (end === total) {
      this.attendantsScroll$.next(size);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize($event: number) {
    this.desktop = window.innerWidth >= 1250;
  }

  onAttendantsScroll(direction: string) {
    const start = this.attendantsList.getRenderedRange().start;
    const end = this.attendantsList.getRenderedRange().end;
    const items = end - start - 2;

    // TODO: optimize scroll behavior
    if (direction === 'right') {
      this.attendantsList.scrollToIndex(end, 'smooth');
    } else {
      this.attendantsList.scrollToIndex(start, 'smooth');
    }
  }

  onEditAbout() {
    this.editEvent.title = this.editAbout.get('title').value;
    this.editEvent.description = this.editAbout.get('description').value;

    this.updateEvent();

    this.edit.about = false;
  }

  onEditLocation() {
    const width = window.innerWidth < 800 ? '95%' : '70%';
    const dialogRef = this.dialog.open(EventFormMapDialogComponent, {
      width,
      height: '500px',
      maxHeight: '90vh',
      data: this.event.location
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      console.log(result);
      if (!result) {
        return;
      }

      this.editEvent.location = result;

      this.updateEvent();
    });
  }

  onEditInfo() {
    const width = window.innerWidth < 800 ? '95%' : '70%';
    const dialogRef = this.dialog.open(EventFormDialogComponent, {
      width,
      height: '500px',
      maxHeight: '90vh',
      data: {
        event: this.event,
        user: this.user
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      console.log(result);
      if (!result) {
        return;
      }

      this.editEvent.time = result.date;
      this.editEvent.skill = result.skill;
      this.editEvent.maxAttendees = result.maxAttendees;

      this.updateEvent();
    });
  }
}
