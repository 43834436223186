import {Deserializable} from './deserializable.model';

export class Language implements Deserializable {

  isoCode: string;
  nativeName: string;
  emoji: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  clone() {
    return Object.assign( Object.create( Object.getPrototypeOf(this)), this);
  }

}
