import {Injectable} from '@angular/core';
import {fromEvent} from 'rxjs';
import {debounceTime, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ResizeService {

  constructor() {
  }

  onScreenResizeY(elem: Element, itemHeight: number, numberOfItems: number) {
    return fromEvent(window, 'resize')
      .pipe(
        debounceTime(200),
        map(_ => {
          const c = Math.ceil(
            (window.innerHeight + elem.scrollTop) / (itemHeight * numberOfItems)
          );

          return c;
        })
      );
  }

  onScreenResizeX(elem: Element, itemWidth: number, numberOfItems: number) {
    return fromEvent(window, 'resize')
      .pipe(
        debounceTime(200),
        map(_ => {
          const c = Math.ceil(
            (window.innerWidth + elem.scrollLeft) / (itemWidth * numberOfItems)
          );

          return c;
        })
      );
  }
}
