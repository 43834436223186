import {User} from '../../shared/models/user';
import {Event} from '../../shared/models/event';
import {NotificationType} from '../../shared/enums/notification-type';
import {Deserializable} from '../../shared/models/deserializable.model';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';
import {Comment} from '../../shared/models/comment';
import {faCheck, faClock, faComment, faEdit, faUserFriends, faUserPlus, faUsers} from '@fortawesome/free-solid-svg-icons';
import {DateTime} from 'luxon';

export class Notification implements Deserializable {

  id: string;
  type: NotificationType;
  timestamp: DateTime;
  headline: string;
  text: string;
  url: (string | number)[];
  clicked: boolean;
  received: boolean;
  isUserText: boolean;
  user: User;
  sender: User;
  event: Event;
  icon: IconDefinition;
  selected: boolean;
  subId: number;
  comment: Comment;

  constructor(user?: User) {
    if (user) {
      this.user = user;
    } else {
      this.user = new User();
    }
    this.selected = false;
  }

  deserialize(input: any): this {
    if (input.notification) {
      Object.assign(this, input.notification);

      if (input.notification.event && input.notification.event.id) {
        this.event = new Event().deserialize(input.notification.event);
      }
      this.sender = new User().deserialize(input.notification.sender);
      this.timestamp = DateTime.fromISO(input.notification.timestamp);
      this.type = input.notification.typeId;
    } else {
      Object.assign(this, input);

      if (input.event && input.event.id) {
        this.event = new Event().deserialize(input.event);
      }
      this.sender = new User().deserialize(input.sender);
      this.timestamp = DateTime.fromISO(input.timestamp);
    }

    this.getNotificationText();
    return this;
  }

  setUser(u: User) {
    this.user = u;

    if (this.event) {
      this.getNotificationText();
    }
  }

  setEvent(e: Event) {
    this.event = e;

    if (this.user) {
      this.getNotificationText();
    }
  }

  private getNotificationText() {
    let text: string;
    let url: (string | number)[];
    let headline;
    let request;

    switch (this.type) {
      case NotificationType.eventComment:
        // event comment
        headline = 'Eventkommentar';
        if (this.isUserText === true) {
          text = '<span class="motifun text">' + this.sender.firstName +
            '</span> hat dein Event <span class="motifun text">' + this.event.title + '</span> kommentiert.';
        } else {
          text = '<span class="motifun text">' + this.sender.firstName +
            '</span> hat das Event <span class="motifun text">' + this.event.title + '</span> kommentiert.';
        }
        this.icon = faComment;
        url = ['/event', this.event.id];
        break;
      case NotificationType.eventAttender:
        // event attender
        headline = 'Eventteilnehmer';
        if (this.isUserText === true) {
          text = '<span class="motifun text">' + this.sender.firstName +
            '</span> nimmt jetzt an deinem Event <span class="motifun text">' + this.event.title + '</span> teil.';
        } else {
          text = '<span class="motifun text">' + this.sender.firstName +
            '</span> nimmt jetzt ebenfalls am Event <span class="motifun text">' + this.event.title + '</span> teil.';
        }
        this.icon = faUsers;
        url = ['/event', this.event.id];
        break;
      case NotificationType.eventObserver:
        // event observer
        headline = 'Eventbeobachter';
        if (this.isUserText === true) {
          text = '<span class="motifun text">' + this.sender.firstName +
            '</span> beobachtet jetzt dein Event <span class="motifun text">' + this.event.title + '</span>.';
        } else {
          text = '<span class="motifun text">' + this.sender.firstName +
            '</span> beobachtet jetzt ebenfalls das Event <span class="motifun text">' + this.event.title + '</span>.';
        }
        this.icon = faUsers;
        url = ['/event', this.event.id];
        break;
      case NotificationType.eventStarts:
        // event starting
        headline = 'Event startet bald';
        if (this.isUserText === true) {
          text = '<span class="motifun text">' + this.sender.firstName +
            '</span> Dein Event <span class="motifun text">' + this.event.title + '</span> beginnt in einer Stunde.';
        } else {
          text = '<span class="motifun text">' + this.sender.firstName +
            '</span> Das Event <span class="motifun text">' + this.event.title + '</span> beginnt in einer Stunde.';
        }
        this.icon = faClock;
        url = ['/event', this.event.id];
        break;
      case NotificationType.eventEnded:
        // event ended
        headline = 'Event beendet';
        text = '<span class="motifun text">' + this.sender.firstName +
          '</span> hat das Event <span class="motifun text">' + this.event.title + '</span> als beendet markiert.';
        url = ['/event', this.event.id];
        this.icon = faCheck;
        break;
      case NotificationType.eventEdited:
        // event changed
        headline = 'Event bearbeitet';
        text = '<span class="motifun text">' + this.sender.firstName +
          '</span> hat sein Event <span class="motifun text">' + this.event.title + '</span> geändert.';
        url = ['/event', this.event.id];
        this.icon = faEdit;
        break;
      case NotificationType.friendRequest:
        // friend request
        headline = 'Freundschaftsanfrage';
        text = '<span class="motifun text">' + this.sender.firstName + '</span> hat Dir eine Freundschaftsanfrage gesendet.';
        request = true;
        url = ['/user', this.sender.id];
        this.icon = faUserPlus;
        break;
      case NotificationType.newFriendship:
        // new friend
        headline = 'Neue Freundschaft';
        text = '<span class="motifun text">' + this.sender.firstName + '</span> hat Deine Freundschaftsanfrage angenommen.';
        url = ['/user', this.sender.id];
        this.icon = faUserFriends;
        break;
      default:
        // TODO:
        text = 'something went wrong... :/';
    }

    this.headline = headline;
    this.text = `<p>${text}</p>`;
    this.url = url;
  }

  formatDate() {
    const diff = DateTime.local().diff(this.timestamp, 'days').days;
    if (diff <= 1) {
      return this.timestamp.diffNow();
    }

    return this.timestamp.toFormat('LL');
  }

  getImgUrl() {
    if (this.type === NotificationType.friendRequest || this.type === NotificationType.newFriendship) {
      return this.sender.getMedImgUrl();
    } else {
      console.log(this);
      return this.event.sport.getImgUrl();
    }
  }

  hasEvent() {
    return this.type === NotificationType.eventComment
      || this.type === NotificationType.eventEdited
      || this.type === NotificationType.eventAttender
      || this.type === NotificationType.eventEnded
      || this.type === NotificationType.eventObserver
      || this.type === NotificationType.eventStarts;
  }

  hasUser() {
    return this.type === NotificationType.newFriendship || this.type === NotificationType.friendRequest;
  }

  clone() {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
  }
}
