import {User} from './user';
import {environment} from '../../../environments/environment';
import {faAward, faCheck, faPlus, faStar} from '@fortawesome/free-solid-svg-icons';
import {ActivityType} from '../../modules/users/enums/activity-type';
import {DateTime} from 'luxon';

export class Activity {

  id: number;
  url: string;
  headline: string;
  user: User;
  text: string;
  day: number;
  month: string;

  firstName: string;
  lastName: string;
  gender: string;
  image: string;
  linkId: number;
  me: number;
  title: string;
  rating: number;
  timestamp: DateTime;

  type: ActivityType;

  icon: any;

  constructor(user?: User) {
    this.user = user;
  }

  deserialize(input: any): this {
    Object.assign(this as any, input);

    this.timestamp = DateTime.fromISO(input.timestamp);
    this.day = this.timestamp.day;
    this.month = this.timestamp.toFormat('MM');

    this.id = this.linkId;

    if (input.iconImage) {
      this.image = environment.imageHost + 'events/' + input.iconImage;
    }

    switch (this.type) {
      case ActivityType.EVENT_REVIEW:
        this.url = '/event/' + this.id;
        this.headline = 'Event-Bewertung';
        this.icon = faStar;
        this.text = (this.user.isSignedIn ? 'Du hast das Event ' : this.user.firstName + ' hat das Event ') + this.title
          + ' mit ' + this.rating +
          (this.rating > 1 ? '-Sternen' : '-Stern') + ' bewertet!';
        break;
      case ActivityType.EVENT_JOINED:
        this.url = '/event/' + this.id;
        this.headline = 'Event beigetreten';
        this.icon = faCheck;
        this.text = (this.user.isSignedIn ? 'Du bist dem Event ' : this.user.firstName + ' ist dem Event ') + this.title
          + ' von ' + this.firstName + ' beigetreten!';
        break;
      case ActivityType.AWARD:
        this.url = '#!award';
        this.headline = 'Auszeichnung erhalten';
        this.icon = faAward;
        this.text = (this.user.isSignedIn ? 'Du hast die Auszeichnung ' : this.user.firstName + ' hat die Auszeichnung ') + this.title
          + ' erhalten.';
        break;
      case ActivityType.EVENT_CREATED:
        this.url = '/event/' + this.id;
        this.headline = 'Event erstellt';
        this.icon = faPlus;
        this.text = (this.user.isSignedIn ? 'Du hast das Event ' : this.user.firstName + ' hat das Event ') + this.title
          + ' erstellt.';
        break;
      default:
        console.error('unknown activity type...');
    }

    return this;
  }

  clone() {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this as any);
  }
}
