import {Component, Inject, OnInit} from '@angular/core';
import {DateAdapter} from '@angular/material/core';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {NgxMaterialTimepickerComponent} from 'ngx-material-timepicker';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Event} from '../../../shared/models/event';
import {User} from '../../../shared/models/user';
import {DataStoreService} from '../../../core/services/data-store.service';
import {faChevronLeft, faChevronRight, faUsers} from '@fortawesome/free-solid-svg-icons';
import {MatSliderChange} from '@angular/material/slider';
import {DateTime} from 'luxon';

@Component({
  selector: 'app-event-form-dialog',
  templateUrl: './event-form-dialog.component.html',
  styleUrls: ['./event-form-dialog.component.css'],
  providers: []
})
export class EventFormDialogComponent implements OnInit {

  sliderConfig = {
    min: 1,
    max: 5,
    invert: false,
    step: 1,
    vertical: false,
    thumbLabel: true,
    tickInterval: 1
  };

  errors = {
    sport: 'Es muss eine Sportart ausgewählt werden!',
    day: 'Es muss ein Termin min 3 Stunden in der Zukunft ausgewählt werden!',
    time: 'Es muss ein Termin min 3 Stunden in der Zukunft ausgewählt werden!',
    skill: 'Es muss ein Level zwischen 1 und 5 gewählt werden!',
    maxAttendees: 'Wenn die Teilnehmerzahl beschränkt werden soll, dann muss der Wert zwischen 5 und 20 liegen!',
    place: 'Es muss ein Ort ausgewählt werden. Dafür kannst du entweder einen Punkt auf der Karte markieren oder die Suche benutzen.',
    title: 'Es muss ein Titel mit max. 50 Zeichen vergeben werden!',
    description: 'Es muss eine Beschreibung mit max. 400 Zeichen vergeben werden!'
  };

  info = new UntypedFormGroup({
    day: new UntypedFormControl('', [Validators.required]),
    time: new UntypedFormControl('', [Validators.required]),
    skill: new UntypedFormControl(1, [Validators.required, Validators.min(1), Validators.max(5)]),
    maxAttendees: new UntypedFormControl('', [Validators.min(5), Validators.max(15)])
  });

  skillLevel: string;

  icons = {
    maxAttendees: faUsers,
    arrowLeft: faChevronLeft,
    arrowRight: faChevronRight
  };

  timeFormat = 24;
  minDate = new Date();
  user: User;
  event: Event;

  private edit: any = {};

  constructor(private adapter: DateAdapter<Date>,
              private data: DataStoreService,
              private dialogRef: MatDialogRef<EventFormDialogComponent>,
              @Inject(MAT_DIALOG_DATA) private passedData: any) {
    this.user = passedData.user;
    this.event = passedData.event;
    this.adapter.setLocale(this.user.settings.language);

    this.edit.day = DateTime.fromISO(this.event.time.toISO()).startOf('day');
    this.edit.skill = this.event.skill;
    this.edit.maxAttendees = this.event.maxAttendees;
    if (this.timeFormat === 24) {
      this.edit.time = DateTime.fromISO(this.event.time.toISO());
    } else {
      this.edit.time = DateTime.fromISO(this.event.startTime.toISO());
    }

    console.log(this.event);
    console.log(this.edit);

    this.setLevel(this.event.skill);
  }

  ngOnInit() {
    this.info.patchValue({
      day: this.edit.day,
      time: this.event.startTime,
      skill: this.edit.skill,
      maxAttendees: this.edit.maxAttendees
    });
  }

  onTimeSet(event: string) {
    if (this.timeFormat === 24) {
      this.edit.time = DateTime.fromISO(event);
    } else {
      this.edit.time = DateTime.fromISO(event);
    }
    this.info.patchValue({
      time: event
    });
  }

  onDaySet(event: MatDatepickerInputEvent<Date>, time?: NgxMaterialTimepickerComponent) {
    this.info.patchValue({
      day: event.value
    });
    this.edit.day = event.value;
    if (time && this.info.get('time').value.length === 0) {
      time.open();
    }
  }

  onSkillChange($event: MatSliderChange): void {
    this.edit.skill = $event.value;
    this.info.patchValue({
      skill: this.edit.skill
    });
    this.setLevel($event.value);
  }

  onMaxAttendeesChange($event: any) {
    this.edit.maxAttendees = $event.target.value;
    this.info.patchValue({
      maxAttendees: $event.target.value
    });
  }

  private setLevel(skill: number) {
    switch (skill) {
      case 1:
        this.skillLevel = 'Anfänger/Couchpotato';
        break;
      case 2:
        this.skillLevel = 'Laie/Gelegenheitssportler';
        break;
      case 3:
        this.skillLevel = 'Durchschnittssportler/Sportler';
        break;
      case 4:
        this.skillLevel = 'Profi/Profisportler';
        break;
      default:
        this.skillLevel = 'Experte/Leistungssportler';
    }
  }

  submit() {
    if (this.info.valid) {
      console.log(this.edit);
      console.log(this.edit.time.hour());
      console.log(this.edit.time.minute());
      // this.edit.date = DateTime.now().set(this.edit.day.t).hour(this.edit.time.hour()).minute(this.edit.time.minute());
      console.log(this.edit);
      this.dialogRef.close(this.edit);
    }
  }

}
