import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {UserComponent} from './user/user.component';
import {AuthGuard} from '../../core/guards/auth.guard';
import {FriendListComponent} from './friend-list/friend-list.component';

const userRoutes: Routes = [
  {
    path: '',
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'friends',
        component: FriendListComponent
      },
      {
        path: ':id',
        component: UserComponent
      },
      {
        path: '',
        component: UserComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(userRoutes)],
  exports: [RouterModule]
})
export class UserRoutingModule {
}
