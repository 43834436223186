import {Component} from '@angular/core';
import {AuthService} from '../../../core/services/auth.service';
import {Router} from '@angular/router';
import {ApiService} from '../../../core/services/api.service';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {PasswordValidator} from '../../../core/validators/password.validator';
import {catchError} from 'rxjs/operators';
import {of} from 'rxjs';
import {AlertService} from '../../../core/services/alert.service';
import {Alert} from '../../../shared/models/alert';
import {AlertType} from '../../../shared/enums/alert-type';
import {faEnvelope, faKey, faUser} from '@fortawesome/free-solid-svg-icons';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';
import {RegisterResponse} from '../../../shared/interface/responses/register-response';

@Component({
  selector: 'app-register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.css']
})
export class RegisterFormComponent {

  icons: { [key: string]: IconDefinition } = {
    name: faUser,
    email: faEnvelope,
    password: faKey
  };

  registerForm: UntypedFormGroup = new UntypedFormGroup({
    firstName: new UntypedFormControl('', [
      Validators.required
    ]),
    lastName: new UntypedFormControl('', [
      Validators.required
    ]),
    email: new UntypedFormControl('', [
      Validators.required,
      Validators.email
    ]),
    password: new UntypedFormControl('', [
      Validators.required,
      PasswordValidator.strengthValidator(),
      Validators.minLength(8),
      Validators.maxLength(32)
    ])
  });

  valid: { [key: string]: boolean | number } = {
    firstName: true,
    lastName: true,
    email: true,
    password: 3
  };

  constructor(private alert: AlertService,
              private api: ApiService,
              private auth: AuthService,
              public router: Router) {
  }

  onSubmit() {
    if (!this.registerForm.valid) {
      // TODO: alert user
      return;
    }
    this.api.post<any>('register', this.registerForm.value)
      .pipe(
        catchError((err, caught) => {

          return of(null);
        })
      )
      .subscribe((data: RegisterResponse) => {
        if (data && data.token) {
          this.auth.setToken(data.token);

          this.router.navigate(['/'])
            .catch((error: Error) => {
              console.log(error);
            });
        } else if (data.message === 'email sent') {
          this.alert.appendAlert(new Alert(
            'E-Mail',
            'Wir haben dir eine E-Mail zum zurücksetzen deines Passworts gesendet.',
            AlertType.SUCCESS
          ));
        } else {
          this.alert.appendAlert(new Alert(
            'Fehler',
            'Es ist ein Fehler bei der Registrierung aufgetreten. Bitte versuche es erneut.',
            AlertType.ERROR
          ));
        }
      });
  }

  checkFirstName(firstName: string) {
    this.valid.firstName = this.validateFirstname(firstName);
  }

  checkLastName(lastName: string) {
    this.valid.lastName = this.validateLastname(lastName);
  }

  checkEmail(email: string) {
    this.valid.email = this.validateEmail(email);
  }

  checkPassword(password: string) {
    this.valid.password = this.validatePassword(password);
  }

  validateFirstname(firstName: string) {
    const pattern = /^([A-Z-ÄÖÜ]{1})([a-z-äöüß]+)((([-.,']{1})+([A-Z-ÄÖÜ]{1})([a-z-äöüß]+))*)$/;
    return pattern.test(firstName) && firstName.length > 1;
  }

  validateLastname(lastName: string) {
    const pattern = /^([A-Z-ÄÖÜ]{1})([a-z-äöüß]+)((([-.,']{1})+([A-Z-ÄÖÜ]{1})([a-z-äöüß]+))*)$/;
    return pattern.test(lastName) && lastName.length > 1;
  }

  validateEmail(email: string) {
    const pattern = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/igm;
    return pattern.test(email) && email.length < 129 && email.length > 8;
  }

  validatePassword(password: string) {
    let count = -1;
    const pattern1 = /.*\d.*/;
    const pattern2 = /.*[a-z].*/;
    const pattern3 = /.*[A-Z].*/;
    const pattern4 = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9\s]).{8,32}/;
    if (password.length >= 8 && password.length <= 32) {
      count = 0;
      if (pattern1.test(password)) {
        count++;
      }
      if (pattern2.test(password)) {
        count++;
      }
      if (pattern3.test(password)) {
        count++;
      }
      if (pattern4.test(password)) {
        count++;
      }
    }
    return count;
  }
}
