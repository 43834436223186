import {Component, Input} from '@angular/core';
import {Comment} from '../../../shared/models/comment';

@Component({
  selector: 'app-event-comment',
  templateUrl: './event-comment.component.html',
  styleUrls: ['./event-comment.component.css']
})
export class EventCommentComponent {

  @Input() comment: Comment;
  @Input() eventCreatorId: string;

  constructor() {
  }
}
