import {Component, Input, OnInit} from '@angular/core';
import {Activity} from '../../../shared/models/activity';

@Component({
  selector: 'app-timeline-item',
  templateUrl: './user-activity-item.component.html',
  styleUrls: ['./user-activity-item.component.css']
})
export class UserActivityItemComponent {

  @Input() activity: Activity;

  constructor() {
  }

}
