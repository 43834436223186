import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {NgxMaterialTimepickerComponent} from 'ngx-material-timepicker';
import {TimeFormat} from '../../../shared/enums/time-format.enum';
import {EventFormSportsDialogComponent} from '../event-form-sports-dialog/event-form-sports-dialog.component';
import {Sport} from '../../../shared/models/sport';
import {MatDialog} from '@angular/material/dialog';
import {User} from '../../../shared/models/user';
import {IEventWhat} from '../../../shared/interface/IEventWhat';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import {faCalendar, faClock, faDumbbell, faTimes, faUsers} from '@fortawesome/free-solid-svg-icons';
import {MatSliderChange} from '@angular/material/slider';
import {DateTime} from 'luxon';

@Component({
  selector: 'app-event-form-what',
  templateUrl: './event-form-what.component.html',
  styleUrls: ['./event-form-what.component.scss']
})
export class EventFormWhatComponent implements OnInit {

  sliderConfig = {
    min: 1,
    max: 5,
    invert: false,
    step: 1,
    vertical: false,
    thumbLabel: true,
    tickInterval: 1,
    color: 'primary'
  };

  errors = {
    sport: 'Es muss eine Sportart ausgewählt werden!',
    day: 'Es muss ein Termin min 3 Stunden in der Zukunft ausgewählt werden!',
    time: 'Es muss ein Termin min 3 Stunden in der Zukunft ausgewählt werden!',
    skill: 'Es muss ein Level zwischen 1 und 5 gewählt werden!',
    maxAttendees: 'Wenn die Teilnehmerzahl beschränkt werden soll, dann muss der Wert zwischen 5 und 20 liegen!'
  };

  icons = {
    clear: faTimes,
    sports: faDumbbell,
    calendar: faCalendar,
    time: faClock,
    maxAttendees: faUsers
  };

  skillLevel: string;

  @Output() changed: EventEmitter<any> = new EventEmitter<any>();

  @Input() formGroup: UntypedFormGroup;
  @Input() user: User;
  @Input() sportsError: string;
  @Input() dayError: string;
  @Input() minDate: Date;
  @Input() timeFormat: TimeFormat;

  constructor(private dialog: MatDialog) {
    this.setLevel(1);
  }

  ngOnInit() {
    if (!this.minDate) {
      this.minDate = new Date();
    }
    if (!this.timeFormat) {
      this.timeFormat = TimeFormat.TWENTY_FOUR;
    }
  }

  openSportsDialog() {
    const dialogRef = this.dialog.open(EventFormSportsDialogComponent, {
      minWidth: '250px',
      width: '80%',
      maxWidth: '80%',
      panelClass: this.user.settings.isDarkMode ? 'dark-theme' : ''
    });

    dialogRef.afterClosed().subscribe((sport: Sport) => {
      if (!sport) {
        return;
      }
      this.formGroup.patchValue({
        sportName: sport.name,
        sportId: sport.id
      });
    });
  }

  removeSports() {
    this.formGroup.patchValue({
      sportName: '',
      sportId: ''
    });
  }

  onDaySet(event: MatDatepickerInputEvent<Date>, time?: NgxMaterialTimepickerComponent) {
    this.formGroup.patchValue({
      day: event.value
    });
    if (time && this.formGroup.get('time').value.length === 0) {
      time.open();
    }

    if (DateTime.fromJSDate(event.value).isValid) {
      const values: IEventWhat = this.formGroup.getRawValue();
      this.calculateStartTime(values.day, values.time);
    }
  }

  onMaxAttendeesChange($event: any) {
    this.formGroup.patchValue({
      maxAttendees: $event.target.value
    });
  }

  onTimeSet(event: string) {
    const values = this.formGroup.getRawValue();
    this.formGroup.patchValue({
      time: event
    });
    this.calculateStartTime(values.day, event);
  }

  onSkillChange($event: MatSliderChange): void {
    this.formGroup.patchValue({
      skill: $event.value
    });
    this.setLevel($event.value);
  }

  private setLevel(skill: number) {
    switch (skill) {
      case 1:
        this.skillLevel = 'Anfänger/Couchpotatoe';
        break;
      case 2:
        this.skillLevel = 'Laie/Gelegenheitssportler';
        break;
      case 3:
        this.skillLevel = 'Durchschnittssportler/Sportler';
        break;
      case 4:
        this.skillLevel = 'Profi/Profisportler';
        break;
      default:
        this.skillLevel = 'Experte/Leistungssportler';
    }
  }

  private calculateStartTime(day: DateTime, time: string) {
    if (!time || time.length <= 0 || time.indexOf(':') < 0) {
      return;
    }

    if (!day || !day.isValid) {
      return;
    }

    const t = time.split(':');
    const hour = Number(t[0]);
    const minute = Number(t[1]);

    const newStartTime = DateTime.fromISO(day.toISO());
    newStartTime.set({minute: minute, hour: hour});

    this.formGroup.patchValue({
      startTime: newStartTime
    });
  }
}
