<div id="bg" class="back"
     [ngStyle]="settings?.getImgPath() !== null && {'background-image': 'url(' + settings?.getImgPath() + ')'}"></div>
<div id="color" class="back color-effect"
     [ngClass]="(settings?.backgroundColor !== 'none' ? settings?.backgroundColor  : (settings?.isDarkMode? 'dark' : 'light'))"></div>
<input #menuToggle type="checkbox" id="navigation" name="" (change)="openMenu = menuToggle.checked"
       [checked]="innerWidth >= 1620">

<header #header>
  <mat-toolbar color="white">
    <button mat-icon-button (click)="snav.toggle()">
      <fa-icon [icon]="icons.menu"></fa-icon>
    </button>
    <img [src]="images.logo.dark" [routerLink]="['/']" [style.height.px]="header.clientHeight - 8">
  </mat-toolbar>
</header>

<mat-sidenav-container class="side-nav-container" [ngClass]="(user$ | async)?.settings.isDarkMode ? 'dark-theme' : ''">
  <mat-sidenav #snav [mode]="'over'"
               [fixedInViewport]="true">
    <mat-nav-list>
      <div class="logo">
        <button mat-icon-button (click)="snav.toggle()">
          <fa-icon [icon]="icons.close"></fa-icon>
        </button>
        <a [routerLink]="['/']">
          <img [src]="images.logoMenu">
        </a>
      </div>
      <ul class="menu-list">
        <li>
          <a [routerLink]="['/']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <fa-icon [icon]="icons.start"></fa-icon>
            <span>Startseite</span>
          </a>
        </li>
        <li>
          <a [routerLink]="['/user']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <fa-icon [icon]="icons.profile"></fa-icon>
            <span>Profil</span>
          </a>
        </li>
        <li>
          <a [routerLink]="['/notifications']" routerLinkActive="active">
            <div class="rel">
              <fa-icon [icon]="icons.notification"></fa-icon>
              <span>Neuigkeiten</span>
              <ng-container *ngIf="unreadNotificationCount$ | async as unreadNotifications">
                <span class="badge" *ngIf="unreadNotifications > 0">{{unreadNotifications}}</span>
              </ng-container>
            </div>
          </a>
        </li>
        <li>
          <a [routerLink]="['/event', 'create']" routerLinkActive="active">
            <fa-icon [icon]="icons.add"></fa-icon>
            <span>Event erstellen</span>
          </a>
        </li>
        <li>
          <a [routerLink]="['/messages']" routerLinkActive="active">
            <div class="rel">
              <fa-icon [icon]="icons.messages"></fa-icon>
              <span>Nachrichten</span>
              <ng-container *ngIf="unreadMessageCount$ | async as unreadMessages">
                <span class="badge" *ngIf="unreadMessages > 0">{{unreadMessages}}</span>
              </ng-container>
            </div>
          </a>
        </li>
        <li>
          <a [routerLink]="['/user', 'friends']" routerLinkActive="active">
            <fa-icon [icon]="icons.friends"></fa-icon>
            <span>Freunde</span>
          </a>
        </li>
        <li>
          <a [routerLink]="['/settings']" routerLinkActive="active">
            <fa-icon [icon]="icons.settings"></fa-icon>
            <span>Einstellungen</span>
          </a>
        </li>
        <li>
          <a [routerLink]="['/search']" routerLinkActive="active">
            <fa-icon [icon]="icons.search"></fa-icon>
            <span>Suchen</span>
          </a>
        </li>
        <li>
          <a (click)="logout(); snav.toggle();">
            <fa-icon [icon]="icons.logout"></fa-icon>
            <span>Abmelden</span>
          </a>
        </li>
      </ul>
      <footer>
        <div class="flex-row">
          <div class="col-6">
            <a [routerLink]="['/impress']">Impressum</a>
          </div>
          <div class="col-6">
            <a [routerLink]="['/privacy']">Datenschutz</a>
          </div>
        </div>
        <span>© {{year}} MotiFun</span>
      </footer>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content>
    <main>
      <router-outlet></router-outlet>
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>

<nav id="menu">
  <label for="navigation" class="toggle" id="toggle-menu">
    <div class="menu_btn">
      <div class="bar1 lite-text"></div>
      <div class="bar2 lite-text"></div>
      <div class="bar3 lite-text"></div>
    </div>
  </label>
  <div class="logo">
    <a [routerLink]="['/']">
      <img [src]="openMenu ? images.openedMenu : images.closedMenu">
    </a>
  </div>
  <ul class="menu-list">
    <li>
      <a [routerLink]="['/']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
        <fa-icon [icon]="icons.start"></fa-icon>
        <span>Startseite</span>
      </a>
    </li>
    <li>
      <a [routerLink]="['/notifications']" routerLinkActive="active">
        <div class="rel">
          <fa-icon [icon]="icons.notification"></fa-icon>
          <span>Neuigkeiten</span>
          <ng-container *ngIf="unreadNotificationCount$ | async as unreadNotifications">
            <span class="badge" *ngIf="unreadNotifications > 0">{{unreadNotifications}}</span>
          </ng-container>
        </div>
      </a>
    </li>
    <li>
      <a [routerLink]="['/user']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
        <fa-icon [icon]="icons.profile"></fa-icon>
        <span>Profil</span>
      </a>
    </li>
    <li>
      <a [routerLink]="['/event', 'create']" routerLinkActive="active">
        <fa-icon [icon]="icons.add"></fa-icon>
        <span>Event erstellen</span>
      </a>
    </li>
    <li>
      <a [routerLink]="['/messages']" routerLinkActive="active">
        <div class="rel">
          <fa-icon [icon]="icons.messages"></fa-icon>
          <span>Nachrichten</span>
          <ng-container *ngIf="unreadMessageCount$ | async as unreadMessages">
            <span class="badge" *ngIf="unreadMessages > 0">{{unreadMessages}}</span>
          </ng-container>
        </div>
      </a>
    </li>
    <li>
      <a [routerLink]="['/user', 'friends']" routerLinkActive="active">
        <fa-icon [icon]="icons.friends"></fa-icon>
        <span>Freunde</span>
      </a>
    </li>
    <li>
      <a [routerLink]="['/settings']" routerLinkActive="active">
        <fa-icon [icon]="icons.settings"></fa-icon>
        <span>Einstellungen</span>
      </a>
    </li>
    <li>
      <a [routerLink]="['/search']" routerLinkActive="active">
        <fa-icon [icon]="icons.search"></fa-icon>
        <span>Suchen</span>
      </a>
    </li>
    <li>
      <a (click)="logout()">
        <fa-icon [icon]="icons.logout"></fa-icon>
        <span>Abmelden</span>
      </a>
    </li>
  </ul>
  <footer>
    <div class="flex-row">
      <div class="col-6">
        <a [routerLink]="['/impress']">Impressum</a>
      </div>
      <div class="col-6">
        <a [routerLink]="['/privacy']">Datenschutz</a>
      </div>
    </div>
    <span>© {{year}} MotiFun</span>
  </footer>
</nav>
