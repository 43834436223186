<div class="flex-row">
  <div class="img" [ngStyle]="{'background-image': 'url(' + notification.getImgUrl() + ')'}">
    <div>
      <fa-icon [icon]="notification.icon"></fa-icon>
    </div>
  </div>
  <div class="text">
    <p>{{notification.headline}}</p>
    <span>{{notification.formatDate()}}</span>
    <div [innerHTML]="notification.text"></div>
  </div>
</div>
