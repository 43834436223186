import {User} from './user';
import {Comment} from './comment';
import {Deserializable} from './deserializable.model';
import {Sport} from './sport';
import {Location} from './location';
import {environment} from '../../../environments/environment';
import {IEvent} from '../interface/IEvent';
import {ICreateEvent} from '../interface/ICreateEvent';
import {DateTime} from 'luxon';

export class Event implements Deserializable {

  constructor() {
    this.attendantsEnd = false;
    this.commentsEnd = false;
    this.isCreator = false;
  }

  id: number;
  title: string;
  description: string;
  skill: number;
  img: string;

  creator: User;

  latitude: number;
  longitude: number;
  city: string;
  country: string;

  mapUrl: string;

  distance: number;

  startTime: DateTime;
  startDate: DateTime;
  time: DateTime;

  sport: Sport;

  isAttender: boolean;
  isObserver: boolean;
  attendeesCount: number;
  observersCount: number;
  commentsCount: number;

  location: Location;

  attendees: User[] = [];
  observers: User[] = [];
  comments: Comment[] = [];

  maxAttendees: number;

  isCreator: boolean;
  attendantsEnd: boolean;
  commentsEnd: boolean;

  static formGroupToPayload(data: IEvent): ICreateEvent {
    return {
      sportId: data.eventInfoGroup.sportId,
      startTime: data.eventInfoGroup.startTime.toISO(),
      skill: data.eventInfoGroup.skill,
      maxAttendees: data.eventInfoGroup.maxAttendees,

      street: data.eventPlaceGroup.street,
      houseNumber: data.eventPlaceGroup.houseNumber,
      postalCode: data.eventPlaceGroup.postalCode,
      city: data.eventPlaceGroup.city,
      state: data.eventPlaceGroup.state,
      country: data.eventPlaceGroup.country,
      lat: data.eventPlaceGroup.lat,
      lng: data.eventPlaceGroup.lng,

      title: data.eventTextGroup.title,
      description: data.eventTextGroup.description
    };
  }

  /**
   * maps form into this
   * @param data formData
   */
  static createFromForm(data: IEvent): Event {
    const event = new Event();
    event.sport = new Sport();
    event.sport.id = data.eventInfoGroup.sportId;
    event.maxAttendees = data.eventInfoGroup.maxAttendees > 0 ? data.eventInfoGroup.maxAttendees : -1;
    event.startTime = data.eventInfoGroup.day;
    const values = data.eventInfoGroup.time.split(':');
    event.time = DateTime.local().set({hour: Number(values[0]), minute: Number(values[1])});
    event.skill = data.eventInfoGroup.skill;

    return event;
  }

  validate(): boolean {
    return true;
  }

  appendComment(comment: any) {
    if (!(comment instanceof Comment)) {
      comment = new Comment().deserialize(comment);
    }

    console.log(this.comments.length);
    this.commentsCount++;
    this.comments.unshift(comment);
    console.log(this.comments.length);
  }

  appendComments(comments: Comment[]) {
    if (this.comments.length === 0) {
      this.comments = comments;
    } else {
      this.comments = this.comments.concat(comments);
    }
  }

  appendAttender(user: User) {
    const found = this.attendees.find((el) => el.id === user.id);

    if (!found) {
      this.attendees.push(user);
      this.attendeesCount++;
    }
  }

  prependAttender(user: User) {
    const found = this.attendees.find((el) => el.id === user.id);

    if (!found) {
      this.attendees.unshift(user);
      this.attendeesCount++;
    }
  }

  removeAttender(user: User) {
    if ((this.attendees.length === 0 && this.attendeesCount > 0) || this.attendeesCount === this.attendees.length) {
      this.attendeesCount--;
    }
    for (let i = 0; i < this.attendees.length; i++) {
      if (this.attendees[i].id === user.id) {
        this.attendees.splice(i, 1);
        return;
      }
    }
  }

  appendAttendees(data: any[]) {
    for (const d of data) {
      if (!d.user) {
        continue;
      }
      const u = new User().deserialize(d.user);
      this.attendees.push(u);
    }
  }

  setUser(user: User) {
    this.creator = user;
  }

  getImgUrl() {
    return environment.sportImgUrl + this.img;
  }

  getFallbackUrl() {
    return environment.sportImgUrl + 'fallback.jpg';
  }

  calculateDistance(location: Location) {
    this.distance = this.location.getDistanceInKm(location);
  }

  getDistanceString() {
    if (Number.isNaN(this.distance)) {
      return null;
    }
    if (this.distance < 0.5) {
      return '< 500 m';
    } else if (this.distance < 1) {
      return '< 1 Km';
    } else {
      return Math.round(this.distance * 100) / 100 + ' Km';
    }
  }

  getMapUrl(width: number = 430, height: number = 320) {
    return 'https://maps.googleapis.com/maps/api/staticmap?center=' +
      this.latitude + ',' + this.longitude +
      '&zoom=15&size=' + width + 'x' + height + '&maptype=roadmap' +
      '&markers=color:red%7Clabel:%7C' + this.latitude + ',' + this.longitude +
      '&key=' + environment.googleAPIKey;
  }

  setStartTime(day: DateTime) {
    this.startTime = DateTime.fromISO(day.toISOTime());
    this.startTime.set({
      hour: this.time.get('hour'),
      minute: this.time.get('minute')
    });
  }

  hasValidTime() {
    return !(!this.time || !this.time.isValid);
  }

  hasValidDate(day: DateTime) {
    if (!day || !day.isValid) {
      return false;
    }

    if (!this.startTime.isValid) {
      return false;
    }

    return DateTime.local().startOf('day').diff(this.startTime).minutes <= 0;
  }

  hasValidStartTime() {
    if (!this.startTime) {
      return false;
    }

    if (!this.startTime.isValid) {
      return false;
    }

    return DateTime.local().plus({hour: 3}).diff(this.startTime).seconds <= 0;
  }

  /**
   * returns event in FormGroup structure
   */
  getIEvent(): IEvent {
    return {
      eventInfoGroup: {
        sportName: this.sport.name,
        sportId: this.sport.id,
        day: this.startDate,
        time: this.startTime.toISOTime(),
        startTime: this.startTime,
        skill: this.skill,
        maxAttendees: this.maxAttendees
      },
      eventPlaceGroup: {
        address: this.location.getAddressString(),
        street: this.location.street,
        houseNumber: this.location.houseNumber,
        postalCode: this.location.postalCode,
        city: this.location.city,
        state: this.location.state,
        country: this.location.country,
        lat: this.location.lat,
        lng: this.location.lng
      },
      eventTextGroup: {
        title: this.title,
        description: this.description
      }
    };
  }

  /**
   * returns event in payload structure
   */
  getICreateEvent() {
    return {
      sportId: this.sport.id,
      startTime: this.startTime,
      skill: this.skill,
      maxAttendees: this.maxAttendees,

      street: this.location.street,
      houseNumber: this.location.houseNumber,
      postalCode: this.location.postalCode,
      city: this.location.city,
      state: this.location.state,
      country: this.location.country,
      lat: this.location.lat,
      lng: this.location.lng,

      title: this.title,
      description: this.description
    };
  }

  deserialize(input: any): this {
    Object.assign(this as any, input);
    if (input.creator) {
      this.creator = new User().deserialize(input.creator);
    }

    this.location = new Location().deserialize(input);

    this.time = DateTime.fromISO(this.startTime as unknown as string);

    this.startDate = DateTime.fromISO(this.time.toISO());
    this.startTime = DateTime.fromISO(this.time.toISO());

    this.distance = Math.round(input.distance * 100) / 100;

    if (Number.isNaN(this.distance)) {
      this.distance = undefined;
    }

    if (input.sport) {
      this.sport = new Sport().deserialize(input.sport);
      this.img = this.sport.backgroundImage;
    }

    return this;
  }

  clone(): Event {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this as any);
  }
}
