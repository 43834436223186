<h3 mat-dialog-title>Sportart auswählen</h3>
<mat-dialog-content>
  <div class="row">
    <mat-form-field class="col s10">
      <input matInput type="text" id="filter_sports" placeholder="Sportart suchen" #sportsFilter
             (keyup)="filterSports(sportsFilter.value)">
    </mat-form-field>
  </div>
  <div class="row center-align">
    <div *ngFor="let cat of filteredSports">
      <div *ngIf="cat.sports.length > 0">
        <h4>{{cat.headline}}</h4>
        <ul>
          <li
            *ngFor="let sport of cat.sports"
            (click)="setSport(sport)">
            <div><span class="link">{{sport.name}}</span></div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Abbrechen</button>
</mat-dialog-actions>
