import {Component} from '@angular/core';
import {faKey} from '@fortawesome/free-solid-svg-icons';
import {environment} from '../../../../environments/environment';
import {ApiService} from '../../../core/services/api.service';
import {ActivatedRoute} from '@angular/router';
import {switchMap} from 'rxjs/operators';
import {UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators} from '@angular/forms';
import {PasswordValidator} from '../../../core/validators/password.validator';
import {of} from 'rxjs';

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.css']
})
export class UpdatePasswordComponent {

  icons = {
    password: faKey
  };

  images = {
    background: environment.imageHost + 'landing/bg_forgot.jpg',
    logo: environment.imageHost + 'logos/logo_mt_384x384.png'
  };

  resetPasswordFrom = new UntypedFormGroup({
    password: new UntypedFormControl('', [
        Validators.required,
        PasswordValidator.strengthValidator(),
        Validators.minLength(8),
        Validators.maxLength(32)
      ]
    ),
    passwordConfirm: new UntypedFormControl('', [
      Validators.required
    ])
  }, PasswordValidator.equalPasswords('password', 'passwordConfirm'));


  constructor(private api: ApiService,
              private route: ActivatedRoute) {
  }

  onSubmit() {
    if (!this.resetPasswordFrom.valid) {
      // TODO: alert user
      return;
    }
    this.route.params
      .pipe(
        switchMap((params: any) => {
          const payload = this.resetPasswordFrom.value;

          if (!params['email'] || !params['token']) {
            // TODO: alert user
            console.log('no token');
            return of(null);
          }

          return this.api.post(`lostpassword/${params['email']}/${params['token']}`, payload);
        })
      )
      .subscribe((data: any) => {
        if (!data) {
          return;
        }
        console.log(data);
      });
  }

  showPasswordError() {
    const pw = this.resetPasswordFrom.controls['password'];
    return pw.hasError('strength') || pw.hasError('minlength') || pw.hasError('maxlength');
  }

  showDifferentPasswordsError() {
    const show = this.resetPasswordFrom.controls['passwordConfirm'].hasError('differentPasswords') &&
      !this.resetPasswordFrom.controls['passwordConfirm'].hasError('required');
    return show;
  }

  getFormValidationErrors() {
    Object.keys(this.resetPasswordFrom.controls).forEach(key => {

      const controlErrors: ValidationErrors = this.resetPasswordFrom.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });
  }
}
