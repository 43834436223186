<ng-container *ngIf="(user$ | async) as user">
  <mat-tab-group mat-stretch-tabs>
    <mat-tab>
      <ng-template mat-tab-label>
        <fa-icon [icon]="icons.profile"></fa-icon>
        Profil
      </ng-template>
      <ng-template matTabContent>
        <div class="profile">
          <app-user-info [user]="user" [ownProfile]="ownProfile"></app-user-info>
        </div>
      </ng-template>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <fa-icon [icon]="icons.activity"></fa-icon>
        Aktivitätsverlauf
      </ng-template>

      <ng-template matTabContent>
        <div class="activities">
          <app-user-activities [user]="user"></app-user-activities>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>

  <div id="content" class="flex-row main-fixed">
    <div id="user-left-col" class="col-6 margin" style="padding-left: 20px;">

      <app-user-info [user]="user" [ownProfile]="ownProfile"></app-user-info>

    </div>

    <div class="spacer-v" style="position: relative;">
      <div class="arrow-down" style="position: absolute; bottom: -1px; left: -19px;"></div>
    </div>

    <div id="user-right-col" class="col-4 flex-col margin">

      <app-user-activities [user]="user"></app-user-activities>

    </div>
  </div>

</ng-container>
