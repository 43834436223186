import {Component, OnInit} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {ActivatedRoute, Params} from '@angular/router';
import {ApiService} from '../../../core/services/api.service';
import {finalize, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {AuthService} from '../../../core/services/auth.service';
import {DataStoreService} from '../../../core/services/data-store.service';

@Component({
  selector: 'app-delete-account',
  templateUrl: './delete-account.component.html',
  styleUrls: ['./delete-account.component.css']
})
export class DeleteAccountComponent implements OnInit {

  images = {
    background: environment.imageHost + 'landing/bg_header.jpg',
    logo: environment.imageHost + 'logos/logo_mt_384x384.png'
  };

  loading = true;
  deleted = false;
  error = false;
  invalid = false;

  constructor(private auth: AuthService,
              private route: ActivatedRoute,
              private data: DataStoreService,
              private api: ApiService) {
  }

  ngOnInit() {
    this.route.params
      .pipe(
        switchMap((params: Params) => {
          if (!params['token']) {
            return of(null);
          }
          return this.api.delete('account/' + params['token']);
        }),
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe((data: any) => {
        this.loading = false;
        if (!data) {
          this.invalid = true;
        }
        if (data.msg) {
          this.auth.clear();
          this.data.clear();
          this.deleted = true;
        }
      }, (error) => {
        console.log(error);
        this.error = true;
      });
  }

}
