import {Component} from '@angular/core';
import {faEnvelope} from '@fortawesome/free-solid-svg-icons';
import {environment} from '../../../../environments/environment';
import {ApiService} from '../../../core/services/api.service';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {AlertService} from '../../../core/services/alert.service';
import {Alert} from '../../../shared/models/alert';
import {AlertType} from '../../../shared/enums/alert-type';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent {

  icons = {
    email: faEnvelope
  };

  images = {
    background: environment.imageHost + 'landing/bg_forgot.jpg',
    logo: environment.imageHost + 'logos/logo_mt_384x384.png'
  };

  forgotPasswordForm: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl('', [
      Validators.required,
      Validators.email
    ])
  });

  start = true;
  success: boolean;
  loading = false;

  constructor(private alertService: AlertService,
              private api: ApiService) {
  }

  onSubmit() {
    if (!this.forgotPasswordForm.valid) {
      this.alertService.appendAlert(new Alert(
        'Fehler',
        'Deine Eingabe ist keine E-Mail Adresse!',
        AlertType.ERROR
      ));
      return;
    }

    if (this.loading) {
      return;
    }

    this.loading = true;

    this.api.post('lostpassword', this.forgotPasswordForm.value)
      .subscribe((data: any) => {
        if (data.success === true) {
          this.success = true;
        } else {
          this.alertService.appendAlert(new Alert(
            'Fehler',
            data.msg,
            AlertType.ERROR
          ));
          this.success = false;
        }
        this.start = false;
        this.loading = false;
      });
  }
}
