<nav #header *ngIf="showMenu">
  <mat-toolbar class="toolbar" color="primary">
    <mat-toolbar-row>
      <button matRipple mat-button (click)="snav.toggle()">
        <mat-icon>menu</mat-icon>
      </button>
      <a [routerLink]="['/']"><img [src]="images.logo" [style.height.px]="header.clientHeight - 8">MotiFun</a>
      <div class="desktop">
        <span class="fill-remaining-space"></span>
        <a [routerLink]="['/']"><span>Start</span></a>
        <a [routerLink]="['/login']"><span>Anmelden</span></a>
        <a [routerLink]="['/register']"><span>Registrieren</span></a>
        <a [routerLink]="['/']" fragment="how-to"><span>Wie es funktioniert</span></a>
        <a [routerLink]="['/']" fragment="contact"><span>Kontakt</span></a>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
</nav>
<mat-sidenav-container class="example-sidenav-container">
  <mat-sidenav #snav [mode]="'over'"
               [fixedInViewport]="true" [fixedTopGap]="56">
    <mat-nav-list>
      <div class="logo">
        <button mat-icon-button (click)="snav.toggle()">
          <mat-icon>close</mat-icon>
        </button>
        <a [routerLink]="['/']"><img [src]="images.logoMenu"></a>
      </div>
      <ul>
        <li (click)="snav.toggle()">
          <a [routerLink]="['/']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <fa-icon [icon]="icons.menu.home"></fa-icon>
            <span>Start</span>
          </a>
        </li>
        <li>
          <a [routerLink]="['/login']" routerLinkActive="active">
            <fa-icon [icon]="icons.menu.login"></fa-icon>
            <span>Anmelden</span>
          </a>
        </li>
        <li>
          <a [routerLink]="['/register']" routerLinkActive="active">
            <fa-icon [icon]="icons.menu.register"></fa-icon>
            <span>Registrieren</span>
          </a>
        </li>
        <li>
          <a [routerLink]="['/']" fragment="how-to">
            <fa-icon [icon]="icons.menu.howItWorks"></fa-icon>
            <span>Wie es funktioniert</span>
          </a>
        </li>
        <li>
          <a [routerLink]="['/']" fragment="#contact">
            <fa-icon [icon]="icons.menu.contact"></fa-icon>
            <span>Kontakt</span>
          </a>
        </li>
      </ul>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>


<footer class="footer-motifun flex-col flex-center" *ngIf="showFooter">
  <ul class="flex-row flex-center social">
    <li class="facebook">
      <a class="white-text" href="https://www.facebook.com/motifun" target="_blank">
        <fa-icon [icon]="icons.facebook"></fa-icon>
      </a>
    </li>
    <li class="twitter">
      <a class="white-text" href="https://www.twitter.com/MotiFun" target="_blank">
        <fa-icon [icon]="icons.twitter"></fa-icon>
      </a>
    </li>
    <li class="instagram">
      <a class="white-text" href="https://www.instagram.com/motifun_official/" target="_blank">
        <fa-icon [icon]="icons.instagram"></fa-icon>
      </a>
    </li>
  </ul>
  <ul class="flex-row flex-center links">
    <li class="links">
      <a [routerLink]="['impress']">Impressum</a>
    </li>
    <li class="links">
      <a [routerLink]="['privacy']">Datenschutz</a>
    </li>
  </ul>
  <p>Copyright &copy; MotiFun {{year}}<br>Entwickelt mit
    <fa-icon class="love" [icon]="icons.love"></fa-icon>
    und
    <fa-icon class="coffee" [icon]="icons.coffee"></fa-icon>
    aus Dortmund
  </p>
</footer>
