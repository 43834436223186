import {AfterViewInit, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ApiService} from '../../../core/services/api.service';
import {Event} from '../../../shared/models/event';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import {DataStoreService} from '../../../core/services/data-store.service';
import {BehaviorSubject, merge, Observable, ReplaySubject, Subject, Subscription} from 'rxjs';
import {filter, map, switchMap, tap} from 'rxjs/operators';
import {SearchOptions} from '../../../shared/models/search-options';
import {ScrollService} from '../../../core/services/scroll.service';
import {ResizeService} from '../../../core/services/resize.service';
import {User} from '../../../shared/models/user';
import {AlertService} from '../../../core/services/alert.service';
import {Alert} from '../../../shared/models/alert';
import {EventType} from '../event-type';
import {EventService} from '../../../core/services/event.service';
import {AlertType} from '../../../shared/enums/alert-type';

@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.css']
})
export class EventListComponent implements OnInit, AfterViewInit, OnDestroy {

  end = {
    events: false
  };

  loading = {
    events: true,
    sports: false,
    location: false
  };

  showFilter = true;

  sticky = false;

  private searchOptions: SearchOptions;

  private itemHeight = 291; // height in px of event item
  private numberOfItems = 15; // number of items which are returned on every request

  private optionsChanged$ = new ReplaySubject<SearchOptions>(1); // received new searchOptions, start of pipe

  private resetPage$: Observable<any> = null; // when searchOptions got changed, event array has to be cleared

  private scroll$: Observable<number> = null; // infinite scroll
  private resize$: Observable<number> = null; // resized and more events needed
  private needToLoad$: Subject<number> = new Subject(); // stream for manuel appending events
  // --1--3---1--4---6---4--> // merge of scroll$, resize$ and initial$
  private appendEvents$: Observable<SearchOptions> = null; // returns current searchOptions

  private initialLoad$: BehaviorSubject<number> = new BehaviorSubject(1); // initial load; appends event cache

  private lastEventCount: number;

  private eventsCache$: Observable<Event[]>; // final event array with caches events
  private eventsLoading$: Observable<Event[]>; // final event array with caches events

  private routerEvents$: Subscription;

  events$: Observable<Event[]>; // final event array with loading

  private filterPosition: any;

  @Input() user: User;
  @Input() eventType: EventType;

  @ViewChild('filter', {static: true}) filterElement: ElementRef<HTMLElement>;

  constructor(private api: ApiService,
              private eventService: EventService,
              private router: Router,
              private scroll: ScrollService,
              private resize: ResizeService,
              private data: DataStoreService,
              private alertService: AlertService) {
  }

  ngOnInit(): void {
    this.searchOptions = this.eventService.getSearchOptions(this.eventType);

    const elem = document.body;

    this.resetPage$ = this.optionsChanged$
      .pipe(
        tap((searchOptions: SearchOptions) => {
          this.eventService.removeEvents(this.eventType);
          this.end.events = false;
          this.loading.events = false;
          this.lastEventCount = -1;

          // TODO: check for valid search options! location always needed!
          this.alertService.appendAlert(new Alert('Suchoptionen geändert', 'Deine Anfrage wird an den Server gesendet', AlertType.SUCCESS));

          return searchOptions;
        })
      );

    this.scroll$ = this.scroll.onWindowScrollY(elem, 15, 200, this.itemHeight, this.numberOfItems).pipe(tap(() => console.log('scrolled')));
    this.resize$ = this.resize.onScreenResizeY(elem, this.itemHeight, this.numberOfItems).pipe(tap(() => console.log('resized')));

    this.appendEvents$ = merge(this.scroll$, this.resize$, this.needToLoad$)
      .pipe(
        map((page: number) => {
          console.log('load append events: ', page);
          return this.searchOptions;
        })
      );

    this.eventsCache$ = this.initialLoad$
      .pipe(
        switchMap((page: number) => {
          this.loading.events = true;
          window.scrollTo(0, this.eventService.getScrollPosition(this.eventType));
          return this.eventService.getCachedEvents(this.eventType);
        })
      );

    this.eventsLoading$ = merge(this.appendEvents$, this.resetPage$)
      .pipe(
        filter(() => !this.eventService.reachedEventsEnd(this.eventType)),
        switchMap((searchOptions: SearchOptions) => {
          console.log('loadings events');
          this.loading.events = true;
          if (!searchOptions) {

            return [];
          }
          return this.eventService.getEvents(searchOptions, this.eventType);
        })
      );

    this.events$ = merge(this.eventsLoading$, this.eventsCache$)
      .pipe(
        tap((events: Event[]) => {
          this.end.events = this.lastEventCount === events.length;
          this.lastEventCount = events.length;
          this.loading.events = false;

          if ((events.length * this.itemHeight) < window.innerHeight && !this.end.events && events.length > 0) {
            this.needToLoad$.next(events.length % this.numberOfItems);
          }

          return events;
        })
      );

    this.routerEvents$ = this.router.events
      .pipe(
        filter(event => event instanceof NavigationStart || event instanceof NavigationEnd),
      )
      .subscribe((data: any) => {
        this.eventService.setScrollPosition(this.eventType, window.pageYOffset);
      });
  }

  ngAfterViewInit(): void {
    console.log(this.filterElement);
    console.log(this.filterElement.nativeElement.getBoundingClientRect());
    console.log(this.filterElement.nativeElement.offsetTop);
    this.filterPosition = this.filterElement.nativeElement.getBoundingClientRect().top - this.filterElement.nativeElement.offsetTop;
  }

  ngOnDestroy(): void {
    this.routerEvents$.unsubscribe();
  }

  joinEvent(event: Event) {
    if (event.id <= 0) {
      return;
    }

    this.eventService.joinEvent(event)
      .subscribe((e: Event) => {
        if (event.isAttender) {
          event.appendAttender(this.user);
        } else {
          event.removeAttender(this.user);
        }

        if (event.isObserver) {
          event.isObserver = false;
        }
      });
  }

  observeEvent(event: Event) {
    if (event.id <= 0) {
      return;
    }

    const isAttender = event.isAttender;
    this.eventService.observeEvent(event)
      .subscribe((e: Event) => {
        if (isAttender) {
          event.removeAttender(this.user);
        }
      });
  }

  onFilterChange(searchOptions: SearchOptions) {
    this.searchOptions = searchOptions;
    this.optionsChanged$.next(searchOptions);
  }

  @HostListener('window:scroll', ['$event'])
  handleScroll(event: any) {
    const windowScroll = window.pageYOffset;
    this.sticky = windowScroll >= this.filterPosition;
  }

  onEventClick(event: Event) {
    this.router.navigate(['event', event.id])
      .catch((reason) => {
        console.log(reason);
      });
  }
}
