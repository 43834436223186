import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AlertComponent} from './components/alert/alert.component';
import {AlertListComponent} from './components/alert-list/alert-list.component';
import {GeneralDialogComponent} from './components/general-dialog/general-dialog.component';
import {NotFoundComponent} from './components/not-found/not-found.component';
import {SharedModule} from '../shared/shared.module';
import {SignedInComponent} from './components/signed-in/signed-in.component';
import {SignedOutComponent} from './components/signed-out/signed-out.component';
import {StartComponent} from './components/start/start.component';
import {StartSignedInComponent} from './components/start-signed-in/start-signed-in.component';
import {StartSignedOutComponent} from './components/start-signed-out/start-signed-out.component';
import {ActivateAccountComponent} from './components/activate-account/activate-account.component';
import {LoginModule} from '../modules/login/login.module';
import {RegisterModule} from '../modules/register/register.module';
import {EventsModule} from '../modules/events/events.module';
import {ScrollToModule} from '../modules/scroll-to/scroll-to.module';


@NgModule({
  declarations: [
    ActivateAccountComponent,
    AlertComponent,
    AlertListComponent,
    GeneralDialogComponent,
    NotFoundComponent,
    SignedInComponent,
    SignedOutComponent,
    StartComponent,
    StartSignedInComponent,
    StartSignedOutComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ScrollToModule,
    EventsModule,
    LoginModule,
    RegisterModule,
  ],
  exports: [
    ActivateAccountComponent,
    AlertComponent,
    AlertListComponent,
    GeneralDialogComponent,
    NotFoundComponent,
    SignedInComponent,
    SignedOutComponent,
    StartComponent,
    StartSignedInComponent,
    StartSignedOutComponent
  ]
})
export class CoreModule {
}
