import {Component, Input, OnInit} from '@angular/core';
import {Message} from '../../../shared/models/message';

@Component({
  selector: 'app-chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.css']
})
export class ChatMessageComponent {

  @Input() message: Message;

  constructor() {
  }
}
