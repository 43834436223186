import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {ApiService} from './api.service';
import {map, switchMap} from 'rxjs/operators';
import {User} from '../../shared/models/user';
import {Event} from '../../shared/models/event';
import {EventType} from '../../modules/events/event-type';
import {SearchOptions} from '../../shared/models/search-options';
import {Comment} from '../../shared/models/comment';
import {UserService} from './user.service';
import {ICreateEvent} from '../../shared/interface/ICreateEvent';


@Injectable({
  providedIn: 'root'
})
export class EventService {

  private end = {
    events: false,
    createdEvents: false,
    joinedEvents: false,
    observedEvents: false,
    passedEvents: false
  };

  private scrollPosition = {
    search: 0,
    created: 0,
    joined: 0,
    observed: 0,
    passed: 0
  };

  private searchOptions = {
    search: new SearchOptions(),
    created: new SearchOptions(),
    joined: new SearchOptions(),
    observed: new SearchOptions(),
    passed: new SearchOptions()
  };

  private events: any = {};

  private createdEvents: Event[] = [];
  private searchedEvents: Event[] = [];
  private observedEvents: Event[] = [];
  private attendedEvents: Event[] = [];
  private passedEvents: Event[] = [];

  constructor(private api: ApiService,
              private userService: UserService) {
  }

  getEvents(searchOptions: SearchOptions, type: EventType): Observable<Event[]> {
    const payload = searchOptions ? searchOptions.getPayload(this.getEventIds(this.getArrayByType(type))) : null;
    switch (type) {
      case EventType.SEARCHED:
        return this.getSearchedEvents(payload);
      case EventType.CREATED:
        return this.getCreatedEvents(payload);
      case EventType.JOINED:
        return this.getJoinedEvents(payload);
      case EventType.OBSERVED:
        return this.getObservedEvents(payload);
      case EventType.PASSED:
        return this.getPassedEvents(payload);
      default:
        return of([]);
    }
  }

  getCachedEvents(type: EventType) {
    switch (type) {
      case EventType.SEARCHED:
        return this.getCachedSearchedEvents();
      case EventType.CREATED:
        return this.getCachedCreatedEvents();
      case EventType.JOINED:
        return this.getCachedJoinedEvents();
      case EventType.OBSERVED:
        return this.getCachedObservedEvents();
      case EventType.PASSED:
        return this.getCachedPassedEvents();
    }
  }

  removeEvents(type: EventType) {
    switch (type) {
      case EventType.SEARCHED:
        return this.removeSearchedEvents();
      case EventType.CREATED:
        return this.removeCreatedEvents();
      case EventType.JOINED:
        return this.removeJoinedEvents();
      case EventType.OBSERVED:
        return this.removeObservedEvents();
      case EventType.PASSED:
        return this.removePassedEvents();
    }
  }

  getSearchOptions(type: EventType) {
    switch (type) {
      case EventType.SEARCHED:
        return this.searchOptions.search;
      case EventType.CREATED:
        return this.searchOptions.created;
      case EventType.JOINED:
        return this.searchOptions.joined;
      case EventType.OBSERVED:
        return this.searchOptions.observed;
      case EventType.PASSED:
        return this.searchOptions.passed;
    }
  }

  setSearchOptions(type: EventType, options: SearchOptions) {
    switch (type) {
      case EventType.SEARCHED:
        return this.searchOptions.search = options;
      case EventType.CREATED:
        return this.searchOptions.created = options;
      case EventType.JOINED:
        return this.searchOptions.joined = options;
      case EventType.OBSERVED:
        return this.searchOptions.observed = options;
      case EventType.PASSED:
        return this.searchOptions.passed = options;
    }
  }

  private removeSearchedEvents() {
    this.end.events = false;
    this.searchedEvents = [];
  }

  private removeCreatedEvents() {
    this.end.createdEvents = false;
    this.createdEvents = [];
  }

  private removeJoinedEvents() {
    this.end.joinedEvents = false;
    this.attendedEvents = [];
  }

  private removeObservedEvents() {
    this.end.observedEvents = false;
    this.observedEvents = [];
  }

  private removePassedEvents() {
    this.end.passedEvents = false;
    this.passedEvents = [];
  }

  private getCachedSearchedEvents() {
    return of(this.searchedEvents);
  }

  private getCachedCreatedEvents() {
    return of(this.createdEvents);
  }

  private getCachedJoinedEvents() {
    return of(this.attendedEvents);
  }

  private getCachedObservedEvents() {
    return of(this.observedEvents);
  }

  private getCachedPassedEvents() {
    return of(this.passedEvents);
  }

  private getSearchedEvents(payload: any): Observable<Event[]> {
    // TODO: maybe check payload.. but don't know. needs to be discussed!

    if (this.end.events) {
      console.log('reached');
      // TODO: alert no location set.
      return of([]);
    }

    return this.api.get<any>('event', payload)
      .pipe(
        map((data: any) => {
          return this.mapEvents(data, EventType.SEARCHED);
        })
      );
  }

  private getCreatedEvents(payload?: any): Observable<Event[]> {
    if (payload) {
      return this.api.get('event/created', payload)
        .pipe(
          map((data: any) => {
            return this.mapEvents(data, EventType.CREATED);
          })
        );
    }

    return this.userService.getUser()
      .pipe(
        switchMap((user: User) => {
          const url = 'event/created';
          const params = {
            lat: user.currentLocation.lat,
            lng: user.currentLocation.lng,
            s: this.createdEvents.length
          };
          return this.api.get(url, params);
        }),
        map((data: any) => {
          return this.mapEvents(data, EventType.CREATED);
        })
      );
  }

  private getJoinedEvents(payload: any): Observable<Event[]> {
    // TODO: maybe check payload.. but don't know. needs to be discussed!

    if (this.end.events) {
      console.log('reached');
      // TODO: alert no location set.
      return of([]);
    }

    return this.api.get<any>('event/joined', payload)
      .pipe(
        map((data: any) => {
          return this.mapEvents(data, EventType.JOINED);
        })
      );
  }

  private getObservedEvents(payload: any): Observable<Event[]> {
    // TODO: maybe check payload.. but don't know. needs to be discussed!

    if (this.end.observedEvents) {
      console.log('reached');
      // TODO: alert no location set.
      return of([]);
    }

    return this.api.get<any>('event/observed', payload)
      .pipe(
        map((data: any) => {
          return this.mapEvents(data, EventType.OBSERVED);
        })
      );
  }

  private getPassedEvents(payload: any): Observable<Event[]> {
    // TODO: maybe check payload.. but don't know. needs to be discussed!

    if (this.end.passedEvents) {
      console.log('reached');
      // TODO: alert no location set.
      return of([]);
    }

    return this.api.get<any>('event/passed', payload)
      .pipe(
        map((data: any) => {
          return this.mapEvents(data, EventType.PASSED);
        })
      );
  }

  joinEvent(event: Event): Observable<Event> {
    return this.api.get<any>('event/' + event.id + '/join')
      .pipe(
        map((data: any) => {
          if (data.joined === true || data.joined === 1) {
            event.isAttender = true;
          } else {
            event.isAttender = false;
          }

          if (event.isObserver) {
            event.isObserver = false;
          }
          return event;
        })
      );
  }

  observeEvent(event: Event): Observable<Event> {
    return this.api.get<any>('event/' + event.id + '/observe')
      .pipe(
        map((data) => {
          event.isObserver = data.observed === true || data.observed === 1;
          if (event.isAttender) {
            event.isAttender = false;
          }

          return event;
        })
      );
  }

  createEvent(payload: ICreateEvent): Observable<Event> {
    return this.api.post<Event>('event', payload)
      .pipe(
        map((data: any) => {
          const event = new Event().deserialize(data);

          return event;
        })
      );
  }

  getEvent(id: number): Observable<Event> {
    if (this.events[id]) {
      return of(this.events[id]);
    }

    return this.api.get<any>('event/' + id)
      .pipe(
        map((data: any) => {
          if (!data) {
            return null;
          }

          const event = new Event().deserialize(data);
          event.attendees.push(event.creator);

          this.events[event.id] = event;

          return event;
        })
      );
  }

  updateEvent(event: Event): Observable<Event> {
    if (!event) {
      return null;
    }

    const payload = {
      startTime: event.time,
      skill: event.skill,
      location: event.location,
      title: event.title,
      description: event.description
    };

    return this.api.put('event/' + event.id, payload)
      .pipe(
        map((data: any) => {
          event = event.deserialize(data);
          event.attendees = this.events[event.id].attendees;
          event.comments = this.events[event.id].comments;

          this.events[event.id] = event;

          return event;
        })
      );
  }

  updateEventAbout(event: Event) {
    // TODO:
  }

  getAttendants(event: Event): Observable<Event> {
    if (!event || event.attendantsEnd) {
      return of(event);
    }
    let params = '';
    const last = event.attendees.length - 1; // cause added creator as user
    if (last > 0) {
      params = `?skip=${last}`;
    }
    return this.api.get<any>('event/' + event.id + '/attendees' + params)
      .pipe(
        map(data => {
          if (!data || data.length === 0) {
            event.attendantsEnd = true;
            return event;
          }

          event.appendAttendees(data);

          return event;
        })
      );
  }

  getComments(event: Event): Observable<Event> {
    if (!event || event.commentsEnd) {
      return of(event);
    }
    const id = event.comments.length > 0 ? event.comments[event.comments.length - 1].id : 0;
    let params = '';
    if (id && id > 0) {
      params = `?id=${id}`;
    }
    return this.api.get<any[]>('event/' + event.id + '/comments' + params)
      .pipe(
        map(data => {
          const comments = [];
          for (const comment of data) {
            const c = new Comment().deserialize(comment);
            comments.push(c);
          }

          event.appendComments(comments);

          if (comments.length <= 0) {
            event.commentsEnd = true;
          }

          return event;
        })
      );
  }

  getComment(event: Event, id: number) {
    if (!event) {
      return null;
    }

    return this.api.get(`event/${event.id}/comments/${id}`)
      .pipe(
        map((data) => {
          if (!data) {
            return null;
          }

          const comment = new Comment().deserialize(data);

          return comment;
        })
      );
  }

  private setEndByType(type: EventType) {
    switch (type) {
      case EventType.SEARCHED:
        return this.end.events = true;
      case EventType.CREATED:
        return this.end.createdEvents = true;
      case EventType.JOINED:
        return this.end.joinedEvents = true;
      case EventType.OBSERVED:
        return this.end.observedEvents = true;
      case EventType.PASSED:
        return this.end.passedEvents = true;
    }
  }

  private getArrayByType(type: EventType) {
    switch (type) {
      case EventType.SEARCHED:
        return this.searchedEvents;
      case EventType.CREATED:
        return this.createdEvents;
      case EventType.JOINED:
        return this.attendedEvents;
      case EventType.OBSERVED:
        return this.observedEvents;
      case EventType.PASSED:
        return this.passedEvents;
      default:
        return [];
    }
  }

  private mapEvents(data: any, type: EventType): Event[] {
    if (!data || data.length === 0) {
      this.setEndByType(type);
      return this.getArrayByType(type);
    }

    const resultArray = this.getArrayByType(type);

    let e;
    for (const event of data) {
      e = new Event().deserialize(event);
      resultArray.push(e);
    }

    return resultArray;
  }


  private getEventIds(events: Event[]) {
    const ids: number[] = [];
    for (const e of events) {
      ids.push(e.id);
    }

    return ids;
  }

  setScrollPosition(type: EventType, position: number) {
    switch (type) {
      case EventType.SEARCHED:
        return this.scrollPosition.search = position;
      case EventType.CREATED:
        return this.scrollPosition.created = position;
      case EventType.JOINED:
        return this.scrollPosition.joined = position;
      case EventType.OBSERVED:
        return this.scrollPosition.observed = position;
      case EventType.PASSED:
        return this.scrollPosition.passed = position;
    }
  }

  getScrollPosition(type: EventType) {
    switch (type) {
      case EventType.SEARCHED:
        return this.scrollPosition.search;
      case EventType.CREATED:
        return this.scrollPosition.created;
      case EventType.JOINED:
        return this.scrollPosition.joined;
      case EventType.OBSERVED:
        return this.scrollPosition.observed;
      case EventType.PASSED:
        return this.scrollPosition.passed;
    }
  }

  reachedEventsEnd(type: EventType): boolean {
    switch (type) {
      case EventType.SEARCHED:
        return this.end.events;
      case EventType.CREATED:
        return this.end.createdEvents;
      case EventType.JOINED:
        return this.end.joinedEvents;
      case EventType.OBSERVED:
        return this.end.observedEvents;
      case EventType.PASSED:
        return this.end.passedEvents;
      default:
        return true;
    }
  }

  clear() {
    this.end = {
      events: false,
      createdEvents: false,
      joinedEvents: false,
      observedEvents: false,
      passedEvents: false
    };

    this.scrollPosition = {
      search: 0,
      created: 0,
      joined: 0,
      observed: 0,
      passed: 0
    };

    this.searchOptions = {
      search: new SearchOptions(),
      created: new SearchOptions(),
      joined: new SearchOptions(),
      observed: new SearchOptions(),
      passed: new SearchOptions()
    };

    this.events = {};

    this.createdEvents = [];
    this.searchedEvents = [];
    this.observedEvents = [];
    this.attendedEvents = [];
    this.passedEvents = [];
  }
}
