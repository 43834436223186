import {Deserializable} from './deserializable.model';
import {environment} from '../../../environments/environment';

export class Settings implements Deserializable {

  backgroundImage: string;
  backgroundColor: string;
  isDarkMode: boolean;
  language: string;
  timezone: string;

  constructor() {
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  clone() {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
  }

  getImgPath() {
    if (this.backgroundImage === 'none') {
      return '';
    }
    return environment.imageHost + 'background/' + this.backgroundImage;
  }

  getBackgroundClass() {
    return this.isDarkMode ? 'background-dark' : 'background-lite';
  }

  getTextColor() {
    return this.isDarkMode ? 'lite-text' : 'dark-text';
  }
}
