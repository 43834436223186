import {Deserializable} from './deserializable.model';
import {DateTime} from 'luxon';

export class Message implements Deserializable {

  id: string;
  text: string;
  time: DateTime;
  received: string;
  sender: string;
  read: string;
  type: string;

  constructor() {
  }

  deserialize(input: any): this {
    if (!input) {
      return this;
    }
    Object.assign(this as any, input);
    this.time = DateTime.fromISO(input.time);
    return this;
  }

  setType(userId: string) {
    this.type = this.sender === userId ? 'sent' : 'received';
  }

  getChatTime(): string {
    switch (this.time.diff(DateTime.local(), 'day').days) {
      case 0:
        return this.time.toRelativeCalendar({locale: window.navigator.language}).split(' ')[0];
      case 1:
        return this.time.toRelativeCalendar({locale: window.navigator.language}).split(' ')[0];
      default:
        return this.time.toJSDate().toLocaleString();
    }
  }

  getTime() {
    return this.time.toFormat('HH:mm');
  }

  clone() {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this as any);
  }
}
