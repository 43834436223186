import {Component, Input} from '@angular/core';
import {User} from '../../../shared/models/user';
import {FriendState} from '../../../shared/enums/friend-state';
import {faEnvelope, faUserCheck, faUserCircle, faUserMinus, faUserPlus, faUserTimes} from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-event-attendees',
  templateUrl: './event-attendees.component.html',
  styleUrls: ['./event-attendees.component.css']
})
export class EventAttendeesComponent {

  @Input() attender: User;
  @Input() mode: boolean;
  @Input() eventCreatorId: string;
  @Input() ownUserId: string;

  icons = {
    chat: faEnvelope,
    addFriend: faUserPlus,
    removeFriend: faUserMinus,
    cancelRequest: faUserTimes,
    acceptRequest: faUserCheck,
    userTest: faUserCircle
  };

  requestSent = FriendState.REQUEST_SENT;

  constructor() {
  }

  getFriendsIcon() {
    switch (this.attender.userRelation) {
      case FriendState.FRIENDS:
        return this.icons.removeFriend;
      case FriendState.NO_FRIENDS:
        return this.icons.addFriend;
      case FriendState.REQUEST_RECEIVED:
        return this.icons.removeFriend;
      case FriendState.REQUEST_SENT:
        return this.icons.acceptRequest;
      default:
        return this.icons.userTest;
    }
  }

  onAddFriend() {

  }

  onRemoveFriend() {

  }
}
