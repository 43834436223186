import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {shareReplay} from 'rxjs/operators';
import {GoogleMaps} from '../interfaces/google-maps';

@Injectable({
  providedIn: 'root'
})
export class GoogleMapsService {

  private map$: Subject<GoogleMaps> = new Subject<GoogleMaps>();

  constructor() {
  }

  setMap(data: GoogleMaps) {
    this.map$.next(data);
  }

  getMap() {
    return this.map$.pipe(shareReplay(1));
  }
}
