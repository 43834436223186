<div class="scrollable-content" *ngIf="(settings$ | async) as s">
  <div class="flex-center motifun-box">
    <span class="title">Einstellungen</span>
  </div>

  <div class="flex-row">
    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Passwort ändern
          </mat-panel-title>
        </mat-expansion-panel-header>
        <form [formGroup]="passwordForm" (submit)="updatePassword()">

          <div class="flex-row">
            <mat-form-field class="col-10">
              <input matInput
                     formControlName="oldPassword"
                     type="password"
                     name="old_password"
                     placeholder="Altes Passwort"
                     required>
            </mat-form-field>
          </div>

          <div class="flex-row">
            <mat-form-field class="col-10">
              <input matInput
                     formControlName="newPassword"
                     type="password"
                     name="new_password"
                     placeholder="Neues Passwort"
                     required>
            </mat-form-field>
          </div>

          <div class="flex-row">
            <mat-form-field class="col-10">
              <input matInput
                     formControlName="newPasswordConfirm"
                     type="password"
                     name="new_password_confirm"
                     placeholder="Neues Passwort bestätigen"
                     required>
            </mat-form-field>
          </div>

          <div class="flex-row">
            <button mat-raised-button color="primary" class="fill-remaining-space">Passwort ändern</button>
          </div>

        </form>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            E-Mail ändern
          </mat-panel-title>
        </mat-expansion-panel-header>
        <form [formGroup]="emailForm" (submit)="updateEmail()">

          <mat-form-field class="flex-row col-10">
            <input matInput
                   formControlName="email"
                   type="email"
                   name="email"
                   placeholder="Neue E-Mail"
                   required>
          </mat-form-field>

          <div class="flex-row">
            <button mat-raised-button color="primary" class="fill-remaining-space">Passwort ändern</button>
          </div>

        </form>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Sprache ändern
          </mat-panel-title>
        </mat-expansion-panel-header>
        <form [formGroup]="languageForm" (submit)="updateLanguage()">
          <mat-form-field class="flex-row col-10">
            <mat-select name="language" placeholder="Sprache wählen" formControlName="language" required>
              <mat-option *ngFor="let language of languages" [value]="[language.isoCode]">
                {{language.emoji}}{{language.nativeName}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <div class="flex-row">
            <button mat-raised-button color="primary" class="fill-remaining-space">Sprache ändern</button>
          </div>
        </form>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Blockierte Nutzer
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="row">
          <div class="col s10">
            <!-- TODO: virtual scroll -->
            <ul>
              <li
                *ngFor="let user of blockedUsers"
              >
                <app-blocked-user-item [user]="user"
                                       (removeBlocking)="handleRemoveBlocking($event)"></app-blocked-user-item>
              </li>
            </ul>
          </div>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Angemeldete Geräte
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="row">
          <div class="col s10">
            <cdk-virtual-scroll-viewport
              itemSize="60"
              (scrolledIndexChange)="getNextSessions($event)"
              class="vscoll-viewport"
              #sessionViewport>
              <ul>
                <li *cdkVirtualFor="let session of subscriptions?.sessions | async"
                    class="session-item">
                  <app-session-item [session]="session"
                                    (removeSession)="handleRemoveSession($event)"></app-session-item>
                </li>
                <ng-container *ngIf="loading.sessions">
                  <li>
                    <mat-progress-spinner
                      class=""
                      color="primary"
                      mode="indeterminate">
                    </mat-progress-spinner>
                  </li>
                </ng-container>
                <ng-container *ngIf="end.sessions">
                  <li><p>Keine weiteren Sessions vorhanden</p></li>
                </ng-container>
              </ul>
            </cdk-virtual-scroll-viewport>
          </div>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Theme
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="row">
          <mat-slide-toggle color="primary" [(ngModel)]="s.isDarkMode" (change)="onDarkMode($event)">Nachtmodus
          </mat-slide-toggle>
        </div>
        <div class="row">
          <ul class="inline-list">
            <li *ngFor="let color of colors" class="theme-item">
                  <span class="color-effect d-block rounded-circle dark-border" [ngClass]="color"
                        (click)="setTheme($event, color)"></span>
            </li>
          </ul>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Hintergrund
          </mat-panel-title>
        </mat-expansion-panel-header>
        <!-- TODO: virtual scroll -->
        <div class="row">
          <ul class="inline-list">
            <li
              *ngFor="let image of images">
              <div class="col s10">
                <div *ngIf="image === '00.jpeg' || image === 'none'" class="rounded"
                     (click)="selectImage($event, image)"></div>
                <img *ngIf="image !== '00.jpeg' && image !== 'none'" [src]="imgHost + image" class="rounded"
                     (click)="selectImage($event, image)">
              </div>
            </li>
          </ul>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
