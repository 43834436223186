import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NotificationListComponent} from './notification-list/notification-list.component';

const notificationRoutes: Routes = [
  {
    path: '',
    component: NotificationListComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(notificationRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class NotificationsRoutingModule {
}
