<div id="create">

  <div class="motifun-box" id="progress">
    <div class="title-box">
      <h3>In 3 Schritten zum eigenen Event</h3>
      <hr>
    </div>
    <div class="progress">
      <mat-progress-bar [value]="100/progressMax*progress" mode="determinate"></mat-progress-bar>
    </div>
  </div>

  <form *ngIf="user$ | async as user" [formGroup]="eventGroup">
    <div class="desktop">
      <app-event-form-desktop [eventGroup]="eventGroup"
                              [user]="user"></app-event-form-desktop>

      <button (click)="submitEvent()" [disabled]="eventGroup.invalid" class="col s10" color="primary"
              mat-raised-button
              type="submit">Event
        erstellen
      </button>
    </div>
    <div class="tablet">
      <app-event-form-tablet (created)="onSubmitEvent($event)"
                             [eventGroup]="eventGroup"
                             [user]="user"></app-event-form-tablet>
    </div>
    <div class="mobile">
      <app-event-form-mobile (created)="onSubmitEvent($event)"
                             [eventGroup]="eventGroup"
                             [user]="user"></app-event-form-mobile>
    </div>
  </form>


  <div class="motifun-box flex col" id="recent">

    <div class="title-box">
      <h3>Deine zuletzt erstellten Events</h3>
      <hr>
    </div>
    <div class="flex row center">
      <div class="nav-arrows">
        <fa-icon [icon]="icons.arrowLeft"></fa-icon>
      </div>

      <ul class="list" *ngIf="recentEvents$ | async as events">
        <cdk-virtual-scroll-viewport
          itemSize="350"
          orientation="horizontal"
          class="horizontal"
          (scrolledIndexChange)="getNextRecentEvents($event)"
          #recentEventsViewport>
          <li *cdkVirtualFor="let e of events" class="event-item">
            <a [routerLink]="['event', e.id]"></a>
            <app-event-item (copied)="onCopyEvent(e)" [event]="e" [type]="eventType"></app-event-item>
          </li>
          <li *ngIf="recentEventLoading">
            <mat-progress-spinner
              color="primary"
              mode="indeterminate">
            </mat-progress-spinner>
          </li>
        </cdk-virtual-scroll-viewport>
      </ul>

      <div class="nav-arrows">
        <fa-icon [icon]="icons.arrowRight"></fa-icon>
      </div>
    </div>
  </div>

</div>
