import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class ApiService {

  private baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) {
  }

  get<T>(url: string, parameters?: any): Observable<any> {

    let params;
    if (parameters) {
      params = {
        params: parameters
      };
    }

    return this.http.get<T>(this.baseUrl + url, params);
  }

  getOther<T>(url: string, parameters?: any): Observable<any> {
    const params = new HttpParams({
      fromObject: parameters
    });

    const q = params.toString().length > 0 ? '?' + params.toString() : '';

    return this.http.get<T>(url + q);
  }

  post<T>(url: string, body: Object): Observable<any> {
    return this.http.post<T>(this.baseUrl + url, body);
  }

  uploadImage2<T>(url: string, data: FormData): Observable<any> {
    return this.http.post<T>(environment.apiUrl + url, data);
  }

  uploadImage<T>(url: string, images: File[]): Observable<any> {
    const formData = new FormData();

    for (const image of images) {
      formData.append('image', image);
    }

    return this.http.post<T>(environment.apiUrl + url, formData);
  }

  delete<T>(url: string): Observable<any> {
    return this.http.delete<T>(this.baseUrl + url);
  }

  put<T>(url: string, body: Object): Observable<any> {
    return this.http.put<T>(this.baseUrl + url, body);
  }
}
