<div class="flex-col motifun-box" style="width: 225px; height: 275px; margin: 0.8rem; padding: 0.8rem;">
  <div style="display: flex; justify-content: center;">
    <div class="img small circle link" [ngStyle]="{'background-image': 'url(' + attender?.getMedImgUrl() + ')'}"
         [routerLink]="['/user', attender.id]"></div>
  </div>
  <div style="flex: auto;">
    <div class="text-overflow" style="font-size: 1.05em; text-align: center;">
      {{attender.getFullName()}}
    </div>
    <div class="motifun text" style="text-align: center; font-size: 0.875em;">
      {{attender.id === eventCreatorId && attender.id !== ownUserId ? 'Initiator' : ''}}
      {{attender.id === ownUserId ? 'Du' : ''}}
    </div>
  </div>
  <div style="font-size: 0.75em;margin: 10px 0;padding-bottom: 30px;border-bottom: 2px solid #cecece;">
    <div class="inner-row flex-row" *ngIf="attender.age">
      <span class="col-4 margin" style="text-align: right; padding-right: 5px;">Alter</span>
      <span class="col-6 margin" style="padding-left: 5px;">{{attender.age}} Jahre</span>
    </div>
    <div class="inner-row flex-row" *ngIf="attender.city">
      <span class="col-4 margin" style="text-align: right; padding-right: 5px;">Ort</span>
      <span class="col-6 margin text-overflow" style="padding-left: 5px;">{{attender.city}}</span>
    </div>
    <div class="inner-row flex-row">
      <span class="col-4 margin" style="text-align: right; padding-right: 5px;">Letzter Login</span>
      <span class="col-6 margin" style="padding-left: 5px;">{{attender.getLastSignIn()}}</span>
    </div>
  </div>
  <div class="inner-row flex-row flex-center" style="font-size: 1.5em; min-height: 36px;">
    <a class="link" style="padding: 0 0.8rem;" *ngIf="attender.id !== ownUserId"
       [routerLink]="['/messages', attender.id]">
      <fa-icon [icon]="icons.chat"></fa-icon>
    </a>
    <a class="link" style="padding: 0 0.8rem;" *ngIf="attender.id !== ownUserId" (click)="onAddFriend()">
      <fa-icon [icon]="getFriendsIcon()"></fa-icon>
    </a>
    <a class="link" *ngIf="attender.userRelation === requestSent" (click)="onRemoveFriend()">
      <fa-icon [icon]="icons.cancelRequest"></fa-icon>
    </a>
  </div>
</div>
