import {SportCategory} from './sport-category';
import {Deserializable} from './deserializable.model';
import {environment} from '../../../environments/environment';

export class Sport implements Deserializable {

  id: number;
  category: SportCategory;
  name: string;
  backgroundImage: string;

  constructor() {
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    if (input.category) {
      this.category = new SportCategory().deserialize(input.category);
    }

    if (input.name) {
      this.name = input.name.name;
    }
    if (input.nameClean) {
      this.backgroundImage = input.nameClean + '_back.jpg';
    }
    return this;
  }

  getImgUrl() {
    if (!this.backgroundImage) {
      // TODO: dummy img
      return '';
    }
    return environment.sportImgUrl + this.backgroundImage;
  }

  clone() {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
  }
}
