import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject, Subscription} from 'rxjs';
import {shareReplay, tap} from 'rxjs/operators';

@Component({
  selector: 'app-base-list-wrapper',
  template: ''
})
export class BaseListWrapperComponent<T, R> implements OnInit, OnDestroy {

  // data that comes from items$ and is set in itemsSubscription
  items: T;
  // variable that determines whether loading or not
  loading = false;
  // filter options/params
  protected options: R;
  // will be submitted when filter changed
  protected params$: Subject<R> = new Subject<R>();
  // is set by inherited component with data stream
  protected data$: Observable<T>;
  // Observable that contains the result of data$ and stops loading items
  private items$: Observable<T>;
  // Subscription of items$
  private itemsSubscription: Subscription;

  constructor() {
  }

  ngOnInit() {
    this.items$ = this.data$
      .pipe(
        tap((items: T) => {
          this.loading = false;
        }),
        shareReplay(1)
      );

    this.itemsSubscription = this.items$
      .subscribe((items: T) => {
        this.items = items;
      });
  }

  ngOnDestroy(): void {
    this.params$.complete();
    if (this.itemsSubscription) {
      this.itemsSubscription.unsubscribe();
    }
  }

  onFetchMore() {
    this.loading = true;
    this.params$.next(this.options);
  }

  onFilterChanged(options: R) {
    this.loading = true;
    this.options = options;
    this.params$.next(options);
  }
}
