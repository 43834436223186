import {Component, Input} from '@angular/core';
import {User} from '../../../shared/models/user';
import {UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'app-event-form-desktop',
  templateUrl: './event-form-desktop.component.html',
  styleUrls: ['./event-form-desktop.component.scss']
})
export class EventFormDesktopComponent {

  @Input() user: User;
  @Input() eventGroup: UntypedFormGroup;

  constructor() {
  }
}
