<section id="top" class="fullscreen img flex-center"
         [ngStyle]="{'background-image': 'url(' + images.background + ')'}">
  <div id="form-container" [@simpleFadeAnimation]="'in'">
    <div class="logo-container grow" [routerLink]="['/']">
      <div class="logo" [ngStyle]="{'background-image': 'url(' + images.logo + ')'}"></div>
    </div>
    <div class="motifun-box section">
      <p class="section-headline">Anmelden</p>
      <app-login-form></app-login-form>
    </div>
  </div>
</section>
