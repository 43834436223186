import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {UserRoutingModule} from './user-routing.module';
import {UserItemComponent} from './user-item/user-item.component';
import {UserComponent} from './user/user.component';
import {UserFavoriteSportsDialogComponent} from './user-favorite-sports-dialog/user-favorite-sports-dialog.component';
import {UserImgDialogComponent} from './user-img-dialog/user-img-dialog.component';
import {UserActivityItemComponent} from './user-activity-item/user-activity-item.component';
import {FriendItemComponent} from './friend-item/friend-item.component';
import {FriendListComponent} from './friend-list/friend-list.component';
import {FriendRequestItemComponent} from './friend-request-item/friend-request-item.component';
import {FriendRequestListComponent} from './friend-request-list/friend-request-list.component';
import {AuthGuard} from '../../core/guards/auth.guard';
import {StartComponent} from './start/start.component';
import {UserInfoComponent} from './user-info/user-info.component';
import {UserActivitiesComponent} from './user-activities/user-activities.component';
import {UserImagePreviewDialogComponent} from './user-image-preview-dialog/user-image-preview-dialog.component';
import {SharedModule} from '../../shared/shared.module';

@NgModule({
  declarations: [
    FriendItemComponent,
    FriendListComponent,
    FriendRequestItemComponent,
    FriendRequestListComponent,
    UserComponent,
    UserActivityItemComponent,
    UserFavoriteSportsDialogComponent,
    UserImgDialogComponent,
    UserItemComponent,
    StartComponent,
    UserInfoComponent,
    UserActivitiesComponent,
    UserImagePreviewDialogComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    UserRoutingModule
  ],
  exports: [
    UserItemComponent
  ],
  providers: [
    AuthGuard
  ]
})
export class UserModule {
}
