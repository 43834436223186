import {Component, EventEmitter, Input, Output} from '@angular/core';
import {User} from '../../../shared/models/user';
import {ApiService} from '../../../core/services/api.service';
import {faComment, faMapMarker, faUserMinus} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-friend-item',
  templateUrl: './friend-item.component.html',
  styleUrls: ['./friend-item.component.css']
})
export class FriendItemComponent {

  icons = {
    marker: faMapMarker,
    message: faComment,
    removeFriend: faUserMinus
  };

  @Input() user: User;
  @Output() changed: EventEmitter<User> = new EventEmitter<User>();

  constructor(private api: ApiService) {
  }

  removeFriend(user: User) {
    if (!user) {
      return;
    }

    this.api.get<any>('user/' + user.id + '/add')
      .subscribe(data => {
        this.user.userRelation = data.status;
        this.changed.emit(user);
      });
  }
}
