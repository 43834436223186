import {Component, EventEmitter, Input, Output} from '@angular/core';
import {User} from '../../../shared/models/user';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Event} from '../../../shared/models/event';
import {ICreateEvent} from '../../../shared/interface/ICreateEvent';

@Component({
  selector: 'app-event-form-tablet',
  templateUrl: './event-form-tablet.component.html',
  styleUrls: ['./event-form-tablet.component.scss']
})
export class EventFormTabletComponent {

  stepperConfig = {
    linear: true
  };

  @Input() user: User;
  @Input() eventGroup: UntypedFormGroup;

  @Output() created: EventEmitter<ICreateEvent> = new EventEmitter<ICreateEvent>();

  constructor() {
  }

  submitEvent() {
    this.created.emit(Event.formGroupToPayload(this.eventGroup.getRawValue()));
  }

  getInputControl(name: string): UntypedFormControl {
    return this.eventGroup.get(name) as UntypedFormControl;
  }
}
