import {Component} from '@angular/core';
import {AuthService} from '../../../core/services/auth.service';
import {Router} from '@angular/router';
import {ApiService} from '../../../core/services/api.service';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {faFacebookF} from '@fortawesome/free-brands-svg-icons/faFacebookF';
import {faTwitter} from '@fortawesome/free-brands-svg-icons/faTwitter';
import {faInstagram} from '@fortawesome/free-brands-svg-icons/faInstagram';
import {faGoogle} from '@fortawesome/free-brands-svg-icons/faGoogle';
import {AlertService} from '../../../core/services/alert.service';
import {Alert} from '../../../shared/models/alert';
import {catchError} from 'rxjs/operators';
import {of} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';
import {AlertType} from '../../../shared/enums/alert-type';
import {faEnvelope, faKey} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css']
})
export class LoginFormComponent {

  icons = {
    email: faEnvelope,
    password: faKey,
    facebook: faFacebookF,
    instagram: faInstagram,
    google: faGoogle,
    twitter: faTwitter
  };

  loginForm = new UntypedFormGroup({
    email: new UntypedFormControl('', [
      Validators.required,
      Validators.email
    ]),
    password: new UntypedFormControl('', [
        Validators.required
      ]
    )
  });

  constructor(private alert: AlertService,
              private api: ApiService,
              private auth: AuthService,
              public router: Router) {
  }

  onSubmit() {
    if (!this.loginForm.valid) {
      this.alert.appendAlert(new Alert('Fehlerhafte Login Daten', 'Deine Logindaten stimmen nicht', AlertType.ERROR));
      return;
    }

    this.api.post<any>('authenticate', this.loginForm.value)
      .pipe(
        catchError((err: HttpErrorResponse, caught) => {
          switch (err.status) {
            // TODO: check if needed
          }
          return of(null);
        })
      )
      .subscribe((data: { token: string, msg: string }) => {
        if (!data || !data.token) {
          this.router.navigate(['/login'])
            .catch((reason: Error) => console.log(reason));
        } else {
          this.auth.setToken(data.token);

          this.router.navigate(['/'])
            .catch((reason: Error) => console.log(reason));
        }
      });
  }

  handleSignInWithClick() {
    this.alert.appendAlert(new Alert(
        'In Entwicklung',
        'Dieses Feature befindet sich momentan in Entwicklung. Versuche es in ein paar Tagen erneut.',
        AlertType.ERROR
      )
    );
  }
}
