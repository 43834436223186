import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Event, NavigationEnd, Router} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {MatSidenav} from '@angular/material/sidenav';
import {faFacebookF} from '@fortawesome/free-brands-svg-icons/faFacebookF';
import {faInstagram} from '@fortawesome/free-brands-svg-icons/faInstagram';
import {faTwitter} from '@fortawesome/free-brands-svg-icons/faTwitter';
import {faHeart} from '@fortawesome/free-solid-svg-icons/faHeart';
import {faCoffee} from '@fortawesome/free-solid-svg-icons/faCoffee';
import {faHome} from '@fortawesome/free-solid-svg-icons/faHome';
import {faSignInAlt} from '@fortawesome/free-solid-svg-icons/faSignInAlt';
import {faUserPlus} from '@fortawesome/free-solid-svg-icons/faUserPlus';
import {faQuestionCircle} from '@fortawesome/free-solid-svg-icons/faQuestionCircle';
import {faEnvelope} from '@fortawesome/free-solid-svg-icons/faEnvelope';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-signed-out',
  templateUrl: './signed-out.component.html',
  styleUrls: ['./signed-out.component.scss']
})
export class SignedOutComponent implements OnInit, OnDestroy {

  showMenu = false;
  showFooter = true;

  images = {
    logo: environment.imageHost + 'logos/logo_w_ot_160x160.png',
    logoMenu: environment.imageHost + 'logos/logo_w_160x160.png'
  };

  icons = {
    facebook: faFacebookF,
    instagram: faInstagram,
    twitter: faTwitter,
    love: faHeart,
    coffee: faCoffee,
    menu: {
      home: faHome,
      login: faSignInAlt,
      register: faUserPlus,
      howItWorks: faQuestionCircle,
      contact: faEnvelope
    }
  };

  year = new Date().getFullYear();

  private routerSubscription: Subscription;

  @ViewChild('snav', {static: true}) sidenav: MatSidenav;

  constructor(private router: Router) {
  }

  ngOnInit() {
    this.showMenu = document.location.pathname !== '/';

    this.routerSubscription = this.router.events.subscribe((data: Event) => {
      if (data instanceof NavigationEnd) {
        this.showMenu = document.location.pathname !== '/';
        this.sidenav.close();
      }
    });
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
  }
}
