import {Component, Input, OnInit} from '@angular/core';
import {Notification} from '../notification';

@Component({
  selector: 'app-notification-friendship',
  templateUrl: './notification-friendship.component.html',
  styleUrls: ['./notification-friendship.component.css']
})
export class NotificationFriendshipComponent {

  @Input() notification: Notification;

  constructor() {
  }

}
