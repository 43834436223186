import {Injectable} from '@angular/core';
import {User} from '../../shared/models/user';
import {Chat} from '../../shared/models/chat';
import {Message} from '../../shared/models/message';
import {filter, map, share, tap} from 'rxjs/operators';
import {Observable, of, ReplaySubject, Subject} from 'rxjs';
import {ApiService} from './api.service';
import {SocketIOService} from './socketio.service';
import {MessageType} from '../../shared/enums/message-type';


@Injectable({
  providedIn: 'root'
})
export class ChatService {

  private chatsCache: Chat[] = [];
  private cacheSearched: Chat[] = [];

  private manuallyRequestedChats = 0;

  chats$: Observable<Chat[]>;

  private message$: ReplaySubject<Chat> = new ReplaySubject(1);
  private messageRemoved$: ReplaySubject<Chat> = new ReplaySubject(1);
  private unreadMessageCount$: Subject<number> = new Subject<number>();

  private reachedEnd = {
    chats: false,
    searched: false
  };

  private messageEnd: any = {};

  private loading = {
    chats: false,
    search: false
  };

  private counter = 0;

  constructor(private api: ApiService,
              private ioService: SocketIOService) {
  }

  getChats(useCache: boolean = false) {
    if (this.reachedEnd.chats || (useCache && this.chatsCache.length > 0)) {
      // if `data` is available just return it as `Observable`
      return of(this.chatsCache);
    } else if (this.chats$ && this.loading.chats) {
      // if `this.observable` is set then the request is in progress
      // return the `Observable` for the ongoing request
      return this.chats$;
    } else {
      this.loading.chats = true;
      const skip = this.chatsCache.length;
      let params = '';
      if (skip && skip > 0) {
        params = `?c=${skip - this.manuallyRequestedChats}`;
        this.manuallyRequestedChats = this.manuallyRequestedChats > 0 ? this.manuallyRequestedChats - 1 : this.manuallyRequestedChats;
      }
      this.chats$ = this.api.get<Chat[]>(`chat${params}`)
        .pipe(
          map((data: any) => {
            if (!data || data.length < 1) {
              this.reachedEnd.chats = true;
              return this.chatsCache.concat([]);
            }
            let u: User;
            let c: Chat;
            let found: boolean;
            const chats: Chat[] = [];
            for (const chat of data) {
              u = new User().deserialize(chat.user);

              c = new Chat().deserialize(chat.chat);
              c.setUser(u);
              found = false;

              for (const cachedChat of this.chatsCache) {
                if (cachedChat.id === c.id) {
                  found = true;
                  break;
                }
              }

              if (!found) {
                chats.push(c);
              }
            }

            this.chatsCache = this.chatsCache.concat(chats);

            return this.chatsCache;
          }),
          tap(() => this.loading.chats = false),
          share()
        );
      return this.chats$;
    }
  }

  getCachedChats(sort: boolean = false): Chat[] {
    const chats = sort ? this.sortChats() : this.chatsCache;
    return chats.concat([]);
  }

  sortChats() {
    return this.chatsCache.sort((a: Chat, b: Chat) => a.compare(b));
  }

  deleteChat(chat: Chat) {
    for (let i = 0; i < this.chatsCache.length; i++) {
      if (this.chatsCache[i].id === chat.id) {
        this.chatsCache.splice(i, 1);
        break;
      }
    }
  }

  onMessage(): Observable<Chat> {
    return this.message$;
  }

  onMessageRemoved(): Observable<Chat> {
    return this.messageRemoved$;
  }

  appendMessage(chatId: string, message: Message) {
    for (const chat of this.chatsCache) {
      if (chat.id === chatId) {
        // TODO: check if needed
        chat.appendMessage(message);
        this.message$.next(chat);
      }
    }
  }

  setChats(chats: Chat[]) {
    this.chatsCache = chats;
  }

  prependChat(c: Chat) {
    this.chatsCache = [c].concat(this.chatsCache);
  }

  appendChat(c: Chat) {
    this.chatsCache.push(c);
  }

  getChat(userId: string) {
    return this.api.get<any>('chat/' + userId)
      .pipe(
        map(data => {
          if (!data) {
            return null;
          }
          const u = new User().deserialize(data.user);
          const c = new Chat().deserialize(data.chat);

          c.setUser(u);

          this.appendChat(c);

          this.manuallyRequestedChats += 1;

          return c;
        })
      );
  }

  getSearchedChats(query: string) {
    if (query.trim().length <= 0) {
      return of([]);
    }
    let params = '';
    if (this.cacheSearched.length > 0) {
      params = `&s=${this.cacheSearched.length}`;
    }
    return this.api.get<any>('chat/find?q=' + query + params)
      .pipe(
        map((data: any) => {
          if (!data && this.cacheSearched.length < 1) {
            // TODO: tell user nothing found!
            return [];
          }

          let u: User;
          let c: Chat;
          const chats = [];
          for (const d of data) {
            u = new User().deserialize(d.user);
            c = new Chat().deserialize(d.chat);
            c.setUser(u);

            chats.push(c);
          }

          return this.cacheSearched.concat(chats);
        }),
        tap(() => {
          this.loading.search = false;
        })
      );
  }

  getMessages(chat: Chat, user: User): Observable<Message[]> {
    if (!chat || !chat.id) {
      return of([]);
    }
    if (this.messageEnd[chat.id]) {
      return of(chat.messages);
    }
    let params = '';
    if (chat.messages && chat.messages.length > 0) {
      params = `?id=${chat.messages[0].id}`;
    }
    return this.api.get<any>('chat/' + chat.id + '/messages' + params)
      .pipe(
        filter(() => chat != null),
        map((messages: any) => {
          if (!messages || messages.length <= 0) {
            this.messageEnd[chat.id] = true;
          }

          let m: Message;
          for (const message of messages) {
            m = new Message().deserialize(message.message);
            m.setType(user.id);
            chat.prependMessage(m);
          }

          return chat.messages;
        }),
        share()
      );
  }

  onNewMessage() {
    return this.ioService.onMessage()
      .pipe(
        map((data: any) => {
          if (data.type === MessageType.RECEIVED) {
            this.counter++;
            this.unreadMessageCount$.next(this.counter);
          }
          return data;
        }),
        share()
      );
  }

  resetCounter(count: number) {
    this.counter -= count;
    if (this.counter < 0) {
      this.counter = 0;
    }
    this.unreadMessageCount$.next(this.counter);
  }

  onUnreadMessageCount(): Observable<number> {
    return this.unreadMessageCount$;
  }

  getUnreadMessagesCount(): Observable<number> {
    return this.api.get('chat/unread')
      .pipe(
        map((data) => {
          if (data && data.unread) {
            this.counter = data.unread;
          } else {
            this.counter = 0;
          }
          return this.counter;
        })
      );
  }

  isMessageEnd(chatId: string) {
    return this.messageEnd[chatId];
  }

  isChatEnd() {
    return this.reachedEnd.chats;
  }

  isSearchedChatsEnd() {
    return this.reachedEnd.searched;
  }

  clear() {
    this.chatsCache = [];
    this.cacheSearched = [];

    this.manuallyRequestedChats = 0;

    this.reachedEnd = {
      chats: false,
      searched: false
    };

    this.messageEnd = {};

    this.loading = {
      chats: false,
      search: false
    };
  }
}
