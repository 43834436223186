<section id="top" class="fullscreen img flex-center"
         [ngStyle]="{'background-image': 'url(' + images.background + ')'}">
  <div id="form-container" class="animated fadeInUp">
    <div class="logo-container grow" [routerLink]="['/']">
      <div class="logo"
           [ngStyle]="{'background-image': 'url(' + images.logo + ')'}"></div>
    </div>
    <div class="motifun-box section" *ngIf="start">
      <p class="section-headline">Passwort vergessen</p>
      <form class="form" [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()"
            [style.display]="loading ? 'none' : 'flex'">
        <div class="flex-row">
          <mat-form-field class="input-field col-10">
            <fa-icon matPrefix [icon]="icons?.email"></fa-icon>
            <input matInput
                   type="email"
                   name="email"
                   placeholder="E-Mail"
                   formControlName="email">
            <mat-hint>
              E-Mail Adresse deines Accounts
            </mat-hint>
            <mat-error *ngIf="forgotPasswordForm.controls['email'].hasError('required')">
              Die E-Mail Adresse wird <strong>benötigt</strong>
            </mat-error>
            <mat-error *ngIf="forgotPasswordForm.controls['email'].hasError('email')">
              Die eingebene E-Mail ist <strong>keine</strong> gültige E-Mail Adresse.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="flex-row">
          <div class="col-5 flex-center">
            <a class="center" [routerLink]="['/login']"><span>Ich kenne mein Passwort</span></a>
          </div>
          <div class="col-5 flex-center">
            <a class="center" [routerLink]="['/register']"><span>Ich habe noch keinen Account</span></a>
          </div>
        </div>
        <button mat-raised-button
                [disabled]="forgotPasswordForm.invalid && !loading"
                color="primary"
                class="flex-fill-x flex-bottom">Passwort zurücksetzen
        </button>
      </form>
      <div class="flex center" *ngIf="loading">
        <mat-progress-spinner mode="indeterminate" color="primary"></mat-progress-spinner>
      </div>
    </div>
    <div class="motifun-box section center" *ngIf="!start">
      <p *ngIf="success; else error">Wir haben dir eine E-Mail mit einem Link zur Passwortwiederherstellung
        gesendet.</p>
      <ng-template #error>
        <p>Es ist ein Fehler aufgetreten. Bitte versuche es später erneut.</p>
      </ng-template>
    </div>
  </div>
</section>
