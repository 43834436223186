import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ActivateAccountComponent} from './core/components/activate-account/activate-account.component';
import {NotFoundComponent} from './core/components/not-found/not-found.component';
import {NonAuthGuard} from './core/guards/non-auth.guard';
import {AuthGuard} from './core/guards/auth.guard';
import {StartComponent} from './core/components/start/start.component';

const routes: Routes = [
  {
    path: '',
    component: StartComponent,
    pathMatch: 'full'
  },
  {
    path: 'activate/:token',
    component: ActivateAccountComponent
  },
  {
    path: 'login',
    loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule),
    canLoad: [NonAuthGuard],
    canActivate: [NonAuthGuard]
  },
  {
    path: 'register',
    loadChildren: () => import('./modules/register/register.module').then(m => m.RegisterModule),
    canLoad: [NonAuthGuard],
    canActivate: [NonAuthGuard]
  },
  {
    path: 'forgot',
    loadChildren: () => import('./modules/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
    canLoad: [NonAuthGuard],
    canActivate: [NonAuthGuard]
  },
  {
    path: 'impress',
    loadChildren: () => import('./modules/impress/impress.module').then(m => m.ImpressModule)
  },
  {
    path: 'privacy',
    loadChildren: () => import('./modules/privacy/privacy.module').then(m => m.PrivacyModule)
  },
  {
    path: 'delete/:token',
    loadChildren: () => import('./modules/delete/delete.module').then(m => m.DeleteModule),
  },
  {
    path: 'notifications',
    loadChildren: () => import('./modules/notifications/notifications.module').then(m => m.NotificationsModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard]
  },
  {
    path: 'user',
    loadChildren: () => import('./modules/users/user.module').then(m => m.UserModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard]
  },
  {
    path: 'messages',
    loadChildren: () => import('./modules/chat/chat.module').then(m => m.ChatModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard]
  },
  {
    path: 'event',
    loadChildren: () => import('./modules/events/events.module').then(m => m.EventsModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard]
  },
  {
    path: 'settings',
    loadChildren: () => import('./modules/settings/settings.module').then(m => m.SettingsModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    component: NotFoundComponent,
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}
