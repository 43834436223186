<div class="row flex-row wrapper motifun-box">
  <div class="col-fix margin user-img" [ngStyle]="{'background-image': 'url('+ activity.image + ')'}">
    <div class="overlay flex-center">
      <div class="circle-border">
        <fa-icon [icon]="activity.icon"></fa-icon>
      </div>
    </div>
  </div>
  <div class="flex col margin fill">
    <span class="headline">{{activity.headline}}</span>
    <span class="date">{{activity.day}} {{activity.month}}</span>
    <p class="flex text fill-remaining-space center-v">{{activity.text}}</p>
  </div>
</div>
