import {Component, Input} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {User} from '../../../shared/models/user';
import {MatDialog} from '@angular/material/dialog';
import {EventFormMapDialogComponent} from '../event-form-map-dialog/event-form-map-dialog.component';
import {Location} from '../../../shared/models/location';
import {faMapMarker} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-event-form-where',
  templateUrl: './event-form-where.component.html',
  styleUrls: ['./event-form-where.component.scss']
})
export class EventFormWhereComponent {

  errors = {
    place: 'Es muss ein Ort ausgewählt werden. Dafür kannst du entweder einen Punkt auf der Karte markieren oder die Suche benutzen.'
  };

  @Input() formGroup: UntypedFormGroup;
  @Input() user: User;
  @Input() mobileMode: boolean;
  icons = {
    location: faMapMarker
  };

  constructor(private dialog: MatDialog) {
  }

  onChoseLocation() {
    const dialogRef = this.dialog.open(EventFormMapDialogComponent, {
      width: '95%',
      maxWidth: '100%',
      minWidth: '95%',
      panelClass: this.user.settings.isDarkMode ? 'dark-theme' : '',
      data: {
        location: new Location(),
        formGroup: this.formGroup,
        user: this.user
      }
    });

    dialogRef.afterClosed()
      .subscribe((location: Location) => {
        if (!location) {
          return;
        }

        this.patchLocation(location);
      });
  }

  onLocation(location: Location) {
    this.patchLocation(location);
  }

  private patchLocation(location: Location) {
    this.formGroup.patchValue(location.getIEventWhere());
  }
}
