import {Deserializable} from './deserializable.model';

export class Session implements Deserializable {

  id: number;
  city: string;
  country: string;
  updatedAt: Date;
  insertedAt: Date;
  userAgent: string;
  ipv4: string;
  ipv6: string;
  userId: number;
  isCurrentSession: boolean;

  formattedUserAgent = {
    os: {
      name: '',
      version: ''
    },
    device: '',
    browser: {
      name: '',
      version: ''
    },
    engine: {
      name: '',
      version: ''
    }
  };

  constructor() {
  }

  deserialize(input: any): this {
    Object.assign(this as any, input);

    this.createFormattedUserAgent(this.userAgent);

    return this;
  }

  createFormattedUserAgent(userAgent: string) {
    const splitAgent: any = userAgent.toLowerCase().split(')');

    for (let i = 0; i < splitAgent.length; i++) {
      splitAgent[i] = splitAgent[i].trim().split('(');
    }

    if (splitAgent.length === 0) {
      return;
    }

    // parse os/device:
    const os = splitAgent[0].length > 0 ? splitAgent[0].join('') : '';
    if (os.includes('iphone') || os.includes('mobile')) {
      this.formattedUserAgent.device = 'mobile';
    } else if (os.includes('ipad') || os.includes('tablet')) {
      this.formattedUserAgent.device = 'tablet';
    } else if (os.includes('macintosh') || os.includes('windows') || os.includes('linux')) {
      this.formattedUserAgent.device = 'desktop';
    } else {
      this.formattedUserAgent.device = 'unknown';
    }

    if (os.includes('ios') || os.includes('iphone os') || os.includes('ios')) {
      this.formattedUserAgent.os.name = 'apple';
    } else if (os.includes('android')) {
      this.formattedUserAgent.os.name = 'android';
    } else if (os.includes('macintosh') || os.includes('mac os')) {
      this.formattedUserAgent.os.name = 'apple';
    } else if (os.includes('linux')) {
      this.formattedUserAgent.os.name = 'linux';
    } else if (os.includes('windows')) {
      this.formattedUserAgent.os.name = 'windows';
    }

    let text: string[];
    if (splitAgent.length >= 3) {
      const engine = splitAgent[splitAgent.length - 2];
      // engine length - 2
      text = engine.length > 0 ? engine[0].split('/') : ['', 0];
      this.formattedUserAgent.engine.name = text[0];
      this.formattedUserAgent.engine.version = text[1];

      // browser length - 1
      let browser = splitAgent[splitAgent.length - 1];
      browser = browser.length > 0 ? browser[0].split(' ') : [];
      if (browser.length > 1) {
        if (this.containsString(browser, 'version') && this.containsString(browser, 'edgios')) {
          text = browser[1].split('/');
          this.formattedUserAgent.browser.name = text[0];
          this.formattedUserAgent.browser.version = text[1];
        } else if (this.containsString(browser, 'version')) {
          text = browser[browser.length - 1].split('/');
          this.formattedUserAgent.browser.name = text[0];
          this.formattedUserAgent.browser.version = text[1];
        } else {
          text = browser[0].split('/');
          this.formattedUserAgent.browser.name = text[0];
          this.formattedUserAgent.browser.version = text[1];
        }
      } else {
        text = browser.split('/');
        this.formattedUserAgent.browser.name = text[0];
        this.formattedUserAgent.browser.version = text[1];
      }
    } else if (splitAgent.length === 2) {
      const both = splitAgent[1].join('').split(' ');
      const engine = both[0].split('/');
      this.formattedUserAgent.engine.name = engine[0];
      this.formattedUserAgent.engine.version = engine[1];

      const browser = both[1].split('/');
      this.formattedUserAgent.browser.name = browser[0];
      this.formattedUserAgent.browser.version = browser[1];
    }
  }

  getDate(): Date {
    return this.updatedAt ?? this.insertedAt;
  }

  generateIconOSClass() {
    return this.formattedUserAgent.os.name;
  }

  generateIconDeviceClass() {
    return this.formattedUserAgent.device;
  }

  generateIconBrowserClass() {
    if (this.containsStrings(this.formattedUserAgent.browser.name, ['fxios'])) {
      return 'firefox';
    } else if (this.containsStrings(this.formattedUserAgent.browser.name, ['edgios'])) {
      return 'edge';
    } else if (this.containsStrings(this.formattedUserAgent.browser.name, ['crios'])) {
      return 'chrome';
    } else {
      return this.formattedUserAgent.browser.name;
    }
  }

  getIp() {
    return this.ipv4 || this.ipv6;
  }

  getLocation() {
    if (this.city && this.country) {
      return this.city + ', ' + this.country;
    } else if (this.city) {
      return this.city;
    } else if (this.country) {
      return this.country;
    } else {
      return '';
    }
  }

  clone() {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this as any);
  }

  private containsString(arr: string[], find: string): boolean {
    for (const elem of arr) {
      if (elem.includes(find)) {
        return true;
      }
    }
    return false;
  }

  private containsStrings(src: string, strings: string[]) {
    for (const s of strings) {
      if (src.includes(s)) {
        return true;
      }
    }
    return false;
  }
}
