<div class="chat-user flex-row">
  <div class="user-img img small"
       [ngStyle]="{'background-image': 'url(' + chat.user.getLowImgUrl() + ')'}"></div>
  <div class="message-info">
    <span class="name motifun text">{{chat.user.firstName}} {{chat.user.lastName}}</span>
    <p class="message">{{chat.lastMessage?.text}}</p>
    <span class="time">{{chat.formatDate()}}</span>
  </div>
  <div class="sidebar flex-col flex-center">
    <button mat-icon-button [matMenuTriggerFor]="menu" class="chat-settings" (click)="$event.stopPropagation()">
      <fa-icon [icon]="icons.more"></fa-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="deleteChat()">
        <fa-icon [icon]="icons.delete"></fa-icon>
        <span> löschen</span>
      </button>
      <button mat-menu-item (click)="reportChat()">
        <fa-icon [icon]="icons.flag"></fa-icon>
        <span> melden</span>
      </button>
    </mat-menu>
    <div class="unread flex-center" *ngIf="chat.unreadMessages > 0">
      <span>{{chat.unreadMessages}}</span>
    </div>
  </div>
</div>
