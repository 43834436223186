<div class="flex row">

  <div class="motifun-box col s25 margin">
    <app-event-form-what [formGroup]="eventGroup"
                         [user]="user"></app-event-form-what>
  </div>

  <div class="motifun-box col s5 margin">
    <app-event-form-where [formGroup]="eventGroup"
                          [user]="user"></app-event-form-where>
  </div>

  <div class="motifun-box col s25 margin">
    <app-event-form-how [formGroup]="eventGroup"></app-event-form-how>
  </div>
</div>
