<div class="flex-col motifun-box friend-request flex-center">
  <div class="img xsmall link" [routerLink]="['/user', user.id]">
    <img [src]="[user.getLowImgUrl()]" alt="user img" class="circle img xsmall">
  </div>
  <div class="flex-row">
    <div class="col-10">
      <span class="black-text">{{user.getFullName()}}</span>
    </div>
  </div>
  <br>
  <div class="flex-row flex-center">
    <a (click)="addFriend(user)">
      <fa-icon [icon]="icons.add"></fa-icon>
    </a>
    <a (click)="denyFriend(user)">
      <fa-icon [icon]="icons.deny"></fa-icon>
    </a>
  </div>
</div>
