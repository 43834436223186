import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Location} from '../../../shared/models/location';
import {Sport} from '../../../shared/models/sport';
import {DataStoreService} from '../../../core/services/data-store.service';
import {ApiService} from '../../../core/services/api.service';
import {GeoService} from '../../../core/services/geo.service';
import {SearchOptions} from '../../../shared/models/search-options';
import {UntypedFormControl, Validators} from '@angular/forms';
import {debounceTime, distinctUntilChanged, map, share, startWith, switchMap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {GeolocationType} from '../../../shared/enums/geolocation-type';
import {User} from '../../../shared/models/user';
import {MatAutocomplete, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {DateAdapter} from '@angular/material/core';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import {EventType} from '../event-type';
import {EventService} from '../../../core/services/event.service';
import {faCalendar, faDumbbell, faMapMarkerAlt, faSearchLocation, faTimes} from '@fortawesome/free-solid-svg-icons';
import {Options} from 'nouislider';
import wNumb from 'wnumb';
import {MatSliderChange} from '@angular/material/slider';
import {DateTime} from 'luxon';

@Component({
  selector: 'app-event-list-filter',
  templateUrl: './event-list-filter.component.html',
  styleUrls: ['./event-list-filter.component.css'],
  providers: []
})
export class EventListFilterComponent implements OnInit {
  formControls = {
    sports: new UntypedFormControl(''),
    location: new UntypedFormControl('', Validators.required),
    minStartDate: new UntypedFormControl(new Date(), Validators.required),
    maxStartDate: new UntypedFormControl(''),
    skill: new UntypedFormControl(''),
    maxDistance: new UntypedFormControl(15)
  };

  rangeSliderConfig: Options = {
    start: [1, 5],
    step: 1,
    behaviour: 'drag',
    connect: true,
    range: {
      min: 1,
      max: 5
    },
    format: wNumb({
      decimals: 0
    })
  };

  icons = {
    locate: faSearchLocation,
    day: faCalendar,
    sport: faDumbbell,
    location: faMapMarkerAlt,
    close: faTimes
  };

  loading = {
    sports: false,
    places: false
  };

  sports$: Observable<Sport[]>;
  places$: Observable<Location[]>;

  searchOptions: SearchOptions;

  sports: Sport[] = [];
  places: string[] = [];

  today = new Date();
  minDate = new Date();

  @Input() darkMode: boolean;
  @Input() user: User;
  @Input() type: EventType;
  @Output() optionsChange: EventEmitter<SearchOptions> = new EventEmitter<SearchOptions>();

  @ViewChild('sportsInput', {static: true}) sportsInput: ElementRef<HTMLInputElement>;
  @ViewChild('sportsAuto', {static: true}) sportsAutocomplete: MatAutocomplete;

  constructor(private adapter: DateAdapter<Date>,
              private eventService: EventService,
              private data: DataStoreService,
              private api: ApiService,
              private geo: GeoService) {
  }

  ngOnInit(): void {
    this.adapter.setLocale(this.user.settings.language);
    this.searchOptions = this.eventService.getSearchOptions(this.type);

    if (!this.searchOptions.location || !this.searchOptions.location.lat) {
      this.geo.getLocationByIp()
        .subscribe(location => {
          console.log('location changed');
          this.searchOptions.location = location;
          this.formControls.location.setValue(location.getAutocompleteString());
          this.setSearchOptions();
        });
    } else {
      this.formControls.location.patchValue(this.searchOptions.location.getAddressString());
    }

    this.sports$ = this.formControls.sports.valueChanges
      .pipe(
        startWith(null),
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((val: any) => {
          if (val instanceof Sport) {
            return of([]);
          } else {
            if (!val || val.trim().length <= 0) {
              return of([]);
            }
            return this.handleGetSports(val);
          }
        }),
        share()
      );

    this.places$ = this.formControls.location.valueChanges
      .pipe(
        startWith(''),
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((val: any) => {
          if (val instanceof Location) {
            return of([]);
          } else {
            if (!val || val.trim().length <= 0) {
              return of([]);
            }
            return this.handleGetLocations(val);
          }
        }),
        share()
      );
  }

  handleGetSports(value: string) {
    return this.api.get<any[]>('sports?q=' + value)
      .pipe(
        map((sports: Sport[]) => {
          this.sports = [];
          let sport;
          for (const s of sports) {
            sport = new Sport().deserialize(s);
            this.sports.push(sport);
          }
          return this.sports;
        })
      );
  }

  handleGetLocations(value: string) {
    let language;
    if (this.user && this.user.settings && this.user.settings.language) {
      language = this.user.settings.language;
    }
    return this.geo.getSearchPlace(value, GeolocationType.KOMOOT, true, language);
  }

  onSelectSports(event: MatAutocompleteSelectedEvent) {
    if (event.option) {
      const sport = event.option.value;
      this.sports.push(sport);
      this.searchOptions.sports.push(sport);
      this.formControls.sports.setValue(null);
      this.sportsInput.nativeElement.value = '';
      this.setSearchOptions();
    }
  }

  removeSport(sport: Sport) {
    this.searchOptions.sports = this.searchOptions.sports.filter((el: Sport) => {
      return el.name !== sport.name;
    });
    this.setSearchOptions();
  }

  onSelectPlace($event: MatAutocompleteSelectedEvent) {
    const place = $event.option.value;
    this.formControls.location.setValue(place.getAutocompleteString());
    this.searchOptions.location = place;
    this.setSearchOptions();
  }

  displayNull(): null {
    return null;
  }

  onSkillChanged($event: number[]) {
    this.searchOptions.skill = $event;
    this.setSearchOptions();
  }

  onMinStartDateChanged($event: MatDatepickerInputEvent<Date>) {
    if (!$event.value) {
      this.formControls.minStartDate.patchValue(new Date());
      this.minDate = new Date();
      this.setSearchOptions();
      return;
    }
    this.minDate = $event.value;
    let day: DateTime = DateTime.fromJSDate($event.value);
    if (day.diff(DateTime.local(), 'days').days === 0) {
      day = DateTime.local();
    } else {
      day.endOf('day');
    }
    this.searchOptions.minStartDate = Number(day.toMillis());
    if (DateTime.fromJSDate(this.formControls.maxStartDate.value).diff(DateTime.fromJSDate(this.minDate)).days < 0) {
      this.formControls.maxStartDate.patchValue(this.minDate);
    }
    this.setSearchOptions();
  }

  onMaxStartDateChanged($event: MatDatepickerInputEvent<Date>) {
    if (!$event || !$event.value) {
      this.searchOptions.maxStartDate = undefined;
      this.formControls.maxStartDate.patchValue(null);
      this.setSearchOptions();
      return;
    }
    this.searchOptions.maxStartDate = DateTime.fromJSDate($event.value).endOf('day').toMillis();
    this.setSearchOptions();
  }

  onMaxDistanceChanged($event: MatSliderChange) {
    this.setSearchOptions();
  }

  private setSearchOptions() {
    this.eventService.setSearchOptions(this.type, this.searchOptions);
    this.optionsChange.emit(this.searchOptions);
  }

  handleLocateUser() {
    if (!this.searchOptions) {
      return;
    }

    this.geo.locateUser()
      .subscribe((data: Location) => {
        this.searchOptions.location = data;
        this.formControls.location.patchValue(data.city + ', ' + data.country);
        this.setSearchOptions();
      });
  }
}
