import {Component, Input} from '@angular/core';
import {User} from '../../../shared/models/user';
import {FriendState} from '../../../shared/enums/friend-state';
import {UserService} from '../../../core/services/user.service';
import {faEnvelope, faUserCheck, faUserCircle, faUserMinus, faUserPlus, faUserTimes} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-user-item',
  templateUrl: './user-item.component.html',
  styleUrls: ['./user-item.component.css']
})
export class UserItemComponent {

  @Input() user: User;
  @Input() mode: boolean;
  @Input() subText: string;
  @Input() ownUserId: string;

  icons = {
    chat: faEnvelope,
    addFriend: faUserPlus,
    removeFriend: faUserMinus,
    cancelRequest: faUserTimes,
    acceptRequest: faUserCheck,
    userTest: faUserCircle
  };

  requestSent = FriendState.REQUEST_SENT;

  constructor(private userService: UserService) {
  }

  getFriendsIcon() {
    switch (this.user.userRelation) {
      case FriendState.FRIENDS:
        return this.icons.removeFriend;
      case FriendState.NO_FRIENDS:
        return this.icons.addFriend;
      case FriendState.REQUEST_RECEIVED:
        return this.icons.removeFriend;
      case FriendState.REQUEST_SENT:
        return this.icons.acceptRequest;
      default:
        return this.icons.userTest;
    }
  }

  onAddFriend() {
    this.userService.addUser(this.user)
      .subscribe((data: any) => {
      console.log(data);
    });
  }

  onRemoveFriend() {
    this.userService.denyUser(this.user)
      .subscribe((data: any) => {
        console.log(data);
      });
  }
}
