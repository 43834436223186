<div class="session-item flex-row flex-center">
  <div class="icons">
    <fa-icon [icon]="icons.env[session.generateIconDeviceClass()]"></fa-icon>
    <fa-icon [icon]="icons.os[session.generateIconOSClass()]"></fa-icon>
    <fa-icon [icon]="icons.browser[session.generateIconBrowserClass()]"></fa-icon>
  </div>
  <div>
    <p>{{session.getIp()}}</p>
    <p>{{session.getDate() | date}}</p>
    <p>{{session.getLocation()}}</p>
    <p class="motifun text" *ngIf="session.isCurrentSession">aktive Session</p>
  </div>
  <span class="col-1 delete_test" (click)="handleRemoveSession(session)"><i class="material-icons">close</i></span>
</div>
