<form class="form" [formGroup]="registerForm" (ngSubmit)="onSubmit()">
  <div class="flex row">
    <mat-form-field class="col s5">
      <fa-icon matPrefix [icon]="icons?.name"></fa-icon>
      <input matInput
             type="email"
             name="firstName"
             placeholder="Vorname"
             formControlName="firstName">
      <mat-error *ngIf="registerForm.controls['password'].hasError('required')">
        Der Vorname wird <strong>benötigt</strong>
      </mat-error>
    </mat-form-field>
    <mat-form-field class="col s5">
      <fa-icon matPrefix [icon]="icons?.name"></fa-icon>
      <input matInput
             type="email"
             name="lastName"
             placeholder="Nachname"
             formControlName="lastName">
      <mat-error *ngIf="registerForm.controls['password'].hasError('required')">
        Der Nachname wird <strong>benötigt</strong>
      </mat-error>
    </mat-form-field>
  </div>
  <div class="flex row">
    <mat-form-field class="col s10">
      <fa-icon matPrefix [icon]="icons?.email"></fa-icon>
      <input matInput
             type="email"
             name="email"
             placeholder="E-Mail"
             formControlName="email">
      <mat-error *ngIf="registerForm.controls['email'].hasError('required')">
        Die E-Mail wird <strong>benötigt</strong>
      </mat-error>
      <mat-error *ngIf="registerForm.controls['email'].hasError('email')">
        Die eingegebene E-Mail Adresse ist nicht gültig
      </mat-error>
    </mat-form-field>
  </div>
  <div class="flex row">
    <mat-form-field class="col s10">
      <fa-icon matPrefix [icon]="icons.password"></fa-icon>
      <input matInput
             type="password"
             name="password"
             placeholder="Passwort"
             formControlName="password">
      <mat-error *ngIf="registerForm.controls['password'].hasError('required')">
        Das Passwort wird <strong>benötigt</strong>
      </mat-error>
      <mat-error *ngIf="registerForm.controls['password'].hasError('strength')">
        Bitte gib ein gülties Passwort ein. Gültige Passwörter bestehen mindestens aus 8 und maximal aus 32 Zeichen. Sie
        beinhalten Groß- und Kleinbuchstaben, Zahlen und Sonderzeichen.
      </mat-error>
    </mat-form-field>
  </div>
  <div class="flex row center fill">
    <a [routerLink]="['/login']">Ich habe bereits einen Account</a>
  </div>
  <button mat-raised-button
          [disabled]="registerForm.invalid"
          type="submit"
          color="primary"
          class="flex-bottom">Registrieren
  </button>
</form>
