import {Component} from '@angular/core';

@Component({
  selector: 'app-start-signed-in',
  templateUrl: './start-signed-in.component.html',
  styleUrls: ['./start-signed-in.component.scss']
})
export class StartSignedInComponent {

  constructor() {
  }

}
